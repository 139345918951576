import React from 'react';
// import { createStyles } from '@mui/core/styles';
import {NavLink} from 'react-router-dom';
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import {IEnhancedRow} from './EnhancedTableBody';
import {EnhancedUpdatedTableHeader, EnhancedUpdatedTableHeaderType} from '.';
import {
  parseStringTemplate,
  addZeroesAndSeparatevalue,
  maskValueWithPatternIfMatch,
  isExternalLink,
} from '../../utils/formatting-utils';
import LimitedTextWrapper from './shared/LimitTextWrapper';
import {IEnhancedCommonProps} from '.';
import {Button, TableCell} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import FormattingServices from '../../services/formating-services';
import { isEmpty, isValidNumber } from '../../utils/validationUtils';
import EnhancedButton from '../EnhancedButton';
import dayjs from 'dayjs';

interface IEnhancedTableBodyCellProps extends IEnhancedCommonProps {
  row: IEnhancedRow;
  header: EnhancedUpdatedTableHeader;
  currencySymbol?: string;
  dateFormat?: string;
  //phoneNumberPattern: string;
  selected: boolean;
  handleUpdate?(rowKey: string, property: string, value: any): void;
}

const useStyles = makeStyles()((theme) => ({
  cellRoot: {
    padding: '0 2px 0 24px',
    fontFamily: 'OpenSans-Regular',
    fontSize: '13px',
    lineHeight: '16px',
    color: '#667085',
    letterSpacing: '0',
    fontWeight: 'normal',
    height: '45px',
  },
  cellLink: {
    color: 'rgb(34, 51, 84)',
  },
  cellbutton: {
    textAlign: 'left',
    color: '#667085',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textDecoration: 'underline',
    height: 34,
    padding: 0,
    fontWeight: 400,
    display: 'table-cell',
  },
  cellBadge: {
    borderRadius: '5px',
    padding: '5px',
    display: 'inline-block',
    minWidth: '50px',
    textAlign: 'center',
  },
  classificationContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: '0 5px 0 0',
  },
  icon: {
    minHeight: '15px',
    minWidth: '15px',
    borderRadius: '50%',
    margin: '0 7px 0 0',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  classificationInput: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  input: {
    width: '90%',
    maxWidth: '200px',
    border: '1px solid #DFE3EB',
    height: '28px',
    margin: '0 auto',
    fontSize: '13px',
    boxSizing: 'border-box',
    lineHeight: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  underlined: {
    textDecoration: 'underline',
  },
  positiveCurrency: {
    color: '#78b33a',
  },
  negativeCurrency: {
    color: '#d03536',
  },
  svgClickableButton: {
    height: 'auto',
    padding: 3,
    boxShadow: `0 0 3px #aaa`,
    borderRadius: 4,
    transition: '125ms',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  svgClickableIcon: {
    width: '15px',
    height: '15px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    '@media only screen and (max-width: 650px)': {
      width: '12px',
      height: '12px',
    },
  },
}));

const EnhancedTableBodyCell: React.FC<IEnhancedTableBodyCellProps> = ({
  row,
  header,
  dateFormat,
  className,
  selected,
  handleUpdate = () => {},
}) => {
  const {classes, cx} = useStyles();
  const phoneNumberPattern = '';

  function renderCellContents(): JSX.Element | string {
    switch (header.type) {
      case EnhancedUpdatedTableHeaderType.Link: {
        return !isEmpty(row.columns[header.name]) &&
          isExternalLink(row.columns[header.name]) !== null ? (
          <button
            type="button"
            onClick={() => {
              window.open(row.columns[header.name]);
            }}
          >
            <LimitedTextWrapper
              text={row.columns[header.name]}
              maxCharacter={40}
            />
          </button>
        ) : (
          <NavLink
            target={header.openUrlInNewTab ? '_blank' : undefined}
            to={parseStringTemplate(
              header.urlTemplate,
              row.columns.id,
              row.columns,
            )}
          >
            <button className={classes.cellbutton}>
              {row.columns[header.name]}
            </button>
          </NavLink>
        );
      }
      case EnhancedUpdatedTableHeaderType.Date:
        return dayjs(row.columns[header.name]).format(dateFormat);
      case EnhancedUpdatedTableHeaderType.DateAndTime:
        return FormattingServices.formatDateTime(row.columns[header.name]);
      case EnhancedUpdatedTableHeaderType.Clickable:
        if (header.icon) {
          return (
            <Button onClick={() => header.callback(row)}>{header.icon}</Button>
          );
        } else if (header.buttonType === 'enhanced') {
          return (
            <EnhancedButton
              className={header.buttonClass}
              type="button"
              onClick={() => header.callback(row)}
              title={row.columns[header.name] || header.buttonLabel || ''}
            />
          );
        } else {
          return (
            <button
              className={cx(classes.cellbutton, header.buttonClass)}
              type="button"
              onClick={() => header.callback(row)}
            >
              {row.columns[header.name] || header.buttonLabel}
            </button>
          );
        }
      case EnhancedUpdatedTableHeaderType.Badge:
        return (
          <span
            className={classes.cellBadge}
            style={{
              color:
                header.badgeConfig[row.columns[header.name].toString()].color,
              backgroundColor:
                header.badgeConfig[row.columns[header.name]].backgroundColor,
            }}
          >
            {row.columns[header.name]}
          </span>
        );
      case EnhancedUpdatedTableHeaderType.Currency: {
        if (!isValidNumber(row.columns[header.name])) {
          return row.columns[header.name];
        }

        const value = Number(row.columns[header.name]);
        const displayValue = FormattingServices.formatCurrencyNumber(
          Math.abs(value),
        );
        const isPostive = value >= 0;
        if (isPostive) {
          return displayValue;
        }

        return <>{`(${displayValue})`}</>;
      }
      case EnhancedUpdatedTableHeaderType.ColoredCurrency: {
        if (!isValidNumber(row.columns[header.name])) {
          return row.columns[header.name];
        }

        const value = Number(row.columns[header.name]);
        const displayValue = FormattingServices.formatCurrencyNumber(
          Math.abs(value),
        );
        const isPostive = value >= 0;
        if (isPostive) {
          return (
            <span className={classes.positiveCurrency}>{displayValue}</span>
          );
        }

        return (
          <span className={classes.negativeCurrency}>
            {`(${displayValue})`}
          </span>
        );
      }
      case EnhancedUpdatedTableHeaderType.Percentage:
        return isValidNumber(row.columns[header.name])
          ? `% ${addZeroesAndSeparatevalue(row.columns[header.name])}`
          : row.columns[header.name];
      case EnhancedUpdatedTableHeaderType.Rate:
        return isValidNumber(row.columns[header.name])
          ? `% ${addZeroesAndSeparatevalue(
              Number(row.columns[header.name]) * 100
            )}`
          : row.columns[header.name];

      case EnhancedUpdatedTableHeaderType.Classification:
        return header.classificationPropertyName ? (
          <div>
            <figure
              style={{
                backgroundColor: row.columns[header.classificationPropertyName],
              }}
            />
            <span
              style={{color: row.columns[header.classificationPropertyName]}}
            >
              {row.columns[header.name]}
            </span>
          </div>
        ) : (
          row.columns[header.name]
        );

      case EnhancedUpdatedTableHeaderType.PhoneNumber: {
        const value =
          row.columns[header.name] && phoneNumberPattern
            ? maskValueWithPatternIfMatch(
                row.columns[header.name],
                phoneNumberPattern,
              )
            : row.columns[header.name];
        return <LimitedTextWrapper text={value} maxCharacter={30} />;
      }
      case EnhancedUpdatedTableHeaderType.IntPhoneNumber: {
        let value = row.columns[header.name];
        if (value && isPossiblePhoneNumber(value)) {
          value = formatPhoneNumberIntl(value);
        }

        return <LimitedTextWrapper text={value} maxCharacter={30} />;
      }
      // eslint-disable-next-line no-fallthrough
      case EnhancedUpdatedTableHeaderType.Image: {
        return (
          <img
            src={row.columns[header.imageKey || '']}
            alt=""
            width={50}
            height={50}
          />
        );
      }
      default:
        return (
          <LimitedTextWrapper
            text={row.columns[header.name]}
            maxCharacter={30}
          />
        );
    }
  }

  return (
    // <TableCell component="th" scope="row" padding="none" className={className}>
    <TableCell
      component="th"
      scope="row"
      padding="none"
      className={classes.cellRoot}
    >
      {renderCellContents()}
    </TableCell>
  );
};

export default EnhancedTableBodyCell;
