import { isValidNumber } from "../../../../../utils/validationUtils";
import { IProposalPageFormState } from "./form";
import { IProposalDetailsSummary } from "./index2";

export interface IProposalDetailsExpatPageSubmissionModel {
  entityId: string;
  planId: string;
  policyCurrency: string;
  policyEffectiveDate: string;
  policyExpiryDate: string;
  // agencyRepairYear: number;
  policyPersonInput: {
    personId: string;
    personAddressId: string;
    type: string;
    percentage?: number;
    orderNumber?: number;
  }[];
  // policyPlanCoverInput: {
  //   coverPremiumPercentage: number;
  //   coverPremiumType: string;
  //   coverPremiumValue: number;
  //   excessOnClaimAmount: number;
  //   excessOnClaimDays: number;
  //   excessOnClaimPercentage: number;
  //   excessOnClaimType: string;
  //   planCoverID: string;
  // }[];
  costAndChargeInput: {
    chargesPercentage: number;
    policyCost: number;
    tpaFeesAmount: number;
    tpaFeesApplicableOn: string;
    tpaFeesPercentage: number;
    tpaFeesType: string;
  };
  // expatInput: {
  //   medicalClass: string;
  //   continuity: string;
  //   continuityNumber: number;
  //   exclusion: string;
  //   remarks: string;
  // };

  medicalInput: {

  }
}

export function convertProposalPageStateToSubmission(
  summary: IProposalDetailsSummary,
  pageState: IProposalPageFormState
) {
  const { values } = pageState;

  const policyPersons: IProposalDetailsExpatPageSubmissionModel["policyPersonInput"] =
    [];

  if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: "PAYER",
    });
  }
  if (values.policyPersons.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: "OWNER",
    });
  }

  values.policyPersons.insured.forEach((person) => {
    if(person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: "INSURED",
        orderNumber: isValidNumber(person.order)
          ? Number(person.order)
          : undefined,
      });
    }
  });

  values.policyPersons.beneficiaries.forEach((person) => {
    if(person.id){
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: "BENEFICIARY",
        percentage: isValidNumber(person.percentage)
          ? person.percentage / 100
          : 0,
      });
    }
  });

  const data: IProposalDetailsExpatPageSubmissionModel = {
    entityId: summary.Id,
    planId: summary.PlanID.Id,
    policyCurrency: summary.PolicyCurrency.Code,
    policyEffectiveDate: summary.PolicyEffectiveDate,
    policyExpiryDate: summary.PolicyExpiryDate,
    // agencyRepairYear: isValidNumber(values.motorDetails.agencyRepairYear)
    //     ? Number(values.motorDetails.agencyRepairYear)
    //     : null,
    policyPersonInput: policyPersons,
    costAndChargeInput: {
      chargesPercentage: isValidNumber(values.costs.chargesPercentage)
        ? Number(values.costs.chargesPercentage) / 100
        : 0,
      policyCost: isValidNumber(values.costs.policyCost)
        ? Number(values.costs.policyCost)
        : 0,
      tpaFeesAmount: isValidNumber(values.costs.tpaFeesAmount)
        ? Number(values.costs.tpaFeesAmount)
        : 0,
      tpaFeesApplicableOn: values.costs.tpaFeesApplicableOn,
      tpaFeesPercentage: isValidNumber(values.costs.tpaFeesPercentage)
        ? values.costs.tpaFeesPercentage / 100
        : 0,
      tpaFeesType: values.costs.tpaFeesType,
    },
    medicalInput: {
      // medicalClass: values?.expatDetails?.medicalClass,
      // continuity: values?.expatDetails?.continuity,
      // continuityNumber: isValidNumber(values.expatDetails?.continuityNumber)
      //   ? Number(values.expatDetails?.continuityNumber)
      //   : null,
      // exclusion: values?.expatDetails?.exclusion,
      // remarks: values?.expatDetails?.remarks,
    },
  };

  return data;
}
