import { gql } from "@apollo/client";

export function getPolicyDetailsQuery() {
	return gql`
		query getPolicyDetails($policyId: String!) {
			Production {
				entities {
					policy(id: $policyId) {
						views {
							Production_all {
								properties {
									PolicyExpiryDate
									PolicyIssueDate
									PolicyNumber
									PolicyName
								}
							}
						}
					}
				}
			}
			Production_PaymentDivisionTypeList: __type(
				name: "Production_PaymentDivisionTypeList"
			) {
				name
				enumValues {
					name
				}
			}
			Accounting {
				queries {
					GetPolicyReceivableBills(PolicyID: $policyId) {
						accounting_Bills_OutstandingBalance
						accounting_Bills_Currency {
							Code
							Title
							Id
							Symbol
						}
					}
				}
			}
		}
	`;
}

export function calculateMaxFrequencyQuery() {
	return gql`
		mutation calculateMaxFrequency(
			$policyIssuanceDate: Date!
			$policyExpiryDate: Date
			$effectiveFromDate: Date!
		) {
			accounting {
				actions {
					calculateMaxFrequency(
						PolicyIssuanceDate: $policyIssuanceDate
						PolicyExpiryDate: $policyExpiryDate
						EffectiveFromDate: $effectiveFromDate
					) {
						Values
					}
				}
			}
		}
	`;
}

export function calculateMaxPaymentsQuery() {
	return gql`
		mutation calculateMaxPayments(
			$policyIssuanceDate: Date!
			$policyExpiryDate: Date
			$effectiveFromDate: Date!
			$selectedFrequency: String!
		) {
			accounting {
				actions {
					calculateMaxPayments(
						PolicyIssuanceDate: $policyIssuanceDate
						PolicyExpiryDate: $policyExpiryDate
						EffectiveFromDate: $effectiveFromDate
						SelectedFrequency: $selectedFrequency
					) {
						Value
					}
				}
			}
		}
	`;
}
