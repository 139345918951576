import { EnhancedTableHeader, EnhancedTableHeaderType } from "../../../../components/enhanced-table";

export const getHeaders = (
  navigate: (path: string) => void
): Record<string, EnhancedTableHeader> => ({
  production_Policy_PolicyNumber: {
    name: "production_Policy_PolicyNumber",
    title: "Policy Number",
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload: any) => {
      navigate("/production/policies/" + payload.columns.production_Policy_Id);
    },
  },
  production_Policy_PolicyName: {
    name: "production_Policy_PolicyName",
    title: "Policy Name",
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload: any) => {
      navigate("/production/policies/" + payload.columns.production_Policy_Id);
    },
  },
  line: {
    name: "line",
    title: "Line",
    type: EnhancedTableHeaderType.Text,
  },
  subline: {
    name: "subline",
    title: "Subline Name",
    type: EnhancedTableHeaderType.Text,
  },
  plan: {
    name: "plan",
    title: "Plan",
    type: EnhancedTableHeaderType.Text,
  },
  effectiveDate: {
    name: "effectiveDate",
    title: "Effective Date",
    type: EnhancedTableHeaderType.Date,
  },
  status: {
    name: "status",
    title: "Status",
    type: EnhancedTableHeaderType.Text,
  },
  statusReason: {
    name: "statusReason",
    title: "Status Reason",
    type: EnhancedTableHeaderType.Text,
  },
});
