import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageShell from "./components/PageShell";
import ApplicationDetailsPageMotor from "./forms/application-details-page-motor/ApplicationDetailsPageMotor";
import Header from "./header/Header";
import HomePage from "./pages/HomePage";
import AgenciesPage from "./pages/agency-listing/AgenciesPage";
import AgentPage from "./pages/agent-listing/AgentPage";
import ApplicationsPage from "./pages/applications-listing/ApplicationsPage";
import HealthApplicationsPage from "./pages/health-applications-listing/HealthApplicationsPage";
import PolicyNumberRangesPage from "./pages/policy-number-ranges-listing/PolicyNumberRangesPage";
import StoreConfirmation from "./store-confirmation/StoreConfirmation";
import ApplicationDetailsPageHealth from "./forms/application-details-page-health/ApplicationDetailsPageHealth";
import PolicyDetailsPage from "./forms/policy-details-page/PolicyDetailsPage";
import PolicyListing from "./pages/policy-listing/PolicyListing";
import UsersPage from "./pages/user-listing/UsersPage";
import AccountsPage from './pages/account-listing/AccountsPage'
import TransactionsPage from './pages/transaction-listing/TransactionPage'
import ProductsPage from './pages/product-listing/ProductsPage'
import { useAppSelector } from "./redux/hooks";
import { AGENT_LINEOFBUSINESS } from "./redux/user/types";
import DownloadPage from "./pages/DownloadPage";
import ExpatApplicationsPage from "./pages/expat-applications-listing/ExpatApplicationsPage";
import ApplicationDetailsPageExpat from "./forms/application-details-page-expat/ApplicationDetailsPageExpat";
import ExpatPolicyListing from "./pages/expat-policy-listing/ExpatPolicyListing";
import ExpatPolicyDetailsPage from "./forms/expat-policy-details-page/ExpatPolicyDetailsPage";
import BusinessPartnerDetailsPage from "./forms/business-partner-details-page/BusinessPartnerDetailsPage";
import SalesforceBusinessPartnerDetailsPage from "./forms/salesforce-business-partner-details-page/SalesforceBusinessPartnerDetailsPage";
import NssfCodeNumberRangesPage from "./pages/nssfcode-number-ranges-listing/NssfCodeNumberRangesPage";
import EnvironmentSettingsPage from "./forms/settings/EnvironmentSettings";
import LinesPage from "./pages/lines-listing/LinesPage";
import LineDetailsPage from "./forms/line-details-page/LineDetailsPage";
import SublinesPage from "./pages/sublines-listing/SublinesPage";
import SublineDetailsPage from "./forms/subline-details-page/SublineDetailsPage";
import PlansPage from "./pages/plans-listing/PlansPage";
import CoversPage from "./pages/covers-listing/CoversPage";
import PlanDetailsPage from "./forms/plan-details-page/PlanDetailsPage";
import PolicyCoverDetailsPage from "./forms/policy-cover-details-page/PolicyCoverDetailsPage";
import PersonsPage from "./pages/persons-listing/PersonsPage";
import PersonDetailsPage from "./forms/person-details-page/PersonDetailsPage";
import BusinessPartnersPage from "./pages/business-partners-listing/BusinessPartnersPage";
import ClausesPage from "./pages/clauses-listing/ClausesPage";
import TaxationsPage from "./pages/taxations-listing/TaxationsPage";
import BusinessUsersPage from "./pages/business-users-listing/BusinessUsersPage";
import SalesforceBusinessUserDetailsPage from "./forms/salesforce-business-user-details-page/SalesforceBusinessUserDetailsPage";
import ProposalPage from "./pages/proposal-listings/ProposalPage";
import PoliciesPage from "./pages/policy-listings/PoliciesPage";
import ProductionPolicyPage from "./modules/production/policy/page/ProductionPolicyPage";
import CustomersPage from "./pages/customer-listing/CustomerPage";
import InvoicePage from "./pages/invoice-listing/InvoicePage";
import InvoiceDetailsPage from "./forms/invoice-details/InvoiceDetailsPage";
import MainProposalPage from "./modules/production/proposal/page/main/MainProposalPage";
import SystemCurrencyConfig from "./forms/system-currency-config/SystemCurrencyConfig";
import CurrencyExchangeRatesPage from "./pages/currency-exchange-rate-listing/CurrencyExchangeRatesPage";
import CurrencyExchangeRateNewPage from "./forms/currency-exchange-rate-new-page/CurrencyExchangeRateNewPage";
import ProvidersPage from "./pages/providers-listing/ProvidersPage";
import ProviderDetailsPage from "./forms/provider-details-page/ProviderDetailsPage";
import TransfersPage from "./pages/transfers-listing/TransfersListing";
import TransferLinksListing from "./pages/transfer-links-listing/TransferLinksListing";
import ReceivablesPayablesPage from "./pages/receivables-payables-listing/ReceivablesPayablesPage";
import InsuredPage from "./modules/production/insured/listing/InsuredPage";
import InsuredDetailsPage from "./modules/production/insured/page/InsuredDetailsPage";
import { isProd } from "./utils/env-utils";

export interface IAppModule {
  name: string;
  route: string;
  title: string;
  hidden?: boolean;
  isDropdown?: boolean;
  subModules?: Record<string, IAppModule>;
}

export interface IApplication {
  description: string;
  icon: string;
  modules: Record<string, IAppModule>;
  name: string;
  route: string;
  title: string;
  hidden?: boolean;
}
export interface IResult {
  applications?: { name?: string; route?: string; title?: string };
  healthApplications?: { name?: string; route?: string; title?: string };
  expatApplications?: { name?: string; route?: string; title?: string };
  motorPolicies?: { name?: string; route?: string; title?: string };
  expatPolicies?: { name?: string; route?: string; title?: string };
}
const Shell: React.FC = () => {
  const isPlanConfigurationEnabled = useAppSelector(
    (state) => state.app.isPlanConfigurationEnabled
  );
  const isSalesForceAppEnabled = useAppSelector(
    (state) => state.app.isSalesForceAppEnabled
  );
  const user = useAppSelector((state) => state.user);
  const cdnUrl = useAppSelector((state) => state.tenant.cdnUrl);
  const confirmation = useAppSelector((state) => state.confirmation);
  const [expatStatus, setExpatStatus] = useState();
  const [motorStatus, setMotorStatus] = useState();
  const [healthStatus, setHealthStatus] = useState();

  const defaultApplication = "agencymanagementapplication";
  const [currentApp, setCurrentApp] = useState<string>(defaultApplication);
  const location = useLocation();
  let [homePageLink] = useState<string>("");

  const applications: Record<string, IApplication> = {};

  useEffect(() => {
    Object.values(user.linesOfBusinessStatuses).forEach(
      (lineOfBusiness: any) => {
        const code = lineOfBusiness.Code;
        const active = lineOfBusiness.Active;

        if (code === "Expat") {
          setExpatStatus(active);
        } else if (code === "Health") {
          setHealthStatus(active);
        } else if (code === "Motor") {
          setMotorStatus(active);
        }
      }
    );
  }, [user]);

  const getSalesApplicationModules = () => {
    let applications = {
      name: "Applications",
      route: "/applications/motor",
      title: "Motor Applications",
    };
    let healthApplications = {
      name: "HealthApplications",
      route: "/applications/health",
      title: "Health Applications",
    };
    let expatApplications = {
      name: "ExpatApplications",
      route: "/applications/expat",
      title: "Expat Applications",
    };

    const roles = user.userRoles;
    const linesOfBusiness = user.info.linesOfBusiness || [];

    const hasAdminOrUnderwriterRole =
      roles.includes("Insurance-Admin") ||
      roles.includes("Insurance-Underwriter");
    const hasQuotingOrIssuingAgentRole =
      roles.includes("Insurance-QuotingAgent") ||
      roles.includes("Insurance-IssuingAgent");

    const isHealthAgent = linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Health);
    const isMotorAgent = linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Motor);
    const isExpatAgent = linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Expat);

    const result: IResult = {};

    if (hasAdminOrUnderwriterRole) {
      if (motorStatus) result.applications = applications;
      if (healthStatus) result.healthApplications = healthApplications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (
      hasQuotingOrIssuingAgentRole &&
      isHealthAgent &&
      isMotorAgent &&
      isExpatAgent
    ) {
      if (motorStatus) result.applications = applications;
      if (healthStatus) result.healthApplications = healthApplications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isHealthAgent && isMotorAgent) {
      if (motorStatus) result.applications = applications;
      if (healthStatus) result.healthApplications = healthApplications;
    } else if (hasQuotingOrIssuingAgentRole && isHealthAgent && isExpatAgent) {
      if (healthStatus) result.healthApplications = healthApplications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isExpatAgent && isMotorAgent) {
      if (motorStatus) result.applications = applications;
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isHealthAgent) {
      if (healthStatus) result.healthApplications = healthApplications;
    } else if (hasQuotingOrIssuingAgentRole && isExpatAgent) {
      if (expatStatus) result.expatApplications = expatApplications;
    } else if (hasQuotingOrIssuingAgentRole && isMotorAgent) {
      if (motorStatus) result.applications = applications;
    }

    return result;
  };

  const getPoliyManagementApplicationModules = () => {
    const roles = user.userRoles;
    const linesOfBusiness = user.info.linesOfBusiness || [];

    const result: IResult = {};

    const addMotorPolicy = () => {
      if (motorStatus) {
        result.motorPolicies = {
          name: "Policies",
          route: "/policies",
          title: "Motor Policies",
        };
      }
    };

    const addExpatPolicy = () => {
      if (expatStatus) {
        result.expatPolicies = {
          name: "ExpatPolicies",
          route: "/expat-policies",
          title: "Expat Policies",
        };
      }
    };

    if (
      roles.includes("Insurance-Admin") ||
      roles.includes("Insurance-Underwriter")
    ) {
      addMotorPolicy();
      addExpatPolicy();
    } else if (
      roles.includes("Insurance-QuotingAgent") ||
      roles.includes("Insurance-IssuingAgent")
    ) {
      if (linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Motor)) {
        addMotorPolicy();
      }

      if (linesOfBusiness.includes(AGENT_LINEOFBUSINESS.Expat)) {
        addExpatPolicy();
      }
    }

    return result;
  };

  const agencymanagementapplication = {
    description: "View and manage business partners and business users",
    icon: cdnUrl + "/icons/producers-icon-primary.svg",
    name: "agencymanagementapplication",
    route: "/business",
    title: "Business Partners Management",
    modules: {
      Partners: {
        name: "Business Partners",
        route: "/partners",
        title: "Business Partners",
      },
      Users: {
        name: "Business Users",
        route: "/users",
        title: "Business Users",
      },
    },
  };
  const salesapplication = {
    description: "View and manage customers and applications",
    icon: cdnUrl + "/icons/sales-icon-primary.svg",
    name: "salesapplication",
    route: "/sales",
    title: "Sales",
    modules: {},
  };
  const policymanagement = {
    description: "View and manage policies",
    icon: cdnUrl + "/icons/policymanagement-icon-primary.svg",
    name: "policymanagement",
    route: "/plm",
    title: "Policy Management",
    modules: {},
  };
  const adminapplication = {
    description: "View and manage system users",
    icon: cdnUrl + "/icons/administration-icon-primary.svg",
    name: "adminapplication",
    route: "/admin",
    title: "Admin",
    modules: {
      Users: {
        name: "Users",
        route: "/users",
        title: "Users",
      },
      MotorPolicyNumberRanges: {
        name: "MotorPolicyNumberRanges",
        route: "/policynumberranges/motor",
        title: "Motor Policy Number Ranges",
      },
      ExpatPolicyNumberRanges: {
        name: "ExpatPolicyNumberRanges",
        route: "/policynumberranges/expat",
        title: "Expat Policy Number Ranges",
      },
      NssfCodeNumberRanges: {
        name: "NssfCodeyNumberRanges",
        route: "/nssfcodenumberranges",
        title: "Bar Code Number Ranges",
      },
    },
  };
  const planConfigurationApplication = {
    description: "View and manage lines, sublines, and covers",
    icon: cdnUrl + "/icons/configurations-icon-primary.svg",
    name: "planConfigurationApplication",
    route: "/plan",
    title: "Plan Configuration",
    modules: {
      Lines: {
        name: "Lines",
        route: "/lines",
        title: "Lines",
      },
      Sublines: {
        name: "Sublines",
        route: "/sublines",
        title: "Sublines",
      },
      Plans: {
        name: "Plans",
        route: "/plans",
        title: "Plans",
      },
      Covers: {
        name: "Covers",
        route: "/covers",
        title: "Covers",
      },
      Clauses: {
        name: "Clauses",
        route: "/clauses",
        title: "Clauses",
      },
    },
  };
  const salesforceapplication = {
    description:
      "View and manage persons, business partners, and business users",
    icon: cdnUrl + "/icons/salesforces-icon-primary.svg",
    name: "salesforceapplication",
    route: "/salesforce",
    title: "Salesforce",
    modules: {
      Persons: {
        name: "Persons",
        route: "/persons",
        title: "Persons",
      },
      BusinessPartners: {
        name: "BusinessPartners",
        route: "/business-partners",
        title: "Business Partners",
      },
      BusinessUsers: {
        name: "BusinessUsers",
        route: "/business-users",
        title: "Business Users",
      },
    },
  };

  const settingsapplication = {
    description: "",
    icon: cdnUrl,
    name: "settingsapplication",
    route: "/settings",
    title: "Settings",
    hidden: true,
    modules: {
      Environment: {
        name: "Environment",
        route: "/environment",
        title: "Environment",
      },
    },
  };

  const accountingapplication = {
    description: "Manage all your accounting transactions in one place",
    icon: cdnUrl + "/icons/accounting01.svg",
    name: "accountingapplication",
    route: "/accounting",
    title: "Accounting",
    modules: {
      AccountsAndTransactions: {
        name: "AccountsAndTransactions",
        route: "",
        title: "Accounts & Transactions",
        subModules: [
          {
            name: "ChartsofAccounts",
            route: "/accounts",
            title: "Chart of Accounts",
          },
          {
            name: "Transactions",
            route: "/transactions",
            title: "Transactions",
          },
        ]
      },
      // SalesAndCollections: {
      //   name: "SalesAndCollections",
      //   route: "",
      //   title: "Sales & Collections",
      //   subModules: [
      //     {
      //       name: "Customers",
      //       route: "/customers",
      //       title: "Customers",
      //     },
      //     {
      //       name: "Invoices",
      //       route: "/invoices",
      //       title: "Invoices",
      //     }
      //   ]
      // },
      TransfersAndLinks: {
        name: "TransfersAndLinks",
        route: "",
        title: "Transfers & Links",
        subModules: [
          {
            name: "TransferLinks",
            route: "/transfer-links",
            title: "Transfer Links",
          },
          {
            name: "Transfers",
            route: "/transfers",
            title: "Transfers",
          }
        ]
      },
      SalesAndCollections: {
        name: "ReceivablesAndPayables",
        route: "/receivables-payables",
        title: "Receivables & Payables",
      },
    },
  };

  const configurationapplication = {
    description: "View and manage configurations",
    icon: cdnUrl + "/icons/configuration-icon-primary.svg",
    name: "configurationapplication",
    route: "/configuration",
    title: "Configurations",
    modules: {
      systemCurrency: {
        name: "systemCurrency",
        route: "/system-currency",
        title: "System Currency",
      },
      currencyExchangeRate: {
        name: "currencyExchangeRate",
        route: "/currency-exchange-rate",
        title: "Currency Exchange Rate",
      },
      Taxation: {
        name: "Taxation",
        route: "/taxation",
        title: "Taxation",
      },
      Providers: {
        name: "Providers",
        route: "/providers",
        title: "Providers",
      },
    },
  };

  const productionapplication = {
    description: "View and manage production",
    icon: cdnUrl + "/icons/administration-icon-primary.svg",
    name: "productionapplication",
    route: "/production",
    title: "Production",
    modules: {
      Proposal: {
        name: "Proposal",
        route: "/proposal",
        title: "Proposals",
      },
      Policies: {
        name: "Policies",
        route: "/policies",
        title: "Policies",
      },
      Insured: {
        name: "Insured",
        route: "/insured",
        title: "Insured",
      },
    },
  };

  if (user?.userRoles?.includes("Insurance-Admin")) {
    homePageLink = "/sales/applications/motor";

    policymanagement.modules = getPoliyManagementApplicationModules();
    applications.policymanagement = policymanagement;

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;

    applications.agencymanagementapplication = agencymanagementapplication;
    applications.adminapplication = adminapplication;
    applications.settingsapplication = settingsapplication;
    if (isPlanConfigurationEnabled) {
      applications.planConfigurationApplication = planConfigurationApplication;
    }
    if (isSalesForceAppEnabled) {
      applications.salesforceapplication = salesforceapplication;
      applications.accountingapplication = accountingapplication as any;
      applications.configurationapplication = configurationapplication;
      applications.productionapplication = productionapplication;
    }
  } else if (user?.userRoles?.includes("Insurance-IssuingAgent")) {
    if (user.info.linesOfBusiness.includes("Motor")) {
      homePageLink = "/sales/applications/motor";
    } else if (user.info.linesOfBusiness.includes("Health")) {
      homePageLink = "/sales/applications/health";
    } else if (user.info.linesOfBusiness.includes("Expat")) {
      homePageLink = "/sales/applications/expat";
    }

    policymanagement.modules = getPoliyManagementApplicationModules();
    applications.policymanagement = policymanagement;

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;
  } else if (user?.userRoles?.includes("Insurance-QuotingAgent")) {
    if (user.info.linesOfBusiness.includes("Motor")) {
      homePageLink = "/sales/applications/motor";
    } else if (user.info.linesOfBusiness.includes("Health")) {
      homePageLink = "/sales/applications/health";
    } else if (user.info.linesOfBusiness.includes("Expat")) {
      homePageLink = "/sales/applications/expat";
    }

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;
  } else if (user?.userRoles?.includes("Insurance-Underwriter")) {
    homePageLink = "/sales/applications/motor";

    salesapplication.modules = getSalesApplicationModules();
    applications.salesapplication = salesapplication;

    policymanagement.modules = getPoliyManagementApplicationModules();
    applications.policymanagement = policymanagement;
    applications.settingsapplication = settingsapplication;
  } else if (user?.userRoles?.includes("Core-BusinessUser")) {
    homePageLink = "/production/proposal";

    if (isSalesForceAppEnabled) {
      applications.productionapplication = productionapplication;
    }
  }
  else if (user.userRoles.includes("Insurance-Accountant")) {
    homePageLink = "/accounting/accounts";

    policymanagement.modules = getPoliyManagementApplicationModules();

    salesapplication.modules = getSalesApplicationModules();
    if (isSalesForceAppEnabled) {
      applications.salesforceapplication = salesforceapplication;
    }
    applications.accountingapplication = accountingapplication as any;
  }

  if (isProd()) {
    delete applications.salesforceapplication;
    delete applications.accountingapplication;
    delete applications.configurationapplication;
    delete applications.productionapplication;
    delete applications.planConfigurationApplication;
  }

  const getApplicationKeys = useCallback(
    () => Object.keys(applications),
    [applications]
  );

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    let app;
    if (pathParts.length > 1) {
      getApplicationKeys().forEach((appKey) => {
        if (applications[appKey].route === `/${pathParts[1]}`) {
          app = appKey;
        }
      });
    }
    if (!currentApp || app !== currentApp) {
      if (app) {
        setCurrentApp(app);
      } else if (getApplicationKeys().length > 0) {
        const appKey =
          defaultApplication && applications[defaultApplication]
            ? defaultApplication
            : getApplicationKeys()[0];

        setCurrentApp(appKey);
      }
    }
    // We are only concerning ourselves with the currentApp here
    // Only if the app path is wrong do we redirect the page
  }, [applications, location.pathname]);

  return (
    <>
      <PageShell
        header={<Header currentApp={currentApp} applications={applications} />}
      >
        <Routes>
          <Route path="/" element={<HomePage homePageLink={homePageLink} />} />
          <Route path="/business/partners" element={<AgenciesPage />} />
          <Route
            path="/business/partners/:id"
            element={<BusinessPartnerDetailsPage />}
          />
          <Route
            path="/sales/applications/motor"
            element={<ApplicationsPage />}
          />
          <Route path="/plm/policies" element={<PolicyListing />} />
          <Route path="/plm/expat-policies" element={<ExpatPolicyListing />} />
          <Route
            path="/sales/applications/health"
            element={<HealthApplicationsPage />}
          />
          <Route
            path="/sales/applications/expat"
            element={<ExpatApplicationsPage />}
          />
          <Route path="/business/users" element={<AgentPage />} />
          <Route
            path="/sales/applications/motor/:id"
            element={<ApplicationDetailsPageMotor />}
          />
          <Route
            path="/sales/applications/health/:id"
            element={<ApplicationDetailsPageHealth />}
          />
          <Route
            path="/sales/applications/expat/:id"
            element={<ApplicationDetailsPageExpat />}
          />
          <Route path="/plm/policies/:id" element={<PolicyDetailsPage />} />
          <Route
            path="/plm/expat-policies/:id"
            element={<ExpatPolicyDetailsPage />}
          />
          <Route
            path="admin/policynumberranges/:rangeType"
            element={<PolicyNumberRangesPage />}
          />
          <Route
            path="admin/nssfcodenumberranges"
            element={<NssfCodeNumberRangesPage />}
          />
          <Route path="admin/users" element={<UsersPage />} />

          {
            !isProd() &&
            <>
              <Route path="accounting/accounts" element={<AccountsPage />} />
              <Route path="accounting/transactions" element={<TransactionsPage />} />
              <Route path="accounting/customers" element={<CustomersPage />} />
              <Route path="accounting/invoices" element={<InvoicePage />} />
              <Route
                path="accounting/invoices/:id"
                element={<InvoiceDetailsPage />}
              />
              <Route path="accounting/products" element={<ProductsPage />} />
              <Route path="accounting/transfers" element={<TransfersPage />} />
              <Route path="accounting/transfer-links" element={<TransferLinksListing />} />
              <Route path="accounting/receivables-payables" element={<ReceivablesPayablesPage />} />
              <Route path="accounting/receivables-payables/:id" element={<ReceivablesPayablesPage />} />

              <Route path="salesforce/persons" element={<PersonsPage />} />
              <Route
                path="salesforce/persons/:id"
                element={<PersonDetailsPage />}
              />
              <Route
                path="salesforce/business-partners"
                element={<BusinessPartnersPage />}
              />
              <Route
                path="salesforce/business-partners/:id"
                element={<SalesforceBusinessPartnerDetailsPage />}
              />
              <Route
                path="salesforce/business-users"
                element={<BusinessUsersPage />}
              />
              <Route
                path="salesforce/business-users/:id"
                element={<SalesforceBusinessUserDetailsPage />}
              />

              <Route path="production/proposal" element={<ProposalPage />} />
              <Route path="production/policies" element={<PoliciesPage />} />
              <Route
                path="production/proposal/:id"
                element={<MainProposalPage />}
              />

              <Route
                path="production/policies/:id"
                element={<ProductionPolicyPage />}
              />

              <Route path="production/insured" element={<InsuredPage />} />
              <Route
                path="production/insured/:id"
                element={<InsuredDetailsPage />}
              />

              <Route path="plan/lines" element={<LinesPage />} />
              <Route path="plan/sublines" element={<SublinesPage />} />
              <Route path="plan/plans" element={<PlansPage />} />
              <Route path="plan/covers" element={<CoversPage />} />
              <Route path="plan/clauses" element={<ClausesPage />} />
              <Route path="plan/lines/:id" element={<LineDetailsPage />} />
              <Route path="plan/sublines/:id" element={<SublineDetailsPage />} />
              <Route path="plan/plans/:id" element={<PlanDetailsPage />} />
              <Route path="plan/covers/:id" element={<PolicyCoverDetailsPage />} />

              <Route path="configuration/taxation" element={<TaxationsPage />} />
              <Route
                path="configuration/providers"
                element={<ProvidersPage />}
              />
              <Route
                path="configuration/providers/:id"
                element={<ProviderDetailsPage />}
              />

              <Route
                path="configuration/currency-exchange-rate"
                element={<CurrencyExchangeRatesPage />}
              />
              <Route
                path="configuration/currency-exchange-rate/add-ons"
                element={<CurrencyExchangeRateNewPage />}
              />
              <Route
                path="/configuration/system-currency"
                element={<SystemCurrencyConfig />}
              />

          <Route path="configuration/taxation" element={<TaxationsPage />} />

            </>
          }

          <Route path="/download-document" element={<DownloadPage />} />
          <Route
            path="/settings/environment"
            element={<EnvironmentSettingsPage />}
          />

        </Routes>
      </PageShell>
      <StoreConfirmation confirmation={confirmation} />
    </>
  );
};

export default Shell;
