import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  company: '',
  type: '',
  name: '',
  id: '',
  isSubAccount: '',
  parentAccount: '',
  currency: '',
  description: '',
};

export const inputs = (mode: "add" | "edit" = "add", values: Record<string, any> = initialValues, lovs: Record<string, Record<string, string>>, layer2: Record<string, string>, layer3: Record<string, string>, layer4: Record<string, string>) : Record<string, DynamicFormInputType> => ({
  company: {
    name: 'company',
    title: 'Company',
    type: FormInputTypes.chips,
    disabled: mode === "edit",
    value: !isEmpty(values.company)
      ? values.company
      : [],
    selectOptions: lovs?.relatedCompanies,
    required: true,
    multiple: false
  },
  subCategory: {
    name: 'subCategory',
    title: 'Ledger',
    disabled: mode === "edit",
    type: FormInputTypes.chips,
    value: !isEmpty(values.subCategory)
      ? values.subCategory
      : [],
    selectOptions: layer4,
    required: true,
    multiple: false,
  },
  id: {
    name: 'id',
    title: 'Account ID',
    disabled: mode === "edit",
    type: FormInputTypes.number,
    value: values.id,
    required: true,
    hasPrefix: true,
    prefixValue: values.subCategoryCode,
  },
  name: {
    name: 'name',
    title: 'Account Name',
    type: FormInputTypes.text,
    value: values.name,
    required: true,
  },
  currency: {
    name: 'currency',
    title: 'Account Currency',
    type: FormInputTypes.chips,
    value: !isEmpty(values.currency)
      ? values.currency
      : [],
    required: true,
    multiple: false,
    disabled: mode === "edit",
    selectOptions: lovs?.currency,
  }
});
