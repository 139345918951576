import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { IFormSwitchDynamicProps } from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IAssignPolicyCoverDrawerProps } from '.';
import {
  createAssignPolicyCover,
  fetchedClauses,
  fetchedPolicyCovers,
  getAssignPolicyCoverEnums,
  getAssignedPolicyCoverInfo,
  updateAssignPolicyCover,
} from './queries';
import {
  extractClauses,
  extractPolicyCovers,
  LookupToList,
  mapToAssignPolicyCoverInfo,
} from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

const useStyles = makeStyles()(() => ({
  isEditableStyle: {
    marginBottom: '17px',
  },
}));

const AssignPolicyCoverDrawer: React.FC<IAssignPolicyCoverDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  lineId,
  planCurrencyId,
  planCurrencyTitle,
  assignPolicyCoverId,
  planLineExternalCode,
}) => {
  const [booted, setBooted] = useState<boolean>(false);

  const { classes } = useStyles();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [assignPolicyCoverDetailsListQuery] = useLazyQuery(
    getAssignPolicyCoverEnums()
  );

  const [fetchedPolicyCoversLazy] = useLazyQuery(fetchedPolicyCovers());
  const [fetchedClausesLazy] = useLazyQuery(fetchedClauses());

  const [assignPolicyCoverAction] = useMutation(
    assignPolicyCoverId ? updateAssignPolicyCover() : createAssignPolicyCover()
  );

  const [policyCoverInfoQuery] = useLazyQuery(getAssignedPolicyCoverInfo(), {
    variables: { id: assignPolicyCoverId },
  });

  const getAssignPolicyCover = async () => {
    const result = await policyCoverInfoQuery({
      variables: { id: assignPolicyCoverId },
    });

    return result;
  };

  const getLovs = async () => {
    const data = await assignPolicyCoverDetailsListQuery({});
    const newAssignPolicyCoverEnums = LookupToList(data.data);
    const result: Record<string, Record<string, string>> = {
      premiumType: newAssignPolicyCoverEnums['coverPremiumTypes'],
      excessOnClaimType: newAssignPolicyCoverEnums['excessOnClaimTypes'],
      policyCover: {},
      clauseReference: {},
    };

    return result;
  };

  const getPolicyCoverList = async (inputValue: any, newValues: any) => {
    if (inputValue !== null && inputValue.length >= 3) {
      const data = await fetchedPolicyCoversLazy({
        variables: {
          selectedLineIds: [lineId],
          searchKeyword: inputValue,
        },
      });

      if (data.data) {
        const extractedFetchedPolicyCovers = extractPolicyCovers(data.data);

        return extractedFetchedPolicyCovers;
      }
    }
    return null;
  };

  const getClauseList = async (inputValue: any, newValues: any) => {
    if (inputValue !== null && inputValue.length >= 3) {
      const data = await fetchedClausesLazy({
        variables: {
          selectedLineIds: [lineId],
          searchKeyword: inputValue,
        },
      });

      if (data.data) {
        const extractedFetchedPolicyCovers = extractClauses(data.data);

        return extractedFetchedPolicyCovers;
      }
    }
    return null;
  };

  const initialize = async () => {
    try {
      const newLovs = await getLovs();
      let updatedInputs = getInputs(undefined, newLovs, planLineExternalCode);

      let newValues = {
        policyCover: '',
        currency: planCurrencyTitle || '',
        sumInsured: '',
        premiumType: 'VALUE',
        premiumPercentage: '',
        premium: '',
        additiveSumInsured: false,
        main: false,
        mandatory: false,
        editable: false,
        excessOnClaimType: 'PERCENTAGE',
        excessOnClaim: '',
        excessOnClaimPercentage: '',
        excessOnClaimDays: '',
        clauseReference: '',
      };

      if (assignPolicyCoverId) {
        const resultData = await getAssignPolicyCover();
        const info = mapToAssignPolicyCoverInfo(resultData.data);

        if (info.policyCover && info.policyCoverTitle) {
          newLovs.policyCover[info.policyCover] = info.policyCoverTitle;
        }

        if (info.clauseReference && info.clauseReferenceTitle) {
          newLovs.clauseReference[info.clauseReference] =
            info.clauseReferenceTitle;
        }

        newValues = {
          policyCover: info.policyCover,
          currency: planCurrencyTitle,
          sumInsured: info.coverSumInsured?.toString(),
          premiumType: info.coverPremiumType,
          premiumPercentage: info.coverPremiumPercentage,
          premium: info.coverPremiumValue?.toString(),
          additiveSumInsured: info.additiveSumInsured,
          main: info.main,
          mandatory: info.mandatory,
          editable: info.editable,
          excessOnClaimType: info.excessOnClaimType,
          excessOnClaim: info.excessOnClaimAmount?.toString(),
          excessOnClaimPercentage: info.excessOnClaimPercentage?.toString(),
          excessOnClaimDays: info.excessOnClaimDays?.toString(),
          clauseReference: info.clauseReference,
        };
      }

      updatedInputs.currency.value = planCurrencyTitle;
      (updatedInputs.editable as IFormSwitchDynamicProps).className =
        classes.isEditableStyle;

      setLovs(newLovs);
      setValues(newValues);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(undefined, lovs, planLineExternalCode),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const isPremiumPercentage =
        data.premiumType.toLowerCase() === 'percentage';
      const variables = {
        planCoverInputs: {
          planID: planId,
          clauseID: values.clauseReference || null,
          policyCoverID: data.policyCover || '',
          coverPremiumPercentage: isPremiumPercentage
            ? data.premiumPercentage !== '' || 0
              ? parseFloat(data.premiumPercentage) / 100
              : 0
            : 0,
          coverPremiumType: !isEmpty(data.premiumType)
            ? data.premiumType
            : null,
          coverPremiumValue: !isPremiumPercentage ? Number(data.premium) : 0,
          coverSumInsured: Number(data.sumInsured),
          excessOnClaimAmount:
            data.excessOnClaimType.toLowerCase() === 'value'
              ? Number(data.excessOnClaim)
              : 0,
          excessOnClaimDays:
            data.excessOnClaimType.toLowerCase() === 'days'
              ? Number(data.excessOnClaimDays)
              : 0,
          excessOnClaimPercentage:
            data.excessOnClaimType.toLowerCase() === 'percentage'
              ? data.excessOnClaimPercentage !== ''
                ? parseFloat(data.excessOnClaimPercentage) / 100
                : 0
              : 0,
          excessOnClaimType: !isEmpty(data.excessOnClaimType)
            ? data.excessOnClaimType
            : null,
          isMain: data.main,
          isMandatory: data.mandatory,
          isEditable: data.editable,
          sumInsuredIsAdditive: data.additiveSumInsured,
        },
      };

      const response = await assignPolicyCoverAction({
        variables: assignPolicyCoverId
          ? { ...variables, entityId: assignPolicyCoverId }
          : variables,
        errorPolicy: 'all',
      });

      if (isEmpty(response.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {assignPolicyCoverId
              ? 'Assigned Policy Cover details successfully updated.'
              : 'Policy cover successfully assigned.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
        );
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onChange = async (
    fieldName: string,
    value: unknown,
    newValues: Record<string, any>
  ) => {
    setValues(newValues);
    // if(fieldName === "policyCover"){
    //   await getPolicyCoverList(value, newValues);
    // }
  };

  const onSearch = async (fieldName: string, value: string) => {
    const newLovs = _.cloneDeep(lovs);
    if (fieldName === 'policyCover') {
      newLovs.policyCover =
        (await getPolicyCoverList(value, {})) || lovs.policyCover;
    } else if (fieldName === 'clauseReference') {
      await getClauseList(value, {});
      newLovs.clauseReference =
        (await getClauseList(value, {})) || lovs.clauseReference;
    }
    setLovs(newLovs);
  };

  const inputForm = useMemo(() => {
    const result = getInputs(
      (values as any) || undefined,
      lovs,
      planLineExternalCode
    );

    return result;
  }, [values, lovs, planLineExternalCode]);

  return (
    <GenericDrawer
      title={
        assignPolicyCoverId
          ? 'Modify Assigned Policy Cover'
          : 'Assign Policy Cover'
      }
      onClose={onClose}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={(values) => submitForm(values)}
          buttonText={'Submit'}
          submitButtonState={submitButtonState}
          disableForm={formDisabled}
          title='Information'
          hasDoprdownSpecificBehavior={true}
          onChange={onChange}
          onSearch={async (f, v) => {
            await onSearch(f, v);
          }}
        />
      )}
    </GenericDrawer>
  );
};

export default AssignPolicyCoverDrawer;
