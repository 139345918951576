import React, {useState} from 'react';
import {EnhancedUpdatedTableFilterType} from '.';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {makeStyles} from 'tss-react/mui';

export interface EnhancedTableToolbarProps {
  filters?: Record<string, EnhancedUpdatedTableFilterType>;
  handleFilterChange: (name: string, value: string[] | Date[], type: string) => void;
  onFilterClear(): void;
  onFilterAction(): void;
}

const useStyles = makeStyles<{expanded: boolean}>()((theme, {expanded}) => ({
  mainContainer: {
    backgroundColor: '#EFF1F7',
    padding: expanded ? '13px 26px 26px' : '13px 26px 13px',
  },
  filtersTitle: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '15px',
    lineHeight: '19px',
    color: '#344054',
    margin: '0 0 0 8px',
  },
  accordionSummary: {
    minHeight: 'unset!important',
    padding: '0',
    margin: '0',
    '& .MuiAccordionSummary-content': {
      padding: '0',
      margin: expanded ? '0 0 23px' : '0',
    },
  },
  accordionDetails: {
    padding: '0',
  },
}));

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  // actions,
  filters,
  handleFilterChange,
  onFilterClear,
  onFilterAction,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const {classes} = useStyles({expanded: expanded});

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return filters ? (
    <Accordion
      elevation={0}
      className={classes.mainContainer}
      onChange={() => handleChange()}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.609"
            height="10.667"
            viewBox="0 0 10.609 10.667"
          >
            <path
              id="Path_19117"
              data-name="Path 19117"
              d="M4.667,4h6.667L7.993,8.2ZM2.833,3.74C4.18,5.467,6.667,8.667,6.667,8.667v4a.669.669,0,0,0,.667.667H8.667a.669.669,0,0,0,.667-.667v-4s2.48-3.2,3.827-4.927a.665.665,0,0,0-.527-1.073H3.36A.665.665,0,0,0,2.833,3.74Z"
              transform="translate(-2.692 -2.667)"
              fill="#344054"
            />
          </svg>
          <span className={classes.filtersTitle}>{"Filters"}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {/* <EnhancedToolbar
          filters={filters}
          handleFilterChange={(name, value, type) => handleFilterChange(name, value, type)}
          onClear={onFilterClear}
          onFilterAction={onFilterAction}
        ></EnhancedToolbar> */}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default EnhancedTableToolbar;
