import {
  IGenericAllRiskPlanRatesTableData,
  IGenericExpatPlanRatesTableData,
  IGenericMedicalPlanRatesTableData,
  IGenericPlanCoversRatesTableData,
  IGenericPolicyCoverTableData,
  IGenericTravelPlanRatesTableData,
} from '.';
import { IAbstractRecord } from '../../models';
import { IListingData } from '../../models/listing';
import {
  capitalizeFirstLetter,
  getTitlesFromMultiselectData,
  valueCommaSeparated,
} from '../../utils/formatting-utils';
import { steps } from './content';

export function entityToPlanDetails(data: any): any {
  const sublineDetailsInfo =
    data?.PlanConfigManagement?.entities?.plan?.views?.PlanConfigManagement_all
      ?.properties;

  return {
    planName: sublineDetailsInfo?.Name,
    sublineName:
      sublineDetailsInfo?.SublineID?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    sublineId: sublineDetailsInfo?.SublineID?.id,
    lineName:
      sublineDetailsInfo?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    lineId: sublineDetailsInfo?.LineID?.id,
    externalCode: sublineDetailsInfo?.ExternalCode,
    arabicName: sublineDetailsInfo?.NameArabic,
    effectiveFrom: sublineDetailsInfo?.EffectiveFrom,
    effectiveTo: sublineDetailsInfo?.EffectiveTo,
    abbreviation: sublineDetailsInfo?.Abbreviation,
    planCurrency: sublineDetailsInfo?.PlanCurrency?.Id,
    planCurrencyTitle: sublineDetailsInfo?.PlanCurrency?.Title,
    maxNumberofInsured: sublineDetailsInfo?.MaxNbInsured,
    maxNumberofBeneficiary: sublineDetailsInfo?.MaxNbBeneficiary,
    clauseEditable: sublineDetailsInfo?.ClauseEditable,

    acalCategory: sublineDetailsInfo?.AcalCategory?.Id,
    scratching: sublineDetailsInfo?.Scratching,
    scratchingTitle: capitalizeFirstLetter(sublineDetailsInfo?.Scratching),
    acalCategoryTitle: sublineDetailsInfo?.AcalCategory?.Title,
    sOSService: sublineDetailsInfo?.SOSService,
    sOSServiceTitle: capitalizeFirstLetter(sublineDetailsInfo?.SOSService),
    replacementCarApplied: sublineDetailsInfo?.ReplacementCarApplied,
    replacementCarAppliedTitle: capitalizeFirstLetter(
      sublineDetailsInfo?.ReplacementCarApplied
    ),
    replacementCarValue: sublineDetailsInfo?.ReplacementCarValue,
    replacementCarDays: sublineDetailsInfo?.ReplacementCarDays,
    lineIdExternalCode:
      sublineDetailsInfo?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode,

    status: sublineDetailsInfo?.PlanStatus,

    createdBy: `${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_firstName} ${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: sublineDetailsInfo?.createdOn,
    modifiedBy: `${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_firstName} ${data?.PlanConfigManagement?.entities?.plan?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: sublineDetailsInfo?.modifiedOn,

    specialCommissionApplicable:
      sublineDetailsInfo?.SpecialCommissionApplicable,
    specialCommissionType: sublineDetailsInfo?.SpecialCommissionType,
    specialCommissionValue: sublineDetailsInfo?.SpecialCommissionValue,
    specialCommissionValueCurrency:
      sublineDetailsInfo?.SpecialCommissionValueCurrency?.Id,
    specialCommissionValueCurrencyTitle:
      sublineDetailsInfo?.SpecialCommissionValueCurrency?.Title,
    specialCommissionRate: sublineDetailsInfo?.SpecialCommissionRate,
    specialCommissionRateTitle: `${parseFloat(
      (Number(sublineDetailsInfo.SpecialCommissionRate) * 100).toFixed(2)
    )}%`,
    specialCommissionApplicableOn:
      sublineDetailsInfo?.SpecialCommissionApplicableOn?.Id,
    specialCommissionApplicableOnTitle:
      sublineDetailsInfo?.SpecialCommissionApplicableOn?.Title,

    policyCost: sublineDetailsInfo?.PolicyCost,
    policyCostTitle:
      sublineDetailsInfo?.PolicyCost &&
      `${sublineDetailsInfo?.PlanCurrency?.Title} ${valueCommaSeparated(
        sublineDetailsInfo?.PolicyCost
      )}`,
    chargesPercentage: sublineDetailsInfo?.ChargesPercentage,
    chargesPercentageTitle:
      sublineDetailsInfo?.ChargesPercentage &&
      `${Math.round(
        parseFloat(
          (Number(sublineDetailsInfo.ChargesPercentage) * 100).toFixed(10)
        )
      )}%`,
    tPAFeesType: sublineDetailsInfo?.TPAFeesType,
    tPAFeesTypeTitle: capitalizeFirstLetter(sublineDetailsInfo?.TPAFeesType),
    tPAFeesAmount: sublineDetailsInfo?.TPAFeesAmount,
    tPAFeesAmountTitle:
      sublineDetailsInfo?.TPAFeesAmount &&
      `${sublineDetailsInfo?.PlanCurrency?.Title} ${valueCommaSeparated(
        sublineDetailsInfo?.TPAFeesAmount
      )}`,
    tPAFeesPercentage: sublineDetailsInfo?.TPAFeesPercentage,
    tPAFeesPercentageTitle:
      sublineDetailsInfo?.TPAFeesPercentage &&
      `${Math.round(
        parseFloat(
          (Number(sublineDetailsInfo.TPAFeesPercentage) * 100).toFixed(10)
        )
      )}%`,
    tPAFeesApplicableOn: sublineDetailsInfo?.TPAFeesApplicableOn,
    tPAFeesApplicableOnTitle: capitalizeFirstLetter(
      sublineDetailsInfo?.TPAFeesApplicableOn?.replaceAll('_', ' ')
    ),
    minTPA: sublineDetailsInfo?.MinTPA,
    minTPATitle:
      sublineDetailsInfo?.MinTPA &&
      `${sublineDetailsInfo?.PlanCurrency?.Title} ${valueCommaSeparated(
        sublineDetailsInfo?.MinTPA
      )}`,
    maxTPA: sublineDetailsInfo?.MaxTPA,
    maxTPATitle:
      sublineDetailsInfo?.MaxTPA &&
      `${sublineDetailsInfo?.PlanCurrency?.Title} ${valueCommaSeparated(
        sublineDetailsInfo?.MaxTPA
      )}`,
  };
}

export const getPlanStatus = (status: string) => {
  switch (status) {
    case 'inactive':
      return steps[0];
    case 'active':
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToPolicyCoversListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPolicyCoverList.items.forEach(
    (obj: IGenericPolicyCoverTableData, i: number) => {
      list[i] = {
        planConfigManagement_PolicyCover_Id:
          obj.planConfigManagement_PolicyCover_Id,
        planConfigManagement_PolicyCover_Name:
          obj.planConfigManagement_PolicyCover_Name,
        planConfigManagement_PolicyCover_ExternalCode:
          obj.planConfigManagement_PolicyCover_ExternalCode,
        planConfigManagement_PolicyCover_NameArabic:
          obj.planConfigManagement_PolicyCover_NameArabic,
        planConfigManagement_PolicyCover_PrintingOrder:
          obj.planConfigManagement_PolicyCover_PrintingOrder,
        planConfigManagement_PolicyCover_PolicyCoverDescription:
          obj.planConfigManagement_PolicyCover_PolicyCoverDescription,
        planConfigManagement_PolicyCover_createdOn:
          obj.planConfigManagement_PolicyCover_createdOn,
        planConfigManagement_PolicyCover_modifiedOn:
          obj.planConfigManagement_PolicyCover_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPolicyCoverList?.paging
        ?.totalCount,
  };
}

export function mapToAllRiskPlanRatesListingData(
  data: any,
  planCurrency: string
): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.items?.forEach(
    (obj: IGenericAllRiskPlanRatesTableData, i: number) => {
      list[i] = {
        planConfigManagement_PlanSpecificAllRisk_Id:
          obj.planConfigManagement_PlanSpecificAllRisk_Id,
        // planConfigManagement_PlanSpecificAllRisk_CarValueFrom:
        //   obj.planConfigManagement_PlanSpecificAllRisk_CarValueFrom,
        planConfigManagement_PlanSpecificAllRisk_CarValueFrom: `${planCurrency} ${valueCommaSeparated(
          obj.planConfigManagement_PlanSpecificAllRisk_CarValueFrom
        )}`,
        // planConfigManagement_PlanSpecificAllRisk_CarValueTo:
        //   obj.planConfigManagement_PlanSpecificAllRisk_CarValueTo,
        planConfigManagement_PlanSpecificAllRisk_CarValueTo: `${planCurrency} ${valueCommaSeparated(
          obj.planConfigManagement_PlanSpecificAllRisk_CarValueTo
        )}`,
        planConfigManagement_PlanSpecificAllRisk_CarAgeFrom:
          obj.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom,
        planConfigManagement_PlanSpecificAllRisk_CarAgeTo:
          obj.planConfigManagement_PlanSpecificAllRisk_CarAgeTo,
        // planConfigManagement_PlanSpecificAllRisk_CarCategory:
        //   obj.planConfigManagement_PlanSpecificAllRisk_CarCategory?.Id,
        planConfigManagement_PlanSpecificAllRisk_CarCategory:
          obj.planConfigManagement_PlanSpecificAllRisk_CarCategory?.Title,
        // planConfigManagement_PlanSpecificAllRisk_MinimumPremium:
        //   obj.planConfigManagement_PlanSpecificAllRisk_MinimumPremium,
        planConfigManagement_PlanSpecificAllRisk_MinimumPremium: `${planCurrency} ${obj.planConfigManagement_PlanSpecificAllRisk_MinimumPremium}`,
        planConfigManagement_PlanSpecificAllRisk_Rate: `${
          Math.round(
            Number(obj.planConfigManagement_PlanSpecificAllRisk_Rate) *
              100 *
              100000
          ) / 100000
        }%`,
        planConfigManagement_PlanSpecificAllRisk_AgencyYear:
          obj.planConfigManagement_PlanSpecificAllRisk_AgencyYear,
        planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear:
          obj.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificAllRiskList?.paging
        ?.totalCount,
  };
}

export function mapToExpatPlanRatesListingData(
  data: any,
  planCurrency: string
): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.items?.forEach(
    (obj: IGenericExpatPlanRatesTableData, i: number) => {
      list[i] = {
        planConfigManagement_PlanSpecificExpat_Id:
          obj.planConfigManagement_PlanSpecificExpat_Id,
        planConfigManagement_PlanSpecificExpat_AgeFrom:
          obj.planConfigManagement_PlanSpecificExpat_AgeFrom,
        planConfigManagement_PlanSpecificExpat_AgeTo:
          obj.planConfigManagement_PlanSpecificExpat_AgeTo,
        planConfigManagement_PlanSpecificExpat_Gender: capitalizeFirstLetter(
          obj.planConfigManagement_PlanSpecificExpat_Gender
        ),
        planConfigManagement_PlanSpecificExpat_OccupationClass:
          obj.planConfigManagement_PlanSpecificExpat_OccupationClass?.Title,
        planConfigManagement_PlanSpecificExpat_Nationality:
          getTitlesFromMultiselectData(
            obj.planConfigManagement_PlanSpecificExpat_Nationality
          ),
        // planConfigManagement_PlanSpecificExpat_Premium:
        //   obj.planConfigManagement_PlanSpecificExpat_Premium,
        planConfigManagement_PlanSpecificExpat_Premium: `${planCurrency} ${obj.planConfigManagement_PlanSpecificExpat_Premium}`,
        planConfigManagement_PlanSpecificExpat_MedicalClass:
          obj.planConfigManagement_PlanSpecificExpat_MedicalClass?.Title,
        planConfigManagement_PlanSpecificExpat_Emergency: capitalizeFirstLetter(
          obj?.planConfigManagement_PlanSpecificExpat_Emergency || ''
        ),
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificExpatList?.paging
        ?.totalCount,
  };
}

export function mapToTravelPlanRatesListingData(
  data: any,
  planCurrency: string
): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.items?.forEach(
    (obj: IGenericTravelPlanRatesTableData, i: number) => {
      list[i] = {
        planConfigManagement_PlanSpecificTravel_Id:
          obj.planConfigManagement_PlanSpecificTravel_Id,
        planConfigManagement_PlanSpecificTravel_AgeFrom:
          obj.planConfigManagement_PlanSpecificTravel_AgeFrom,
        planConfigManagement_PlanSpecificTravel_AgeTo:
          obj.planConfigManagement_PlanSpecificTravel_AgeTo,
        planConfigManagement_PlanSpecificTravel_TravelDurationFrom:
          obj.planConfigManagement_PlanSpecificTravel_TravelDurationFrom,
        planConfigManagement_PlanSpecificTravel_TravelDurationTo:
          obj.planConfigManagement_PlanSpecificTravel_TravelDurationTo,
        planConfigManagement_PlanSpecificTravel_TravelDestination:
          obj.planConfigManagement_PlanSpecificTravel_TravelDestination?.Title,
        planConfigManagement_PlanSpecificTravel_NumberOfAdults:
          obj.planConfigManagement_PlanSpecificTravel_NumberOfAdults,
        planConfigManagement_PlanSpecificTravel_NumberOfChildren:
          obj.planConfigManagement_PlanSpecificTravel_NumberOfChildren,
        // planConfigManagement_PlanSpecificTravel_Premium:
        //   obj.planConfigManagement_PlanSpecificTravel_Premium,
        planConfigManagement_PlanSpecificTravel_Premium: `${planCurrency} ${obj.planConfigManagement_PlanSpecificTravel_Premium}`,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificTravelList?.paging
        ?.totalCount,
  };
}

export function mapToMedicalPlanRatesListingData(
  data: any,
  planCurrency: string
): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.items?.forEach(
    (obj: IGenericMedicalPlanRatesTableData, i: number) => {
      list[i] = {
        planConfigManagement_PlanSpecificMedical_Id:
          obj.planConfigManagement_PlanSpecificMedical_Id,
        // planConfigManagement_PlanSpecificMedical_PolicyCoverID:
        //   obj.planConfigManagement_PlanSpecificMedical_PolicyCoverID?.id,
        planConfigManagement_PlanSpecificMedical_PolicyCoverID:
          obj.planConfigManagement_PlanSpecificMedical_PolicyCoverID?.views
            ?.PlanConfigManagement_all?.properties?.Name,
        planConfigManagement_PlanSpecificMedical_NbOfAdherent:
          obj.planConfigManagement_PlanSpecificMedical_NbOfAdherent,
        planConfigManagement_PlanSpecificMedical_Class:
          obj.planConfigManagement_PlanSpecificMedical_Class?.Title,
        planConfigManagement_PlanSpecificMedical_AgeFrom:
          obj.planConfigManagement_PlanSpecificMedical_AgeFrom,
        planConfigManagement_PlanSpecificMedical_AgeTo:
          obj.planConfigManagement_PlanSpecificMedical_AgeTo,
        planConfigManagement_PlanSpecificMedical_CoNssf: capitalizeFirstLetter(
          obj.planConfigManagement_PlanSpecificMedical_CoNssf
        ),
        // planConfigManagement_PlanSpecificMedical_NetPremium:
        //   obj.planConfigManagement_PlanSpecificMedical_NetPremium,
        planConfigManagement_PlanSpecificMedical_NetPremium: `${planCurrency} ${valueCommaSeparated(
          obj.planConfigManagement_PlanSpecificMedical_NetPremium
        )}`,
        // planConfigManagement_PlanSpecificMedical_TPAFeesPercentage:
        //   obj.planConfigManagement_PlanSpecificMedical_TPAFeesPercentage,
        planConfigManagement_PlanSpecificMedical_TPAFeesPercentage: `${Math.round(
          parseFloat(
            (
              Number(
                obj.planConfigManagement_PlanSpecificMedical_TPAFeesPercentage
              ) * 100
            ).toFixed(10)
          )
        )}%`,
        // planConfigManagement_PlanSpecificMedical_MinTPAFees:
        //   obj.planConfigManagement_PlanSpecificMedical_MinTPAFees,
        planConfigManagement_PlanSpecificMedical_MinTPAFees: `${planCurrency} ${valueCommaSeparated(
          obj.planConfigManagement_PlanSpecificMedical_MinTPAFees
        )}`,
        // planConfigManagement_PlanSpecificMedical_MaxTPAFees:
        //   obj.planConfigManagement_PlanSpecificMedical_MaxTPAFees,
        planConfigManagement_PlanSpecificMedical_MaxTPAFees: `${planCurrency} ${valueCommaSeparated(
          obj.planConfigManagement_PlanSpecificMedical_MaxTPAFees
        )}`,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.paging
        ?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.paging
        ?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanSpecificMedicalList?.paging
        ?.totalCount,
  };
}

export function mapToPlanCoversRatesListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.PlanConfigManagement?.queries?.getPlanCoverList?.items?.forEach(
    (obj: IGenericPlanCoversRatesTableData, i: number) => {
      list[i] = {
        planConfigManagement_PlanCover_Id:
          obj.planConfigManagement_PlanCover_Id,
        planConfigManagement_PlanCover_PolicyCoverID_ID:
          obj.planConfigManagement_PlanCover_PolicyCoverID?.id,
        planConfigManagement_PlanCover_PolicyCoverID: `${obj.planConfigManagement_PlanCover_PolicyCoverID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.planConfigManagement_PlanCover_PolicyCoverID?.views?.PlanConfigManagement_all?.properties?.Name}`,
        planConfigManagement_PlanCover_IsMain:
          obj.planConfigManagement_PlanCover_IsMain ? 'Yes' : 'No',
        planConfigManagement_PlanCover_IsMandatory:
          obj.planConfigManagement_PlanCover_IsMandatory ? 'Yes' : 'No',
        planConfigManagement_PlanCover_IsEditable:
          obj.planConfigManagement_PlanCover_IsEditable ? 'Yes' : 'No',
        planConfigManagement_PlanCover_CoverPremiumType: capitalizeFirstLetter(
          obj.planConfigManagement_PlanCover_CoverPremiumType
        ),
        planConfigManagement_PlanCover_CoverPremiumValue:
          obj.planConfigManagement_PlanCover_CoverPremiumType === 'VALUE'
            ? `${
                obj.planConfigManagement_PlanCover_PlanID?.views
                  ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Title
              } ${valueCommaSeparated(
                obj.planConfigManagement_PlanCover_CoverPremiumValue
              )}`
            : `${parseFloat(
                (
                  Number(
                    obj.planConfigManagement_PlanCover_CoverPremiumPercentage
                  ) * 100
                ).toFixed(2)
              )}%`,
        planConfigManagement_PlanCover_ExcessOnClaimAmount:
          obj.planConfigManagement_PlanCover_ExcessOnClaimType === 'VALUE'
            ? `${
                obj.planConfigManagement_PlanCover_PlanID?.views
                  ?.PlanConfigManagement_all?.properties?.PlanCurrency?.Title
              } ${valueCommaSeparated(
                obj.planConfigManagement_PlanCover_ExcessOnClaimAmount
              )}`
            : obj.planConfigManagement_PlanCover_ExcessOnClaimType ===
              'PERCENTAGE'
            ? `${parseFloat(
                (
                  Number(
                    obj.planConfigManagement_PlanCover_ExcessOnClaimPercentage
                  ) * 100
                ).toFixed(2)
              )}%`
            : `${obj.planConfigManagement_PlanCover_ExcessOnClaimDays} Days`,

        planConfigManagement_PlanCover_CoverSumInsured: valueCommaSeparated(
          obj.planConfigManagement_PlanCover_CoverSumInsured
        ),
        planConfigManagement_PlanCover_SumInsuredIsAdditive:
          obj.planConfigManagement_PlanCover_SumInsuredIsAdditive
            ? 'Yes'
            : 'No',

        planConfigManagement_PlanCover_ClauseID:
          obj.planConfigManagement_PlanCover_ClauseID?.views
            ?.PlanConfigManagement_all?.properties?.ExternalCode,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanCoverList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanCoverList?.paging?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanCoverList?.paging?.totalCount,
  };
}
