import { gql } from "@apollo/client";

export function getProductionPolicyDetails() {
  return gql`
    query getProductionPolicyDetails($id: String!) {
      Production {
        entities {
          policy(id: $id) {
            id
            views {
              Production_all {
                properties {
                  Id
                  PolicyNumber
                  PolicyName
                  ClauseText
                  InternalCode
                  PolicyEffectiveDate
                  PolicyExpiryDate
                  modifiedOn
                  createdOn
                  Status
                  MotorDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          VignetteCode
                          Vignette
                          AgencyRepairYear
                          NameOnLicenseEn
                          NameOnLicenseAr
                          VehicleID {
                            views {
                              Production_all {
                                properties {
                                  PlateCode {
                                    Id
                                    Code
                                    Title
                                  }
                                  PlateNumber
                                  UsageType {
                                    Id
                                    Title
                                  }
                                  BodyType {
                                    Id
                                    Title
                                  }
                                  EngineType {
                                    Id
                                    Title
                                  }
                                  Make {
                                    Id
                                    Title
                                  }
                                  Model {
                                    Id
                                    Title
                                  }
                                  YearOfMake
                                  Engine
                                  Weight
                                  EngineSize
                                  SeatingCapacity
                                  Chassis
                                  HorsePower
                                  CarValue
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ExpatDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          Exclusion
                          Remarks
                          MedicalClass {
                            Code
                            Title
                            Id
                          }
                          Continuity
                          ContinuityNumber
                        }
                      }
                    }
                  }
                  PrimaryBPID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          RelatedCompany {
                            Code
                            Title
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  BBBDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          NumberOfEmployees
                          UnderwritingYear
                          Infidelity
                          Burglary
                          HoldUp
                          InSafe
                          InTransit
                          ForgedCheckes
                          CounterFeitCurrencies
                          Clause6
                          BranchName
                          IsMain
                          IsAgency
                          Falsification
                        }
                      }
                    }
                  }
                  PlanID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                          ClauseEditable
                          MaxNbInsured
                          AcalCategory {
                            Id
                            Code
                            Title
                          }
                          SOSService
                          ReplacementCarValue
                          ReplacementCarDays
                          ReplacementCarApplied
                        }
                      }
                    }
                  }
                  BusinessUserID {
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          AllowPlanCoverEdits
                          AllowClauseEdits
                          EditableLineChargesIDs {
                            id
                            views {
                              PlanConfigManagement_all {
                                properties {
                                  Id
                                }
                              }
                            }
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              production {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
              production {
                pricingOption {
                  pricingOption_PolicyCost
                  pricingOption_ChargesPercentage
                  pricingOption_TPAFeesType
                  pricingOption_TPAFeesAmount
                  pricingOption_TPAFeesPercentage
                  pricingOption_TPAFeesApplicableOn
                  pricingOption_BasicPremium
                  pricingOption_PlanToPolicyRate
                  pricingOption_NetPremium
                  pricingOption_GrossPremium
                  pricingOption_TaxOnCommissionAmount
                  pricingOption_ReinsuranceTaxAmount
                  pricingOption_MunicipalityTaxAmount
                  pricingOption_TotalPremium
                  pricingOption_ChargesAmount
                  pricingOption_TPAFeesTotalAmount
                  pricingOption_FixedStampAmount
                  pricingOption_ProportionalStampAmount
                  pricingOption_Status
                  pricingOption_createdOn
                  pricingOption_SumInsured
                  pricingOption_PricingOptionCurrency {
                    Title
                    Symbol
                    Code
                  }
                }
              }
            }
          }
          policyDocument(id: $id) {
            id
            views {
              Production_download {
                properties {
                  PolicyID {
                    id
                  }
                  Document {
                    id
                    location
                    path
                    fileName
                    length
                    contentType
                  }
                  Version
                  DocumentName
                  DocumentType
                  UploadedManually
                }
              }
            }
          }
        }
        queries {
          getPolicyPlanCovers(selectedPolicyID: $id) {
            production_PolicyPlanCover_ProposalID {
              id
              views {
                Production_all {
                  properties {
                    PolicyCurrency {
                      Code
                      Title
                      Symbol
                    }
                  }
                }
              }
            }
            policyCover_Name
            production_PolicyPlanCover_Id
            production_PolicyPlanCover_CoverPremiumPercentage
            production_PolicyPlanCover_CoverPremiumValue
            production_PolicyPlanCover_ExcessOnClaimType
            production_PolicyPlanCover_ExcessOnClaimAmount
            production_PolicyPlanCover_ExcessOnClaimPercentage
            production_PolicyPlanCover_ExcessOnClaimDays
            production_PolicyPlanCover_ClauseID {
              id
            }
            production_PolicyPlanCover_CoverPremium
            production_PolicyPlanCover_TotalExcessOnClaim
            production_PolicyPlanCover_CoverSumInsuredBasic
            production_PolicyPlanCover_CoverPremiumBasic
            production_PolicyPlanCover_CoverSumInsuredArabic
            production_PolicyPlanCover_CoverPremiumArabic
            planCover_Id
            planCover_PolicyCoverID {
              id
            }
            production_PolicyPlanCover_IsMain
            production_PolicyPlanCover_IsEditable
            production_PolicyPlanCover_CoverSumInsured
            production_PolicyPlanCover_SumInsuredIsAdditive
            production_PolicyPlanCover_IsMandatory
            production_PolicyPlanCover_CoverPremiumType
            production_PolicyPlanCover_CoverEffectiveFrom
            production_PolicyPlanCover_CoverEffectiveTo
          }
          getPolicyBankersBlanketBondDetails(selectedPolicyID: $id) {
            production_PolicyBBBond_BranchName
            production_PolicyBBBond_IsMain
            production_PolicyBBBond_IsAgency
            production_PolicyBBBond_NumberOfEmployees
            production_PolicyBBBond_UnderwritingYear
            production_PolicyBBBond_Infidelity
            production_PolicyBBBond_Burglary
            production_PolicyBBBond_HoldUp
            production_PolicyBBBond_InSafe
            production_PolicyBBBond_InTransit
            production_PolicyBBBond_ForgedCheckes
            production_PolicyBBBond_CounterFeitCurrencies
            production_PolicyBBBond_Clause6
            production_PolicyBBBond_Falsification
          }
          getPolicyBusienssPartnerDetails(selectedPolicyID: $id) {
            production_PolicyBusinessPartner_Id
            production_PolicyBusinessPartner_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            production_PolicyBusinessPartner_PlanCommissionType
            production_PolicyBusinessPartner_PlanCommissionRate
            production_PolicyBusinessPartner_CommissionPercentage
            production_PolicyBusinessPartner_PlanCommissionAmount
            production_PolicyBusinessPartner_IsPrimary
            production_PolicyBusinessPartner_ApplicableOn {
              Code
              createdOn
              Status
            }

            production_PolicyBusinessPartner_PolicyCommissionAmount
          }
          GetPolicyPerson(policyID: $id) {
            production_PolicyPerson_Id
            production_PolicyPerson_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                    PersonType
                  }
                }
              }
            }
            production_PolicyPerson_Percentage
            production_PolicyPerson_PersonCode
            production_PolicyPerson_FullName
            production_PolicyPerson_Type
            production_PolicyPerson_Profession
            production_PolicyPerson_DateOfBirth
            production_PolicyPerson_NationalityOne {
              Title
            }
            production_PolicyPerson_PhoneDetails {
              PhoneType
              PhoneNumber
            }
            production_PolicyPerson_PersonAddressID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    IsPrimary
                    Address {
                      Country {
                        Id
                        Title
                      }
                      City
                      Street
                      Building
                    }
                  }
                }
              }
            }
            production_PolicyPerson_Address {
              Building
              Street
              City
              Country {
                Id
                Title
              }
              AddressDetails
            }
            production_PolicyPerson_OrderNumber
          }
          getPolicyMarineDetails(selectedPolicyID: $id) {
            production_PolicyMarine_VesselAge
            production_PolicyMarine_VesselName
            production_PolicyMarine_From
            production_PolicyMarine_To
            production_PolicyMarine_Via
            production_PolicyMarine_Survey
            production_PolicyMarine_CargoContract
            production_PolicyMarine_LCNumber
            production_PolicyMarine_LCIssueDate
            production_PolicyMarine_Matter {
              Code
              Title
            }
            production_PolicyMarine_Description
            production_PolicyMarine_Bank
            production_PolicyMarine_ClaimsPayableBy
            production_PolicyMarine_Loading
            production_PolicyMarine_Remarks
            production_PolicyMarine_SumPerShipment
          }
        }
      }
    }
  `;
}

export function getProductionDocuments() {
  return gql`
    query getProductionDocuments($id: String!) {
      Production {
        queries {
          regeneratedDocuments: getNewPolicyDocuments(
            policyId: $id
            uploadedManually: false
          ) {
            production_PolicyDocument_Id
            production_PolicyDocument_PolicyID {
              id
            }
            production_PolicyDocument_Document {
              id
              location
              path
              fileName
              length
              contentType
            }
            production_PolicyDocument_Version
            production_PolicyDocument_createdOn
            production_PolicyDocument_DocumentName
            production_PolicyDocument_DocumentType
            production_PolicyDocument_UploadedManually
          }
          additionalDocuments: getNewPolicyDocuments(
            policyId: $id
            uploadedManually: true
          ) {
            production_PolicyDocument_Id
            production_PolicyDocument_PolicyID {
              id
            }
            production_PolicyDocument_Document {
              id
              location
              path
              fileName
              length
              contentType
            }
            production_PolicyDocument_Version
            production_PolicyDocument_createdOn
            production_PolicyDocument_DocumentName
            production_PolicyDocument_DocumentType
            production_PolicyDocument_UploadedManually
          }
        }
      }
    }
  `;
}

export function getPolicyBills() {
  return gql`
    query getPolicyBills(
      $policyId: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          GetPolicyBills(
            PolicyID: $policyId
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            paging {
              pageSize
              totalCount
              pageNumber
            }
            items {
              accounting_Bills_Id
              accounting_Bills_BillNumber
              accounting_Bills_DueDate
              accounting_Bills_TotalPremium
              accounting_Bills_OutstandingBalance
              accounting_Bills_TotalCommission
              payableBills_TaxOnCommission
              payableBills_OutstandingBalance
            }
          }
        }
      }
    }
  `;
}

export function businessPartnerQuery() {
  return gql`
    query businessPartnerQuery(
      $billID: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          GetBusinessPartnersCommissions(
            BillID: $billID
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            paging {
              pageSize
              totalCount
              pageNumber
            }
            items {
              businessPartner_FullName
              policyBusinessPartner_CommissionPercentage
              accounting_Bills_TotalCommission
            }
          }
        }
      }
    }
  `;
}
