import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
	calculateMaxFrequencyQuery,
	calculateMaxPaymentsQuery,
	getPolicyDetailsQuery,
} from "./queries";
import Loader from "../../components/Loader";
import { makeStyles } from "tss-react/mui";
import NewChipsInput from "../../components/enhanced-form/NewChipsInput";
import { enumListAsRecordObject } from "../../utils/graph-utils";
import EnhancedInput from "../../components/enhanced-form/EnhancedInput";
import { IPaymentTermsPopupFormState, IPaymentTermsPopupProps } from ".";
import { arrayToList, getPaymentTermsPopupFormState, numToList } from "./utils";
import GenericPopUp from "../../components/custom/GenericPopUp";
import EnhancedDatePicker from "../../components/enhanced-form/EnhancedDatePicker";
import { AddMonths } from "../../utils/date-utils";
import dayjs from "dayjs";
import EnhancedButton from "../../components/EnhancedButton";
import { formatDate } from "../../utils/formatting-utils";
import {
	DISPLAY_DATE_FORMAT,
	SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { cloneDeep } from "lodash";
import { IAbstractRecord } from "../../models";

const useStyles = makeStyles()(() => ({
	dialogPaper: {
		height: "80%",
		width: "80%",
		maxWidth: "1539px",
	},
	fieldRow: {
		display: "grid",
		gridTemplateColumns: `repeat(3, 30%)`,
		gap: "3%",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const PaymentTermsPopup: React.FunctionComponent<IPaymentTermsPopupProps> = ({
	policyId,
	open,
	onClose,
}) => {
	const { classes } = useStyles();
	const [booted, setBooted] = useState<boolean>(false);
	const [pageState, setPageState] = useState<IPaymentTermsPopupFormState>(
		getPaymentTermsPopupFormState()
	);
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
		useState<boolean>(true);

	const [lovs, setLovs] = useState<{
		paymentTypes: Record<string, string>;
		frequencies: Record<string, string>;
		numOfPayments: Record<string, number>;
	}>({
		paymentTypes: {},
		frequencies: {},
		numOfPayments: {},
	});

	const maxEffectiveFrom = useMemo(() => {
		return dayjs(pageState.values.policyExpiryDate)
			.subtract(1, "month")
			.toDate();
	}, [pageState.values.policyExpiryDate]);

	const amountToBeAllocated = useMemo(() => {
		const totalAmount = pageState.values.bills.reduce(
			(total: number, bill: IAbstractRecord) => {
				return total + bill.accounting_Bills_OutstandingBalance;
			},
			0
		);

		return totalAmount;
	}, [pageState.values.bills]);

	const [getPolicyDetails] = useLazyQuery(getPolicyDetailsQuery());
	const [calculateMaxFrequency] = useMutation(calculateMaxFrequencyQuery());
	const [calculateMaxPayments] = useMutation(calculateMaxPaymentsQuery());

	const loadData = async () => {
		try {
			const res = await getPolicyDetails({
				variables: {
					policyId: policyId,
				},
			});

			const paymentTypes = enumListAsRecordObject(
				res?.data?.Production_PaymentDivisionTypeList?.enumValues
			);
			setLovs((prev) => ({
				...prev,
				paymentTypes,
			}));

			const policyDetails =
				res?.data?.Production?.entities?.policy?.views?.Production_all
					?.properties;

			const policyExpiryDate =
				policyDetails?.PolicyExpiryDate ||
				AddMonths(new Date(policyDetails?.PolicyIssueDate), 6);

			const effectiveFrom = dayjs(new Date()).isBefore(
				dayjs(policyDetails.PolicyIssueDate)
			)
				? dayjs(policyDetails.PolicyIssueDate).format(DISPLAY_DATE_FORMAT)
				: dayjs(new Date()).format(DISPLAY_DATE_FORMAT);

			setPageState((prev) => ({
				...prev,
				values: {
					...prev.values,
					policyNumber: policyDetails?.PolicyNumber,
					policyIssuanceDate: policyDetails?.PolicyIssueDate,
					policyExpiryDate: policyExpiryDate,
					paymentType: "EQUIVALENT",
					effectiveFrom: effectiveFrom,
					bills: res.data.Accounting.queries.GetPolicyReceivableBills,
				},
			}));

			loadFrequencies(
				policyDetails?.PolicyIssueDate,
				policyExpiryDate,
				dayjs(new Date()).toString()
			);

			setBooted(true);
		} catch (error) {
			console.error(error);
		}
	};

	const loadFrequencies = async (
		policyIssuanceDate: string,
		policyExpiryDate: string,
		effectiveFromDate: string
	) => {
		try {
			const res = await calculateMaxFrequency({
				variables: {
					policyIssuanceDate: formatDate(
						policyIssuanceDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					policyExpiryDate: formatDate(
						policyExpiryDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					effectiveFromDate: formatDate(
						effectiveFromDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
				},
			});

			const maxFrequencies = arrayToList(
				res?.data?.accounting?.actions?.calculateMaxFrequency?.Values
			);

			setLovs((prev) => ({
				...prev,
				frequencies: maxFrequencies,
			}));
		} catch (error) {
			console.error(error);
		}
	};

	const loadPayments = async (selectedFrequency: string) => {
		try {
			const res = await calculateMaxPayments({
				variables: {
					policyIssuanceDate: formatDate(
						pageState.values.policyIssuanceDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					policyExpiryDate: formatDate(
						pageState.values.policyExpiryDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					effectiveFromDate: formatDate(
						pageState.values.effectiveFrom,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					selectedFrequency: selectedFrequency || pageState.values.frequency,
				},
			});

			const maxPayments: number =
				res?.data?.accounting?.actions?.calculateMaxPayments?.Value;

			setLovs((prev) => ({
				...prev,
				numOfPayments: numToList(maxPayments),
			}));
		} catch (error) {
			console.error(error);
		}
	};

	const initialize = async () => {
		await loadData();
	};

	useEffect(() => {
		initialize();
	}, []);

	const handleApply = async () => {
		try {
			setIsSubmitButtonDisabled(false);
		} catch (error) {}
	};

	const handleSubmit = () => {
		setIsSubmitButtonDisabled(true);
	};

	const handleChange = async (inputName: string, value: any) => {
		setIsSubmitButtonDisabled(true);
		const newValues = cloneDeep(pageState.values);
		const newTouched = cloneDeep(pageState.touched);

		switch (inputName) {
			case "effectiveFrom":
				if (pageState.values.paymentType === "EQUIVALENT") {
					newValues.frequency = null;
					newValues.numOfPayments = null;
					await loadFrequencies(
						pageState.values.policyIssuanceDate,
						pageState.values.policyExpiryDate,
						value
					);
				}
				break;
			case "frequency":
				newValues.numOfPayments = null;
				await loadPayments(value);
				break;
			case "paymentType":
				newValues.frequency = null;
				newValues.numOfPayments = null;
				break;
			default:
				break;
		}

		setPageState((prev) => ({
			...prev,
			values: {
				...newValues,
				[inputName]: value,
			},
			touched: {
				...newTouched,
				[inputName]: true,
			},
		}));
	};

	const renderContent = () => {
		return (
			<>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleApply();
					}}
				>
					<div className={classes.fieldRow}>
						<EnhancedInput
							title="Policy Issuance Date"
							name="PolicyIssuanceDate"
							type="text"
							value={formatDate(pageState.values.policyIssuanceDate)}
							onBlur={() => {}}
							onChange={() => {}}
							disabled
						/>
						<EnhancedInput
							title="Policy Expiry Date"
							name="PolicyExpiryDate"
							type="text"
							value={formatDate(pageState.values.policyExpiryDate)}
							onBlur={() => {}}
							onChange={() => {}}
							disabled
						/>
						<NewChipsInput
							title="Payment Type"
							name="paymentType"
							items={lovs.paymentTypes}
							onChange={(v) => {
								handleChange("paymentType", v);
							}}
							value={pageState.values.paymentType}
						/>
					</div>
					<div className={classes.fieldRow}>
						<EnhancedDatePicker
							title="Effective From"
							name="effectiveFrom"
							value={formatDate(pageState.values.effectiveFrom)}
							onDateChange={(v) => {
								handleChange("effectiveFrom", v);
							}}
							onBlur={() => {}}
							minDate={dayjs(pageState.values.policyIssuanceDate).toDate()}
							maxDate={maxEffectiveFrom}
						/>
						{pageState.values.paymentType === "EQUIVALENT" && (
							<NewChipsInput
								title="Frequency"
								name="frequency"
								items={lovs.frequencies}
								onChange={(v) => {
									handleChange("frequency", v);
								}}
								value={pageState.values.frequency}
							/>
						)}
						<NewChipsInput
							title="No of Payments"
							name="numOfPayments"
							items={lovs.numOfPayments}
							onChange={(v) => {
								handleChange("numOfPayments", v);
							}}
							value={pageState.values.numOfPayments}
						/>
						{pageState.values.paymentType === "CUSTOM" && (
							<EnhancedInput
								title="Amount to be Allocated"
								name="amountToBeAllocated"
								type="number"
								value={amountToBeAllocated}
								onBlur={() => {}}
								onChange={() => {}}
								disabled
							/>
						)}
					</div>
					<div>
						<EnhancedButton type="submit" variant="contained">
							Apply
						</EnhancedButton>
					</div>
				</form>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					{/* <EnhancedTable 
						name="paymentTermsSchedule"
						data={tableData}
						headers={headers}
					>

					</EnhancedTable> */}
					<EnhancedButton
						type="submit"
						disabled={isSubmitButtonDisabled}
						variant="contained"
					>
						Submit
					</EnhancedButton>
				</form>
			</>
		);
	};

	return (
		<GenericPopUp
			open={open}
			onClose={onClose}
			title={
				!booted
					? "Change Payment Terms"
					: `Change Payment Terms (${pageState.values.policyNumber})`
			}
			content={!booted ? <Loader /> : renderContent()}
		/>
	);
};

export default PaymentTermsPopup;
