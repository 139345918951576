import _ from "lodash";
import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import WidgetPaper from "../../../components/common/WidgetPaper";
import WidgetSection from "../../../components/common/WidgetSection";
import EnhancedChipInput from "../../../components/enhanced-form/EnhancedChipInput";
import EnhancedInput from "../../../components/enhanced-form/EnhancedInput";
import EnhancedFormattedNumberInput from "../../../components/enhanced-form/EnhancedFormattedNumberInput";
import EnhancedDatePicker from "../../../components/enhanced-form/EnhancedDatePicker";
import {
  IProposalMarineDetails,
  IProposalPageFormState,
} from "../../../modules/production/proposal/page/generic/form";
import { validateMarineDetailsField } from "../../../modules/production/proposal/page/generic/validation";
import { IProposalDetailsSummary } from "../../../modules/production/proposal/page/generic/index2";
import EnhancedPercentageInput from "../../../components/enhanced-form/EnhancedPercentageInput";

interface IProposalMarineDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
  },
  section: {
    padding: "23px 0 25px",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(4, 23%)`,
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  fullFieldRow : {
    gridColumn: "1 / -1", // Span across all columns
    width: "100%",        // Ensure it takes the full width available
  },
  label: {
    color: "#000000",
    marginBottom: 0,
    paddingBottom: 0,
    fontSize: "12px !important",
    fontFamily: "SourceSansPro-SemiBold !important",
  },
}));

const ProposalMarineDetailsWidget: React.FC<
  IProposalMarineDetailsWidgetProps
> = ({ pageState, onPageStateUpdate, disabledForm, data, lovs, status }) => {
  const { classes } = useStyles();

  const values = pageState.values.marineDetails;
  const errors = pageState.errors.marineDetails;
  const touched = pageState.touched.marineDetails;

  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.marineDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IProposalMarineDetails,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    (newPageState.values.marineDetails as any)[fieldName] = value;
    (newPageState.errors.marineDetails as any)[fieldName] =
      validateMarineDetailsField(fieldName, value);

    if (touched) {
      newPageState.touched.marineDetails[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: "1em" }}>
      <WidgetSection
        title="Marine Details"
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="matter"
            name="matter"
            title="Matter*"
            placeholder="Matter"
            value={values.matter}
            error={touched.matter ? errors.matter : ""}
            onChange={(v) => onFieldUpdate("matter", v, true)}
            disabled={
              ["CLOSED_WON, CLOSED_LOST"].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.matters}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
          />

          <EnhancedInput
            key="description"
            name="description"
            title="Description*"
            placeholder="Description"
            value={values.description}
            error={touched.description ? errors.description : ""}
            onBlur={() => onFieldBlur("description")}
            onChange={(v) => onFieldUpdate("description", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="vesselName"
            name="vesselName"
            title="Vessel Name*"
            placeholder="Vessel Name"
            value={values.vesselName}
            error={touched.vesselName ? errors.vesselName : ""}
            onBlur={() => onFieldBlur("vesselName")}
            onChange={(v) => onFieldUpdate("vesselName", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedFormattedNumberInput
            key="vesselAge"
            name="vesselAge"
            title="Vessel Age"
            placeholder="Vessel Age"
            value={values.vesselAge}
            error={touched.vesselAge ? errors.vesselAge : ""}
            onBlur={() => onFieldBlur("vesselAge")}
            onChange={(v) => onFieldUpdate("vesselAge", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="from"
            name="from"
            title="From*"
            placeholder="From"
            value={values.from}
            error={touched.from ? errors.from : ""}
            onBlur={() => onFieldBlur("from")}
            onChange={(v) => onFieldUpdate("from", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="to"
            name="to"
            title="To*"
            placeholder="To"
            value={values.to}
            error={touched.to ? errors.to : ""}
            onBlur={() => onFieldBlur("to")}
            onChange={(v) => onFieldUpdate("to", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="via"
            name="via"
            title="Via*"
            placeholder="Via"
            value={values.via}
            error={touched.via ? errors.via : ""}
            onBlur={() => onFieldBlur("via")}
            onChange={(v) => onFieldUpdate("via", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="survey"
            name="survey"
            title="Survey*"
            placeholder="Survey"
            value={values.survey}
            error={touched.survey ? errors.survey : ""}
            onBlur={() => onFieldBlur("survey")}
            onChange={(v) => onFieldUpdate("survey", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="cargoContract"
            name="cargoContract"
            title="Cargo Contract*"
            placeholder="Cargo Contract"
            value={values.cargoContract}
            error={touched.cargoContract ? errors.cargoContract : ""}
            onBlur={() => onFieldBlur("cargoContract")}
            onChange={(v) => onFieldUpdate("cargoContract", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedFormattedNumberInput
            key="sumPerShipment"
            name="sumPerShipment"
            title="Sum Per Shipment"
            placeholder="Sum Per Shipment"
            value={values.sumPerShipment}
            error={touched.sumPerShipment ? errors.sumPerShipment : ""}
            onBlur={() => onFieldBlur("sumPerShipment")}
            onChange={(v) => onFieldUpdate("sumPerShipment", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
          />

          <EnhancedInput
            key="lcNumber"
            name="lcNumber"
            title="LC Number"
            placeholder="LC Number"
            value={values.lcNumber}
            error={touched.lcNumber ? errors.lcNumber : ""}
            onBlur={() => onFieldBlur("lcNumber")}
            onChange={(v) => onFieldUpdate("lcNumber", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedDatePicker
            key="lcIssueDate"
            name="lcIssueDate"
            title="LC Issue Date"
            placeholder="LC Issue Date"
            value={values.lcIssueDate}
            error={touched.lcIssueDate ? errors.lcIssueDate : ""}
            onDateChange={(v) => onFieldUpdate("lcIssueDate", v, true)}
            onBlur={() => {}}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
          />

          <EnhancedInput
            key="bank"
            name="bank"
            title="Bank"
            placeholder="Bank"
            value={values.bank}
            error={touched.bank ? errors.bank : ""}
            onBlur={() => onFieldBlur("bank")}
            onChange={(v) => onFieldUpdate("bank", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedInput
            key="claimsPayableBy"
            name="claimsPayableBy"
            title="Claims Payable By*"
            placeholder="Claims Payable By"
            value={values.claimsPayableBy}
            error={touched.claimsPayableBy ? errors.claimsPayableBy : ""}
            onBlur={() => onFieldBlur("claimsPayableBy")}
            onChange={(v) => onFieldUpdate("claimsPayableBy", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
          />

          <EnhancedPercentageInput
            key="loading"
            name="loading"
            title="Loading*"
            placeholder="Loading"
            value={values.loading}
            error={touched.loading ? errors.loading : ""}
            onBlur={() => onFieldBlur("loading")}
            onChange={(v) => onFieldUpdate("loading", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
          />

          <EnhancedInput
            key="remarks"
            name="remarks"
            title="Remarks"
            placeholder="Remarks"
            value={values.remarks}
            error={touched.remarks ? errors.remarks : ""}
            onBlur={() => onFieldBlur("remarks")}
            onChange={(v) => onFieldUpdate("remarks", v.target.value)}
            disabled={
              disabledForm ||
              status === "CLOSED_WON" ||
              status === "CLOSED_LOST"
            }
            material={true}
            type="text"
            className={classes.fullFieldRow}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalMarineDetailsWidget;
