import { isEmpty } from "lodash";
import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";

export const initialValues = {
  planCurrency: "",
  policyCover: "",
  nbOfAdherent: "",
  class: "",
  ageFrom: "",
  ageTo: "",
  coNssf: "",
  netPremium: "",
  tpaFeesPercentage: "",
  minTPAFees: "",
  maxTPAFees: "",
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {},
  planCurrency: string
): Record<string, DynamicFormInputType> => {
  return {
    planCurrency: {
      name: "planCurrency",
      title: "Plan Currency",
      type: FormInputTypes.chips,
      placeholder: "Plan Currency",
      value: values.planCurrency || planCurrency,
      multiple: false,
      disabled: true,
      required: true,
      selectOptions: lovs?.currencies || {},
    },
    policyCover: {
      name: "policyCover",
      title: "Policy Cover",
      type: FormInputTypes.chips,
      placeholder: "Type to search for Policy Cover",
      value: !isEmpty(values.policyCover) ? values.policyCover : "",
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.policyCover || {},
    },
    nbOfAdherent: {
      name: "nbOfAdherent",
      title: "Nb Of Adherent",
      placeholder: "Nb Of Adherent",
      type: FormInputTypes.number,
      value: values.nbOfAdherent,
      required: true,
      minNumber: 0,
    },
    class: {
      name: "class",
      title: "Class",
      type: FormInputTypes.chips,
      placeholder: "Class",
      value: values.class,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs.medicalPlanClasses,
    },
    ageFrom: {
      name: "ageFrom",
      title: "Age From",
      placeholder: "Age From",
      type: FormInputTypes.number,
      value: values.ageFrom,
      required: true,
      minNumber: 0,
      maxNumber: 50,
      customValidator: (values) => {
        const { ageFrom, ageTo } = values;
        const fromAge = Number(ageFrom);
        const toAge = Number(ageTo);
        if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
          return "Age From must be less than Age To";
        }
        return "";
      },
    },
    ageTo: {
      name: "ageTo",
      title: "Age To",
      placeholder: "Age To",
      type: FormInputTypes.number,
      value: values.ageTo,
      required: true,
      maxNumber: 50,
      customValidator: (values) => {
        const { ageFrom, ageTo } = values;
        const fromAge = Number(ageFrom);
        const toAge = Number(ageTo);
        if (fromAge != 0 && toAge != 0 && fromAge > toAge) {
          return "Age To must be greater than Age From";
        }
        return "";
      },
    },
    coNssf: {
      name: "coNssf",
      title: "Co-Nssf",
      type: FormInputTypes.chips,
      placeholder: "Co-Nssf",
      value: values.coNssf,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lovs?.coNSSFOptions || {},
    },
    netPremium: {
      name: "netPremium",
      title: "Net Premium",
      placeholder: "Net Premium",
      type: FormInputTypes.formattedNumber,
      value: values.netPremium,
      required: true,
      minValue: 0,
    },
    tpaFeesPercentage: {
      name: "tpaFeesPercentage",
      title: "TPA Fees Percentage",
      placeholder: "TPA Fees Percentage",
      type: FormInputTypes.percentage,
      value: values.tpaFeesPercentage,
      required: true,
    },
    minTPAFees: {
      name: "minTPAFees",
      title: "Min TPA Fees",
      placeholder: "Min TPA Fees",
      type: FormInputTypes.formattedNumber,
      value: values.minTPAFees,
      required: true,
      minValue: 0,
      customValidator: (values) => {
        const { minTPAFees, maxTPAFees } = values;
        const tPAFeesMin = Number(minTPAFees);
        const tPAFeesMax = Number(maxTPAFees);
        if (tPAFeesMin != 0 && tPAFeesMax != 0 && tPAFeesMin > tPAFeesMax) {
          return "Min TPA Fees must be less than Max TPA Fees";
        }
        return "";
      },
    },
    maxTPAFees: {
      name: "maxTPAFees",
      title: "Max TPA Fees",
      placeholder: "Max TPA Fees",
      type: FormInputTypes.formattedNumber,
      value: values.maxTPAFees,
      required: true,
      customValidator: (values) => {
        const { minTPAFees, maxTPAFees } = values;
        const tPAFeesMin = Number(minTPAFees);
        const tPAFeesMax = Number(maxTPAFees);
        if (tPAFeesMin != 0 && tPAFeesMax != 0 && tPAFeesMin > tPAFeesMax) {
          return "Max TPA Fees must be greater than Min TPA Fees";
        }
        return "";
      },
    },
  };
};
