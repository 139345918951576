import dayjs from "dayjs";
import { IDocumentsDetails, IProductionDetailsSummary } from ".";
import { IDocument } from "../../../../models/file";
import { getDefaultDate } from "../../../../utils/date-utils";
import { capitalizeFirstLetterLowerOthers } from "../../../../utils/formatting-utils";
import { IPersonWidgetInfo } from "./custom-components";
import { isEmpty } from "../../../../utils/validationUtils";

export function GetProductionPolicyData(
  response: any
): IProductionDetailsSummary | undefined {
  const policy = response?.Production?.entities?.policy;
  const policyCovers = response?.Production.queries.getPolicyPlanCovers;
  const bankers = policy?.views?.Production_all?.properties?.BBBDetailsID;
  const person = response?.Production.queries.GetPolicyPerson;
  const insuredPerson = person?.find(
    (person: any) =>
      person?.production_PolicyPerson_PersonID?.views?.SalesforceManagement_all
        ?.properties?.PersonType === "Insured"
  );
  const marine = response?.Production.queries.getPolicyMarineDetails?.[0];

  const pricingOptionInfo = policy?.queries?.production?.pricingOption?.[0];
  const motorDetails =
    policy?.views?.Production_all?.properties?.MotorDetailsID?.views
      ?.Production_all?.properties;
  const vehicleDetails =
    motorDetails?.VehicleID?.views?.Production_all?.properties;
  const regeneratedDocuments =
    response?.Production?.queries?.regeneratedDocuments;
  const additionalDocuments =
    response?.Production?.queries?.additionalDocuments;
  const expatDetails =
    policy?.views?.Production_all?.properties?.ExpatDetailsID?.views
      ?.Production_all?.properties;
  return {
    Id: policy?.id || "",
    //   ClauseText: policy?.views?.Production_all?.properties?.ClauseText || "",
    PolicyNumber: policy?.views?.Production_all?.properties?.PolicyNumber || "",
    PolicyName: policy?.views?.Production_all?.properties?.PolicyName || "",
    InternalCode: policy?.views?.Production_all?.properties?.InternalCode || 0,
    PolicyEffectiveDate:
      policy?.views?.Production_all?.properties?.PolicyEffectiveDate || "",
    PolicyExpiryDate:
      policy?.views?.Production_all?.properties?.PolicyExpiryDate || "",
    modifiedOn: policy?.views?.Production_all?.properties?.modifiedOn || "",
    lastModifiedBy:
      policy?.queries.production.relatedUsers?.[0]?.modifiedBy_firstName +
      " " +
      policy?.queries.production.relatedUsers?.[0]?.modifiedBy_lastName,
    createdBy:
      policy?.queries?.production?.relatedUsers?.[0]?.createdBy_firstName +
      " " +
      policy?.queries.production.relatedUsers?.[0]?.createdBy_lastName,
    createdOn: policy?.views?.Production_all?.properties?.createdOn || "",
    PolicyCurrency:
      pricingOptionInfo?.pricingOption_PricingOptionCurrency || undefined,
    Status: policy?.views?.Production_all?.properties?.Status || "",
    StatusReason: policy?.views?.Production_all?.properties?.StatusReason || "",
    LineId: {
      Id: policy?.views?.Production_all?.properties?.LineID?.id,
      Name:
        policy?.views?.Production_all?.properties?.LineID?.views
          ?.PlanConfigManagement_all?.properties?.Name || "",
      ExternalCode:
        policy?.views?.Production_all?.properties?.LineID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || "",
    },
    SubLineId: {
      Name:
        policy?.views?.Production_all?.properties?.SublineID?.views
          ?.PlanConfigManagement_all?.properties?.Name || "",
      ExternalCode:
        policy?.views?.Production_all?.properties?.SublineID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || "",
    },
    BusinessUserID: {
      Code:
        policy?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.Code || "",
      AllowPlanCoverEdits:
        policy?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.AllowPlanCoverEdits || false,

      FullName:
        policy?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.PersonID?.views
          ?.SalesforceManagement_all?.properties?.FullName || "",
      EditableLineChargesIDs:
        policy?.views?.Production_all?.properties?.BusinessUserID?.views?.SalesforceManagement_all?.properties?.EditableLineChargesIDs?.map(
          (a: any) => a.views.PlanConfigManagement_all.properties.Id
        ) || [],
      AllowClauseEdits:
        policy?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.AllowClauseEdits,
    },
    PrimaryBPID: {
      Id: policy?.views?.Production_all?.properties?.PrimaryBPID?.id,
      Code:
        policy?.views?.Production_all?.properties?.PrimaryBPID?.views
          ?.SalesforceManagement_all?.properties?.Code || "",
      FullName:
        policy?.views?.Production_all?.properties?.PrimaryBPID?.views
          ?.SalesforceManagement_all?.properties?.PersonID?.views
          ?.SalesforceManagement_all?.properties?.FullName || "",
      RelatedCompany:
        policy?.views?.Production_all?.properties?.PrimaryBPID?.views
          ?.SalesforceManagement_all?.properties?.RelatedCompany?.Title,
    },
    PlanID: {
      Id: policy?.views?.Production_all?.properties?.PlanID?.id || "",
      Name:
        policy?.views?.Production_all?.properties?.PlanID?.views
          ?.PlanConfigManagement_all?.properties?.Name || "",
      ExternalCode:
        policy?.views?.Production_all?.properties?.PlanID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || "",
      ClauseEditable:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ClauseEditable,
      MaxNbInsured:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.MaxNbInsured,
      AcalCategory:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.AcalCategory,
      SOSService:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.SOSService,
      Scratching:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.Scratching,
      ReplacementCarValue:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ReplacementCarValue,
      ReplacementCarDays:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ReplacementCarDays,
      ReplacementCarApplied:
        policy?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ReplacementCarApplied,
    },
    PricingOption: {
      PolicyCost: pricingOptionInfo?.pricingOption_PolicyCost,
      ChargesPercentage: pricingOptionInfo?.pricingOption_ChargesPercentage,
      TPAFeesType: pricingOptionInfo?.pricingOption_TPAFeesType,
      TPAFeesAmount: pricingOptionInfo?.pricingOption_TPAFeesAmount,
      TPAFeesPercentage: pricingOptionInfo?.pricingOption_TPAFeesPercentage,
      TPAFeesApplicableOn: pricingOptionInfo?.pricingOption_TPAFeesApplicableOn,
      BasicPremium: pricingOptionInfo?.pricingOption_BasicPremium,
      NetPremium: pricingOptionInfo?.pricingOption_NetPremium,
      PlanToPolicyRate: pricingOptionInfo?.pricingOption_PlanToPolicyRate,
      GrossPremium: pricingOptionInfo?.pricingOption_GrossPremium,
      TaxOnCommissionAmount:
        pricingOptionInfo?.pricingOption_TaxOnCommissionAmount,
      ReinsuranceTaxAmount:
        pricingOptionInfo?.pricingOption_ReinsuranceTaxAmount,
      MunicipalityTaxAmount:
        pricingOptionInfo?.pricingOption_MunicipalityTaxAmount,
      TotalPremium: pricingOptionInfo?.pricingOption_TotalPremium,
      ChargesAmount: pricingOptionInfo?.pricingOption_ChargesAmount,
      TPATotalFeesAmount: pricingOptionInfo?.pricingOption_TPAFeesTotalAmount,
      FixedStampAmount: pricingOptionInfo?.pricingOption_FixedStampAmount,
      Status: pricingOptionInfo?.pricingOption_Status,
      ProportionalStampAmount:
        pricingOptionInfo?.pricingOption_ProportionalStampAmount,
      CreatedOn: pricingOptionInfo?.pricingOption_createdOn,
      SumInsured: pricingOptionInfo?.pricingOption_SumInsured,
      PricingOptionCurrencySymbol:
        pricingOptionInfo?.pricingOption_PricingOptionCurrency?.Symbol,
    },
    NameOnLicenseAr: motorDetails?.NameOnLicenseAr,
    NameOnLicenseEn: insuredPerson || "",
    PolicyMotor: {
      Vignette: motorDetails?.Vignette,
      VignetteCode: motorDetails?.VignetteCode,
      AgencyRepairYear: motorDetails?.AgencyRepairYear,
    },
    Vehicle: {
      BodyType: vehicleDetails?.BodyType?.Title,
      Brand: vehicleDetails?.Make?.Title,
      CarValue: vehicleDetails?.CarValue,
      Chassis: vehicleDetails?.Chassis,
      EngineSize: vehicleDetails?.EngineSize,
      EngineType: vehicleDetails?.EngineType?.Id,
      HorsePower: vehicleDetails?.HorsePower,
      Model: vehicleDetails?.Model?.Title,
      Motor: vehicleDetails?.Engine,
      PlateCode: vehicleDetails?.PlateCode?.Title,
      PlateNumber: vehicleDetails?.PlateNumber,
      Seats: vehicleDetails?.SeatingCapacity,
      UsageType: vehicleDetails?.UsageType.Id,
      Weight: vehicleDetails?.Weight,
      YearOfMake: vehicleDetails?.YearOfMake,
    },
    Persons: convertToPolicyPersons(response),
    Covers: policyCovers.map((cover: any) => ({
      isMain: cover.production_PolicyPlanCover_IsMain,
      isMandatory: cover.production_PolicyPlanCover_IsMandatory,
      isEditable: cover.production_PolicyPlanCover_IsEditable,
      policyCover: cover.policyCover_Name,
      coverPremiumType: cover.production_PolicyPlanCover_CoverPremiumType,
      coverPremiumPercentage:
        cover.production_PolicyPlanCover_CoverPremiumPercentage || 0,
      coverPremiumValue:
        cover.production_PolicyPlanCover_CoverPremiumValue || 0,
      currency:
        cover.production_PolicyPlanCover_ProposalID.views.Production_all
          .properties.PolicyCurrency.Code,
      coverSumInsured: cover.production_PolicyPlanCover_CoverSumInsured || 0,
      sumInsuredIsAdditive:
        cover.production_PolicyPlanCover_SumInsuredIsAdditive,
      excessOnClaimType: cover.production_PolicyPlanCover_ExcessOnClaimType,
      excessOnClaimAmount:
        cover.production_PolicyPlanCover_ExcessOnClaimAmount || 0,
      excessOnClaimPercentage:
        cover.production_PolicyPlanCover_ExcessOnClaimPercentage || 0,
      excessOnClaimDays:
        cover.production_PolicyPlanCover_ExcessOnClaimDays || 0,
      name: cover.policyCover_Name,
      clauseID: cover.production_PolicyPlanCover_ClauseID || "",
      coverEffectiveFrom: cover.production_PolicyPlanCover_CoverEffectiveFrom,
      coverEffectiveTo: cover.production_PolicyPlanCover_CoverEffectiveTo,
    })),
    Bankers: (bankers || []).map((banker: any) => ({
      branchName: banker?.views?.Production_all?.properties?.BranchName || "",
      isMain: isEmpty(banker?.views?.Production_all?.properties?.IsMain)
        ? ""
        : banker?.views?.Production_all?.properties?.IsMain
        ? "Yes"
        : "No",
      isAgency: isEmpty(banker?.views?.Production_all?.properties?.IsAgency)
        ? ""
        : banker?.views?.Production_all?.properties?.IsAgency
        ? "Yes"
        : "No",
      numberOfEmployees: Number(
        banker?.views?.Production_all?.properties?.NumberOfEmployees || 0
      ),
      underwritingYear: Number(
        banker?.views?.Production_all?.properties?.UnderwritingYear || 0
      ),
      infidelity: banker?.views?.Production_all?.properties?.Infidelity || 0,
      burglary: banker?.views?.Production_all?.properties?.Burglary || 0,
      holdUp: banker?.views?.Production_all?.properties?.HoldUp || 0,
      inSafe: banker?.views?.Production_all?.properties?.InSafe || 0,
      inTransit: banker?.views?.Production_all?.properties?.InTransit || 0,
      forgedChecks:
        banker?.views?.Production_all?.properties?.ForgedCheckes || 0,
      counterfeitCurrencies:
        banker?.views?.Production_all?.properties?.CounterFeitCurrencies || 0,
      clause6: banker?.views?.Production_all?.properties?.Clause6 || 0,
      falsification:
        banker?.views?.Production_all?.properties?.Falsification || 0,
    })),

    CommissionDetails: (
      response as any
    ).Production.queries.getPolicyBusienssPartnerDetails.map((detail: any) => ({
      BusinessUserFullName:
        detail.production_PolicyBusinessPartner_BusinessPartnerID.views
          .SalesforceManagement_all.properties.PersonID.views
          .SalesforceManagement_all.properties.FullName,
      PlanCommissionType:
        detail.production_PolicyBusinessPartner_PlanCommissionType,
      PlanCommissionAmount:
        detail.production_PolicyBusinessPartner_PlanCommissionAmount,
      PlanCommissionPercentage:
        detail.production_PolicyBusinessPartner_PlanCommissionRate,
      ApplicableOn:
        detail?.production_PolicyBusinessPartner_ApplicableOn?.Status,
      PolicyCommisisonAmount:
        detail.production_PolicyBusinessPartner_PolicyCommissionAmount,
      Id: detail.production_PolicyBusinessPartner_Id,
      Share: detail.production_PolicyBusinessPartner_CommissionPercentage,
      IsPrimary: detail.production_PolicyBusinessPartner_IsPrimary,
    })),
    regeneratedDocuments: getPolicyDocuments(regeneratedDocuments),
    additionalDocuments: getPolicyDocuments(additionalDocuments),
    ExpatDetails: {
      Continuity: expatDetails?.Continuity,
      ContinuityNumber: expatDetails?.ContinuityNumber,
      Exclusion: expatDetails?.Exclusion,
      MedicalClass: expatDetails?.MedicalClass,
      Remarks: expatDetails?.Remarks,
    },
    Marine: {
      matter: marine?.production_PolicyMarine_Matter?.Title || "",
      description: marine?.production_PolicyMarine_Description || "",
      vesselName: marine?.production_PolicyMarine_VesselName || "",
      vesselAge: marine?.production_PolicyMarine_VesselAge || "",
      from: marine?.production_PolicyMarine_From || "",
      to: marine?.production_PolicyMarine_To || "",
      via: marine?.production_PolicyMarine_Via || "",
      survey: marine?.production_PolicyMarine_Survey || "",
      cargoContract: marine?.production_PolicyMarine_CargoContract || "",
      lcNumber: marine?.production_PolicyMarine_LCNumber || "",
      lcIssueDate: marine?.production_PolicyMarine_LCIssueDate || "",
      bank: marine?.production_PolicyMarine_Bank || "",
      claimsPayableBy: marine?.production_PolicyMarine_ClaimsPayableBy || "",
      loading: marine?.production_PolicyMarine_Loading || "",
      remarks: marine?.production_PolicyMarine_Remarks || "",
      sumPerShipment: marine?.production_PolicyMarine_Remarks || "",
    },
  };
}

export function GetDocumentsData(response: any): IDocumentsDetails | undefined {
  const regeneratedDocuments =
    response?.Production?.queries?.regeneratedDocuments;
  const additionalDocuments =
    response?.Production?.queries?.additionalDocuments;
  return {
    regeneratedDocuments: getPolicyDocuments(regeneratedDocuments),
    additionalDocuments: getPolicyDocuments(additionalDocuments),
  };
}

function getPolicyDocuments(policyDocuments: any[]) {
  const policDoc: IDocument[] = [];
  if (policyDocuments && policyDocuments.length > 0) {
    policyDocuments.map((document) => {
      policDoc.push({
        id: document?.production_PolicyDocument_Id,
        code: document?.production_PolicyDocument_DocumentName,
        version: document?.production_PolicyDocument_Version,
        createdOn:
          getDefaultDate(
            dayjs(
              new Date(document?.production_PolicyDocument_createdOn)
            )?.toDate()
          )?.toDateString() || "12-12-2012",
        file: document?.production_PolicyDocument_Document,
      });
    });
  }
  return policDoc;
}

export function convertToPolicyPersons(data: any) {
  const persons = data?.Production?.queries?.GetPolicyPerson || [];

  let payer: IPersonWidgetInfo = {
    name: "",
    dateOfBirth: "",
    phoneType: "",
    phoneNumber: "",
    nationalityOne: "",
    isTaxable: "",
    occupationClass: "",
    profession: "",
    address: "",
  };
  let owner: IPersonWidgetInfo = {
    name: "",
    dateOfBirth: "",
    phoneType: "",
    phoneNumber: "",
    nationalityOne: "",
    isTaxable: "",
    occupationClass: "",
    profession: "",
    address: "",
  };
  const insured: IPersonWidgetInfo[] = [];
  const beneficiaries: IPersonWidgetInfo[] = [];

  persons.forEach((person: any) => {
    const building = person.production_PolicyPerson_Address.Building;
    const street = person.production_PolicyPerson_Address.Street;
    const city = person.production_PolicyPerson_Address.City;
    const country = person.production_PolicyPerson_Address.Country.Title;
    const address = `${building ? building + ", " : ""} ${
      street ? street + ", " : ""
    } ${city ? city + ", " : ""} ${country ? country : ""}`;

    const commonInfo = {
      name: `${person?.production_PolicyPerson_PersonCode} - ${person?.production_PolicyPerson_FullName}`,
      dateOfBirth: person?.production_PolicyPerson_DateOfBirth,
      phoneType: capitalizeFirstLetterLowerOthers(
        person?.production_PolicyPerson_PhoneDetails?.PhoneType || ""
      ),
      phoneNumber: person?.production_PolicyPerson_PhoneDetails?.PhoneNumber,
      nationalityOne: person?.production_PolicyPerson_NationalityOne?.Title,
      isTaxable: person?.production_PolicyPerson_IsTaxable,
      occupationClass: person?.production_PolicyPerson_OccupationClass || "",
      profession: person?.production_PolicyPerson_Profession || "",
      address: address,
    };

    switch (person?.production_PolicyPerson_Type) {
      case "PAYER":
        payer = {
          ...commonInfo,
        };
        break;
      case "INSURED":
        insured.push({
          ...commonInfo,
          order: person?.production_PolicyPerson_OrderNumber,
        });
        break;
      case "BENEFICIARY":
        beneficiaries.push({
          ...commonInfo,
          percentage: person?.production_PolicyPerson_Percentage,
        });
        break;
      case "OWNER":
        owner = {
          ...commonInfo,
        };
        break;
    }
  });

  return {
    payer,
    owner,
    insured,
    beneficiaries,
  };
}
