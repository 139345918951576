import { EnhancedDisplayType } from "../../../../../components/enhanced-form/EnhancedDisplay";
import { IDynamicDiplayView } from "../../../../../components/widgets/dynamic-display";

export interface IPersonWidgetInfo {
    name: string;
    dateOfBirth: string;
    phoneType: string;
    phoneNumber: string;
    nationalityOne: string;
    isTaxable: string;
    occupationClass: string;
    profession: string;
    address: string;
    order?: number;
    percentage?: number;
}

export const getPayerView = (data: IPersonWidgetInfo) => {
    const dynamicView: IDynamicDiplayView = {
        name: 'Payer',
        sections: [
            {
                name: 'Payer',
                title: 'Payer',
                properties: {
                    Payer: {
                        multiline: false,
                        value: data.name,
                        name: 'Payer',
                        title: 'Payer',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    DateOfBirth: {
                        multiline: false,
                        value: data.dateOfBirth,
                        name: 'DateOfBirth',
                        title: 'Date of Birth',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    PhoneType: {
                        multiline: false,
                        value: data.phoneType || "",
                        name: 'PhoneType',
                        title: 'Phone Type',
                        tooltip: false,
                    },
                    PhoneNumber: {
                        multiline: false,
                        value: data.phoneNumber || "",
                        name: 'PhoneNumber',
                        title: 'Phone Number',
                        tooltip: false,
                        type: EnhancedDisplayType.IntPhoneNumber
                    },
                    Nationality: {
                        multiline: false,
                        value: data.nationalityOne || "",
                        name: 'Nationality',
                        title: 'Nationality',
                        tooltip: false,
                    },
                    IsTaxable: {
                        multiline: false,
                        value: data.isTaxable ? "Yes" : "No",
                        name: 'IsTaxable',
                        title: 'Taxable',
                        tooltip: false,
                    },
                    Profession: {
                        multiline: false,
                        value: data.profession || "",
                        name: 'Profession',
                        title: 'Profession',
                        tooltip: false,
                    },
                    Address: {
                        multiline: false,
                        value: data.address || "",
                        name: 'Address',
                        title: 'Payer Address',
                        tooltip: false,
                    }
                },
            },
        ],
    };


    return dynamicView;

}

export const getOwnerView = (data: IPersonWidgetInfo) => {
    const dynamicView: IDynamicDiplayView = {
        name: 'Owner',
        sections: [
            {
                name: 'Owner',
                title: 'Owner',
                properties: {
                    Owner: {
                        multiline: false,
                        value: data.name,
                        name: 'Owner',
                        title: 'Owner',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    DateOfBirth: {
                        multiline: false,
                        value: data.dateOfBirth,
                        name: 'DateOfBirth',
                        title: 'Date of Birth',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    PhoneType: {
                        multiline: false,
                        value: data.phoneType || "",
                        name: 'PhoneType',
                        title: 'Phone Type',
                        tooltip: false,
                    },
                    PhoneNumber: {
                        multiline: false,
                        value: data.phoneNumber || "",
                        name: 'PhoneNumber',
                        title: 'Phone Number',
                        tooltip: false,
                        type: EnhancedDisplayType.IntPhoneNumber
                    },
                    Nationality: {
                        multiline: false,
                        value: data.nationalityOne || "",
                        name: 'Nationality',
                        title: 'Nationality',
                        tooltip: false,
                    },
                    IsTaxable: {
                        multiline: false,
                        value: data.isTaxable ? "Yes" : "No",
                        name: 'IsTaxable',
                        title: 'Taxable',
                        tooltip: false,
                    },
                    Profession: {
                        multiline: false,
                        value: data.profession || "",
                        name: 'Profession',
                        title: 'Profession',
                        tooltip: false,
                    },
                    Address: {
                        multiline: false,
                        value: data.address || "",
                        name: 'Address',
                        title: 'Owner Address',
                        tooltip: false,
                    }
                },
            },
        ],
    };


    return dynamicView;

}

export const getInsuredView = (data: IPersonWidgetInfo[]) => {
    const dynamicView: IDynamicDiplayView = {
        name: 'Insured',
        sections: data.map((item, index) =>
            ({
                name: 'Insured',
                title: '',
                properties: {
                    ["Insured" + index]: {
                        multiline: false,
                        value: item.name,
                        name: 'Insured' + index,
                        title: 'Insured',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    ["DateOfBirth" + index]: {
                        multiline: false,
                        value: item.dateOfBirth,
                        name: 'DateOfBirth' + index,
                        title: 'Date of Birth',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    ["PhoneType" + index]: {
                        multiline: false,
                        value: item.phoneType || "",
                        name: 'PhoneType' + index,
                        title: 'Phone Type',
                        tooltip: false,
                    },
                    ["PhoneNumber" + index]: {
                        multiline: false,
                        value: item.phoneNumber || "",
                        name: 'PhoneNumber' + index,
                        title: 'Phone Number',
                        tooltip: false,
                        type: EnhancedDisplayType.IntPhoneNumber
                    },
                    ["Nationality" + index]: {
                        multiline: false,
                        value: item.nationalityOne || "",
                        name: 'Nationality' + index,
                        title: 'Nationality',
                        tooltip: false,
                    },
                    ["IsTaxable" + index]: {
                        multiline: false,
                        value: item.isTaxable ? "Yes" : "No",
                        name: 'IsTaxable' + index,
                        title: 'Taxable',
                        tooltip: false,
                    },
                    ["Profession" + index]: {
                        multiline: false,
                        value: item.profession || "",
                        name: 'Profession' + index,
                        title: 'Profession',
                        tooltip: false,
                    },
                    ["Address" + index]: {
                        multiline: false,
                        value: item.address || "",
                        name: 'Address' + index,
                        title: 'Insured Address',
                        tooltip: false,
                    },
                    ["Order" + index]: {
                        multiline: false,
                        value: item.order?.toString() || "",
                        name: 'Order' + index,
                        title: 'Order',
                        tooltip: false,
                    }
                },
            }))
    };


    return dynamicView;

}

export const getBenefieryView = (data: IPersonWidgetInfo[]) => {
    const dynamicView: IDynamicDiplayView = {
        name: 'Beneficiary',
        sections: data.map((item, index) =>
            ({
                name: 'Beneficiary',
                title: '',
                properties: {
                    ["Beneficiary" + index]: {
                        multiline: false,
                        value: item.name,
                        name: 'Beneficiary' + index,
                        title: 'Beneficiary',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    ["DateOfBirth" + index]: {
                        multiline: false,
                        value: item.dateOfBirth,
                        name: 'DateOfBirth' + index,
                        title: 'Date of Birth',
                        tooltip: false,
                        type: EnhancedDisplayType.Date
                    },
                    ["PhoneType" + index]: {
                        multiline: false,
                        value: item.phoneType || "",
                        name: 'PhoneType' + index,
                        title: 'Phone Type',
                        tooltip: false,
                    },
                    ["PhoneNumber" + index]: {
                        multiline: false,
                        value: item.phoneNumber || "",
                        name: 'PhoneNumber' + index,
                        title: 'Phone Number',
                        tooltip: false,
                        type: EnhancedDisplayType.IntPhoneNumber
                    },
                    ["Nationality" + index]: {
                        multiline: false,
                        value: item.nationalityOne || "",
                        name: 'Nationality' + index,
                        title: 'Nationality',
                        tooltip: false,
                    },
                    ["IsTaxable" + index]: {
                        multiline: false,
                        value: item.isTaxable ? "Yes" : "No",
                        name: 'IsTaxable' + index,
                        title: 'Taxable',
                        tooltip: false,
                    },
                    ["Profession" + index]: {
                        multiline: false,
                        value: item.profession || "",
                        name: 'Profession' + index,
                        title: 'Profession',
                        tooltip: false,
                    },
                    ["Address" + index]: {
                        multiline: false,
                        value: item.address || "",
                        name: 'Address' + index,
                        title: 'Beneficiary Address',
                        tooltip: false,
                    },
                    ["Percentage" + index]: {
                        multiline: false,
                        value: item.percentage?.toString() || "",
                        name: 'Percentage' + index,
                        title: 'Percentage',
                        tooltip: false,
                        type: EnhancedDisplayType.Percentage
                    }
                },
            }))
    };


    return dynamicView;

}


