import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  setBusinessPartnerPlanCommission,
  getDefaultCommissionEnums,
} from "./queries";
import {
  LookupToList,
} from "./utils";
import Loader from "../../components/Loader";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { formatDateTime } from "../../utils/formatting-utils";
import {
  ISalesforceBusinessPartnerCommissionDrawerProps,
  ISalesforceBusinessPartnerCommissionInfo,
} from ".";

const SalesforceBusinessPartnerCommissionDrawer: React.FC<
  ISalesforceBusinessPartnerCommissionDrawerProps
> = ({
  businessPartnerId,
  businessPartnerType,
  selectedBusinessPlanIds,
  businessPartnerLinesIds,
  selectedDetailedSelection,
  open,
  onSuccess,
  onClose,
}) => {
    const [booted, setBooted] = useState<boolean>(false);

    const [defaultCommissionEnumResults] = useLazyQuery(getDefaultCommissionEnums());

    const [setBusinessPartnerPlanCommissionAction] = useMutation(
      setBusinessPartnerPlanCommission()
    );

    const [formDisabled, setFormDisabled] = useState(false);
    const [submitButtonState, setSubmitButtonState] =
      useState<EnhancedButtonStatus>();

    const [inputsForm, setInputsForm] =
      useState<Record<string, DynamicFormInputType>>(inputs);

    const loadLovList = async () => {
      const result = await defaultCommissionEnumResults({
        fetchPolicy: "no-cache",
      });

      const list = LookupToList(result.data);
      return list;
    }

    const getEntityInfo = () => {

      const isSingleSelection = selectedBusinessPlanIds.length === 1;

      let defaultCommissionEntity: Record<string, any> = {
        commissionType: "RATE",
        lineCommissionValue: "0",
        lineCommissionValueCurrency: "39735",
        lineCommissionRate: "0",
        lineCommissionApplicableOn: "",
        activeFrom: "",
        activeTo: "",
      };

      if (isSingleSelection) {
        const type =
          selectedDetailedSelection[0]?.businessPartnerCommissionType !== null
            ? selectedDetailedSelection[0]?.businessPartnerCommissionType
            : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
              true
              ? selectedDetailedSelection[0]?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionType.toUpperCase()
              : selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType !==
                null
                ? selectedDetailedSelection[0]
                  ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionType
                : "RATE";
        const rate =
          selectedDetailedSelection[0]?.businessPartnerCommissionRate !==
            (0 || undefined || null)
            ? selectedDetailedSelection[0]?.businessPartnerCommissionRate * 100
            : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
              true
              ? selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionRate *
              100
              : selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate !==
                (0 || undefined || null)
                ? selectedDetailedSelection[0]
                  ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionRate *
                100
                : 0;
        const value =
          selectedDetailedSelection[0]?.businessPartnerCommissionValue !==
            (0 || undefined || null)
            ? selectedDetailedSelection[0]?.businessPartnerCommissionValue
            : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
              true
              ? selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValue
              : selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue !==
                (0 || undefined || null)
                ? selectedDetailedSelection[0]
                  ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_LineCommissionValue
                : 0;

        const valueCurrency =
          selectedDetailedSelection[0]?.businessPartnerCommissionValueCurrency !==
            (null || undefined)
            ? selectedDetailedSelection[0]?.businessPartnerCommissionValueCurrency
            : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
              true
              ? selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionValueCurrency
              : selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency !==
                null
                ? selectedDetailedSelection[0]
                  ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ValueCurrency
                : "39735";
        const applicableOn =
          selectedDetailedSelection[0]?.businessPartnerCommissionApplicableOn !==
            (null || undefined)
            ? selectedDetailedSelection[0]?.businessPartnerCommissionApplicableOn
            : selectedDetailedSelection[0]
              ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicable ===
              true
              ? selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_Plan_SpecialCommissionApplicableOn
              : selectedDetailedSelection[0]
                ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn !==
                null
                ? selectedDetailedSelection[0]
                  ?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_ApplicableOn
                : "";
        const activeFrom =
          selectedDetailedSelection[0]?.businessPartnerActiveFrom !== null
            ? selectedDetailedSelection[0]?.businessPartnerActiveFrom
            : "";
        const activeTo =
          selectedDetailedSelection[0]?.businessPartnerActiveTo !== null
            ? selectedDetailedSelection[0]?.businessPartnerActiveTo
            : "";


        defaultCommissionEntity = {
          commissionType: type?.toUpperCase(),
          lineCommissionValue: value,
          lineCommissionValueCurrency: valueCurrency,
          lineCommissionRate: Math.round(
            parseFloat(Number(rate).toFixed(10))
          ),
          lineCommissionApplicableOn: applicableOn,
          activeFrom: activeFrom,
          activeTo: activeTo,
        };
      }
      return defaultCommissionEntity;
    }

    const initialize = async () => {

      try {
        const updatedInputs = cloneDeep(inputsForm);
        const lovResult = await loadLovList();
        (updatedInputs.commissionType as IFormSelectDynamicProps).selectOptions = lovResult["commissionTypes"];
        (updatedInputs.lineCommissionValueCurrency as IFormSelectDynamicProps).selectOptions = lovResult["currencies"];
        (updatedInputs.lineCommissionApplicableOn as IFormSelectDynamicProps).selectOptions = lovResult["commissionApplicableOnOptions"];
  
        const entityData = getEntityInfo() as ISalesforceBusinessPartnerCommissionInfo;
  
        updatedInputs.commissionType.value = entityData.commissionType;
        updatedInputs.lineCommissionValue.value = entityData.lineCommissionValue;
        updatedInputs.lineCommissionValueCurrency.value = entityData.lineCommissionValueCurrency;
        updatedInputs.lineCommissionRate.value = entityData.lineCommissionRate;
        updatedInputs.lineCommissionApplicableOn.value = entityData.lineCommissionApplicableOn;
        updatedInputs.activeFrom.value = entityData.activeFrom;
        updatedInputs.activeTo.value = entityData.activeTo;
  
        setInputsForm(updatedInputs);
        setBooted(true);

      } catch(e) {
        console.log(e);
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }
    }

    const submitForm = async (values: Record<string, any>) => {
      const [data] = normaliseDynamicValues(inputs, values);
    
      setFormDisabled(true);
      setSubmitButtonState("loading");
    
      try {
        const isRate = data.commissionType.toLowerCase() === "rate";
    
        const variables = {
          selectedBusinessPartnerPlanIds: businessPartnerLinesIds,
          businessPartnerPlanInputs: {
            activeFrom: formatDateTime(
              data.activeFrom,
              SEND_TO_BACKEND_DATE_FORMAT
            ),
            activeTo: formatDateTime(data.activeTo, SEND_TO_BACKEND_DATE_FORMAT),
            planCommissionApplicableOn:isRate ? (data.lineCommissionApplicableOn || null) : null,
            planCommissionRate: isRate
              ? parseInt(data.lineCommissionRate, 10) / 100
              : 0,
            planCommissionType: data.commissionType,
            planCommissionValue: !isRate
              ? parseInt(data.lineCommissionValue, 10)
              : 0,
            planCommissionValueCurrency: !isRate
              ? data.lineCommissionValueCurrency
              : "39735",
          },
        };
    
        const res = await setBusinessPartnerPlanCommissionAction({
          variables: { ...variables, entityId: businessPartnerId },
          errorPolicy: "all",
        });
    
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {"Commissions successfully updated."}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            setFormDisabled(false);
          }, 500);
        } else {
          setFormDisabled(false);
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      } catch (err) {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      } finally {
      }
    };
    

    useEffect(() => {
      initialize();
    }, []);


    return (
      <GenericDrawer
        title={"Set Commission"}
        onClose={() => onClose()}
        isOpen={open}
      >
        {!booted ? (
          <Loader />
        ) : (
          <>
            <DynamicForm
              inputs={inputsForm}
              onSubmit={(values) => submitForm(values)}
              buttonText={"Submit"}
              submitButtonState={submitButtonState}
              disableForm={formDisabled}
              title="Information"
            />
          </>
        )}
      </GenericDrawer>
    );
}

export default SalesforceBusinessPartnerCommissionDrawer;
