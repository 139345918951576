import _, { isArray, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import WidgetSection from "../../../components/common/WidgetSection";
import { IBeneficiaryData, IProposalPageFormState } from "./form";
import CollapsibleSection from "../../../components/common/CollapsibleSection";
import ProposalPolicyPersonDrawer from "../../proposal-policy-person-drawer/ProposalPolicyPersonDrawer";
import { removeObjectAtIndex } from "../../../utils/helper-utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EnhancedPercentageInput from "../../../components/enhanced-form/EnhancedPercentageInput";
import { useLazyQuery } from "@apollo/client";
import { fetchedPersonAddressList, fetchedPersons } from "../queries";
import { extractPersonsAddressesFixed, extractPersonsFixed } from "../utils";
import { useParams } from "react-router-dom";
import { isGuid } from "../../../utils/validationUtils";
import NewChipsInput from "../../../components/enhanced-form/NewChipsInput";
import { validatePolicyBeneficiaryField } from "../../../modules/production/proposal/page/expat/validation";
import { IProposalDetailsSummary } from "./index2";
import PersonAddressDrawer from "../../person-address-drawer/PersonAddressDrawer";

interface IPolicyDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  maxNumber: number;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(4, 23%)`,
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  field: {
    width: "100%",
    marginRight: "25px !important",
    minWidth: "195px",
  },
  beneficiaryRow: {
    flexWrap: "wrap",
    margin: "10px",
    alignItems: "flex-start",
    display: "grid",
    gap: "20px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "22% 22% 20% 12% 12.5% ",
  },
  section: {
    display: "grid",
    "grid-template-columns": "23.5% 23.5% 23.5% 23.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& label": {
      "& span": {
        margin: "0 0 0",
      },
    },
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    overflow: "visible",
  },
  addBtn: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0",
    margin: "20px auto 0",
    display: "block",
  },
  removeBtn: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0",
    margin: "40px auto 0",
    display: "block",
  },
}));

const PolicyBeneficiaryWidget: React.FC<IPolicyDetailsWidgetProps> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  maxNumber,
  lovs,
  data,
}) => {
  const { classes } = useStyles();
  const params = useParams();
  const entityId = params.id;

  const [loaded, setLoaded] = useState<boolean>(false);

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] = useState<number>(-1);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] = useState<number>(-1);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<{
    id: Record<number, Record<string, string>>;
    beneficiaryAddresses: Record<number, Record<string, string>>;
    primaryAddresses: Record<number, string>;
  }>({
    id: {},
    beneficiaryAddresses: {},
    primaryAddresses: {},
  });

  const beneficiaryValues = pageState.values.policyPersons.beneficiaries;
  const beneficiaryErrors = pageState.errors.policyPersons.beneficiaries;
  const beneficiaryTouched = pageState.touched.policyPersons.beneficiaries;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());
  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList());

  const handleOpenDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(rowIndex);
  };

  const handleOpenAddressDrawer = (section: string, rowIndex: number) => {
    setCurrentSection(section);
    setNewAddressDrawerOpen(rowIndex);
  };

  const handleBeneficiaryAddresses = async (personId: string) => {
    const result = await getPersonAddressListLazy({
      variables: { selectedPersonID: personId },
      errorPolicy: "all",
    });
    return extractPersonsAddressesFixed(result.data);
  };

  const handleBeneficiarySearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [data?.PrimaryBPID?.Id],
        },
      });

      return extractPersonsFixed(newData.data);
    }

    return {} as Record<string, string>;
  };

  const initialize = () => {
    const newList: Record<number, Record<string, string>> = {};
    beneficiaryValues.forEach((beneficiary, index) => {
      if (!newList[index]) {
        newList[index] = {};
      }
      newList[index][beneficiary.address] = beneficiary.addressDetails;
    });
    setLists((prevLists) => ({
      ...prevLists,
      beneficiaryAddresses: newList,
    }));
    setLoaded(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = (fieldName: string, rowIndex?: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.policyPersons.beneficiaries[rowIndex][fieldName] =
      true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: string,
    value: any,
    rowIndex?: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newLists = { ...updatedList };

    if (fieldName === "id") {
      const mainValue: string = Array.isArray(value) ? value[0] : value;

      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.beneficiaries[rowIndex][fieldName] =
          mainValue;
        newPageState.errors.policyPersons.beneficiaries[rowIndex][fieldName] =
          validatePolicyBeneficiaryField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.beneficiaries[rowIndex][
            fieldName
          ] = true;
        }

        if (isGuid(mainValue)) {
          const searchAddressResult = await handleBeneficiaryAddresses(
            mainValue
          );

          const beneficiaryAddresses = searchAddressResult;
          let extractedAddresses: any = {};
          if (beneficiaryAddresses) {
            Object.keys(beneficiaryAddresses).forEach((key) => {
              extractedAddresses[key] = beneficiaryAddresses[key].address;
              if (beneficiaryAddresses[key].isPrimary) {
                newPageState.values.policyPersons.beneficiaries[
                  rowIndex
                ].address = key;
                newPageState.values.policyPersons.beneficiaries[
                  rowIndex
                ].addressDetails = beneficiaryAddresses[key].address;
              }
            });
            newPageState.errors.policyPersons.beneficiaries[
              rowIndex
            ].beneficiaryAddress = "";
            newLists["beneficiaryAddresses"][rowIndex] = extractedAddresses;
          }
        }
      }
    } else {
      (newPageState.values.policyPersons.beneficiaries[rowIndex] as any)[
        fieldName
      ] = value;

      newPageState.errors.policyPersons.beneficiaries[rowIndex][fieldName] =
        validatePolicyBeneficiaryField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.beneficiaries[rowIndex][fieldName] =
          true;
      }
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdateWithPageState = async (
    newPageState: IProposalPageFormState,
    fieldName: string,
    value: any,
    rowIndex?: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newLists = { ...updatedList };

    if (fieldName === "id") {
      const mainValue: string = Array.isArray(value) ? value[0] : value;

      if (isEmpty(mainValue) || isGuid(mainValue)) {
        newPageState.values.policyPersons.beneficiaries[rowIndex][fieldName] =
          mainValue;
        newPageState.errors.policyPersons.beneficiaries[rowIndex][fieldName] =
          validatePolicyBeneficiaryField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.beneficiaries[rowIndex][
            fieldName
          ] = true;
        }

        if (isGuid(mainValue)) {
          const searchAddressResult = await handleBeneficiaryAddresses(
            mainValue
          );

          const beneficiaryAddresses = searchAddressResult;
          let extractedAddresses: any = {};
          if (beneficiaryAddresses) {
            Object.keys(beneficiaryAddresses).forEach((key) => {
              extractedAddresses[key] = beneficiaryAddresses[key].address;
              if (beneficiaryAddresses[key].isPrimary) {
                newPageState.values.policyPersons.beneficiaries[
                  rowIndex
                ].address = key;
                newPageState.values.policyPersons.beneficiaries[
                  rowIndex
                ].addressDetails = beneficiaryAddresses[key].address;
              }
            });
            newPageState.errors.policyPersons.beneficiaries[
              rowIndex
            ].beneficiaryAddress = "";
            newLists["beneficiaryAddresses"][rowIndex] = extractedAddresses;
          }
        }
      } else {
        const result: Record<
          string,
          Record<string, string>
        > = (await handleBeneficiarySearch(value)) as any;
        newLists["id"][rowIndex] = result;
      }
    } else {
      (newPageState.values.policyPersons.beneficiaries[rowIndex] as any)[
        fieldName
      ] = value;

      newPageState.errors.policyPersons.beneficiaries[rowIndex][fieldName] =
        validatePolicyBeneficiaryField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.beneficiaries[rowIndex][fieldName] =
          true;
      }
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
    initialize();
  };

  const onPersonAddition = async (person: any, personFullName?: any) => {
    const rowIndex = newPersonDrawerOpen;

    const newPageState = _.cloneDeep(pageState);

    const newLists = _.cloneDeep(lists);

    newPageState.values.policyPersons.beneficiaries[rowIndex]["id"] = person;
    newPageState.errors.policyPersons.beneficiaries[rowIndex]["id"] = "";
    newPageState.touched.policyPersons.beneficiaries[rowIndex]["id"] = true;

    newLists.id[rowIndex] = {};
    newLists.id[rowIndex][person] = personFullName;

    const searchAddressResult = await handleBeneficiaryAddresses(person);

    const beneficiaryAddresses = searchAddressResult;
    let extractedAddresses: any = {};
    if (beneficiaryAddresses) {
      Object.keys(beneficiaryAddresses).forEach((key) => {
        extractedAddresses[key] = beneficiaryAddresses[key].address;
        if (beneficiaryAddresses[key].isPrimary) {
          newPageState.values.policyPersons.beneficiaries[rowIndex].address =
            key;
          newPageState.values.policyPersons.beneficiaries[
            rowIndex
          ].addressDetails = beneficiaryAddresses[key].address;
        }
      });
      newPageState.errors.policyPersons.beneficiaries[
        rowIndex
      ].beneficiaryAddress = "";
      newLists["beneficiaryAddresses"][rowIndex] = extractedAddresses;
    }

    if (Object.keys(newLists["beneficiaryAddresses"][rowIndex]).length === 1) {
      newPageState.values.policyPersons.beneficiaries[rowIndex]["address"] =
        Object.keys(newLists["beneficiaryAddresses"][rowIndex])[0];
    }

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onAddressAddition = async (address: any, addressFullName?: any) => {
    const rowIndex = newAddressDrawerOpen;

    const newPageState = _.cloneDeep(pageState);

    const newLists = _.cloneDeep(lists);

    newPageState.values.policyPersons.beneficiaries[rowIndex]["address"] =
      address;
    newPageState.errors.policyPersons.beneficiaries[rowIndex]["address"] = "";
    newPageState.touched.policyPersons.beneficiaries[rowIndex]["address"] =
      true;

    newLists.beneficiaryAddresses[rowIndex] = {};
    newLists.beneficiaryAddresses[rowIndex][address] = addressFullName;

    newPageState.values.policyPersons.beneficiaries[rowIndex].address = address;
    newPageState.values.policyPersons.beneficiaries[rowIndex].addressDetails =
      addressFullName;

    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: any,
    rowIndex: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newLists = { ...updatedList };

    if (fieldName === "id") {
      const result: Record<
        string,
        Record<string, string>
      > = (await handleBeneficiarySearch(value)) as any;
      newLists["id"][rowIndex] = result;
    }

    setLists(newLists);
  };

  const handleDrawerSubmitSuccess = (
    person: any,
    newPersonFullName: string,
    rowIndex?: number
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newPersonList: Record<string, string> = {};
    newPersonList[person] = newPersonFullName;

    newPageState.values.policyPersons.insured[0].fullName = newPersonFullName;
    onFieldUpdateWithPageState(newPageState, "id", [person], 0, false, {
      id: { insuredPersons: newPersonList },
    });

    setNewPersonDrawerOpen(-1);
  };

  handleDrawerSubmitSuccess;

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    beneficiary: IBeneficiaryData
  ) => {
    if (Object.keys(list || {}).length > 0 || !beneficiary.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [beneficiary.id]: beneficiary.fullName,
    };

    return newList;
  };

  const renderBeneficiaryRow = (row: IBeneficiaryData) => {
    const rowIndex = beneficiaryValues.indexOf(row);

    return (
      <div className={classes.beneficiaryRow} key={rowIndex}>
        <NewChipsInput
          key="id"
          name="id"
          title="Beneficiary"
          placeholder="Beneficiary"
          value={beneficiaryValues[rowIndex].id}
          error={
            beneficiaryTouched[rowIndex].id
              ? beneficiaryErrors[rowIndex].id
              : ""
          }
          onChange={(v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldUpdate("id", value, rowIndex);
          }}
          onSearch={async (v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldSearch("id", value, rowIndex);
          }}
          clearValueOnSearch={false}
          disabled={disabledForm}
          items={getDefaultPersonListIfEmpty(
            lists?.id[rowIndex],
            beneficiaryValues[rowIndex]
          )}
          required={true}
          multiple={false}
          material={true}
          supportLink={disabledForm ? "" : "Add Person"}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenDrawer("beneficiary", rowIndex);
          }}
        />

        <NewChipsInput
          key="address"
          name="address"
          title="Beneficiary Address"
          placeholder="Beneficiary Address"
          value={beneficiaryValues[rowIndex].address}
          clearValueOnSearch={false}
          error={
            beneficiaryTouched[rowIndex].address
              ? beneficiaryErrors[rowIndex].address
              : ""
          }
          onChange={(v) => onFieldUpdate("address", v, rowIndex)}
          disabled={disabledForm}
          items={lists.beneficiaryAddresses[rowIndex]}
          required={true}
          multiple={false}
          material={true}
          supportLink={disabledForm ? "" : "Add Address"}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenAddressDrawer("beneficiary", rowIndex);
          }}
        />

        <EnhancedPercentageInput
          key="percentage"
          name="percentage"
          title="Percentage"
          placeholder="Percentage"
          value={beneficiaryValues[rowIndex].percentage}
          error={
            beneficiaryTouched[rowIndex].percentage
              ? beneficiaryErrors[rowIndex].percentage
              : ""
          }
          onBlur={(v) => onFieldBlur("percentage", rowIndex)}
          onChange={(v) =>
            onFieldUpdate("percentage", v.target.value, rowIndex)
          }
          disabled={disabledForm}
          material={true}
          className={classes.field}
        />

        {beneficiaryValues?.length > 1 && (
          <button
            className={classes.removeBtn}
            onClick={() => onBeneficiaryRowDelete(rowIndex)}
          >
            <HighlightOffIcon fontSize="small" />
          </button>
        )}
      </div>
    );
  };

  const onBeneficiaryRowAdd = () => {
    if (!disabledForm && beneficiaryValues.length < maxNumber) {
      const newPageState = _.cloneDeep(pageState);
      newPageState.values.policyPersons.beneficiaries.push({
        id: "",
        address: "",
        percentage: 0,
      });

      newPageState.errors.policyPersons.beneficiaries.push({
        id: "",
        percentage: "",
        address: "",
      });

      newPageState.touched.policyPersons.beneficiaries.push({
        id: false,
        percentage: false,
        address: false,
      });

      onPageStateUpdate(newPageState);
    }
  };

  const onBeneficiaryRowDelete = (rowIndex: number) => {
    if (!disabledForm) {
      const newPageState = _.cloneDeep(pageState);
      newPageState.values.policyPersons.beneficiaries = removeObjectAtIndex(
        newPageState.values.policyPersons.beneficiaries,
        rowIndex
      );
      newPageState.errors.policyPersons.beneficiaries = removeObjectAtIndex(
        newPageState.errors.policyPersons.beneficiaries,
        rowIndex
      );
      newPageState.touched.policyPersons.beneficiaries = removeObjectAtIndex(
        newPageState.touched.policyPersons.beneficiaries,
        rowIndex
      );

      setLists((prevLists) => {
        const newId: Record<number, Record<string, string>> = {};
        const newBeneficiaryAddresses: Record<
          number,
          Record<string, string>
        > = {};
        const newPrimaryAddresses: Record<number, string> = {};

        Object.keys(prevLists.id).forEach((key) => {
          const keyNum = Number(key);
          if (keyNum < rowIndex) {
            newId[keyNum] = prevLists.id[keyNum];
            newBeneficiaryAddresses[keyNum] =
              prevLists.beneficiaryAddresses[keyNum];
          } else if (keyNum > rowIndex) {
            newId[keyNum - 1] = prevLists.id[keyNum];
            newBeneficiaryAddresses[keyNum - 1] =
              prevLists.beneficiaryAddresses[keyNum];
          }
        });

        return {
          id: newId,
          beneficiaryAddresses: newBeneficiaryAddresses,
          primaryAddresses: newPrimaryAddresses,
        };
      });

      onPageStateUpdate(newPageState);
    }
  };

  if (!loaded) {
    return <></>;
  }

  return (
    <CollapsibleSection title={"Beneficiary"} open>
      <WidgetSection title="" classes={{ container: classes.sectionFullRow }}>
        {beneficiaryValues.map((v) => renderBeneficiaryRow(v))}
        {newPersonDrawerOpen !== -1 && currentSection === "beneficiary" && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen != -1}
            onClose={() => setNewPersonDrawerOpen(-1)}
            section={currentSection}
            planId={""}
            lineExternalCode={data?.LineId?.ExternalCode}
            onSuccess={() => {}}
            proposalId={entityId}
            onSubmitSuccess={onPersonAddition}
          />
        )}
        {newAddressDrawerOpen !== -1 && currentSection === "beneficiary" && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen != -1}
            onClose={() => setNewAddressDrawerOpen(-1)}
            personId={pageState.values.policyPersons.beneficiaries[0].id}
            onSuccess={() => {}}
            onSubmitSuccess={onAddressAddition}
          />
        )}
        {beneficiaryValues.length < maxNumber && (
          <button
            className={classes.addBtn}
            onClick={() => onBeneficiaryRowAdd()}
          >
            <AddCircleOutlineIcon color="error" fontSize="small" />
          </button>
        )}
      </WidgetSection>
    </CollapsibleSection>
  );
};

export default PolicyBeneficiaryWidget;
