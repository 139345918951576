import React, { useEffect, useState } from "react";
import { DEFAULT_ERROR_TEXT } from "../../../../../constants";
import EnhancedTable from "../../../../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../../../../models/listing";
import { headers } from "./content";
import { useLazyQuery } from "@apollo/client";
import { getPolicyBills } from "../queries";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../../../../components/ToastErrorMessage";
import Loader from "../../../../../components/Loader";
import { mapToListingData } from "./utils";
import {
	IEnhancedTableMenuItem,
	IEnhanceTableHeaderClickable,
} from "../../../../../components/enhanced-table";
import { makeStyles } from "tss-react/mui";
import PaymentTermsPopup from "../../../../../forms/payment-terms/PaymentTermsPopup";

interface IPolicyPaymentTermsSchedule {
	policyId: string;
}

const useStyles = makeStyles()(() => ({
	paperClass: {
		color: "red",
	},
}));

const ProductionPolicyPaymentTermsSchedule: React.FC<
	IPolicyPaymentTermsSchedule
> = ({ policyId }) => {
	const { classes } = useStyles();
	const [booted, setBooted] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [chosenBreakdownId, setChosenBreakdownId] = useState<string>();
	const [showTermsPopup, setShowTermsPopup] = useState<boolean>(false);
	const [tableData, setTableData] = useState<IListingData<any>>({
		pagedItems: {},
		pageSize: 10,
		pageNumber: 0,
		totalCount: 0,
	});

	const tableAction: IEnhancedTableMenuItem[] = [
		{
			title: "Change Payment Terms",
			onClick: () => {
				setShowTermsPopup(true);
			},
			isEntity: false,
			isBulk: false,
			disabled: false,
		},
	];

	const [getPolicyBillsLazy] = useLazyQuery(getPolicyBills());

	const loadData = async (currentPage = 0, pageSize = tableData.pageSize) => {
		setLoading(true);

		try {
			const result = await getPolicyBillsLazy({
				variables: {
					policyId: policyId,
					pageNumber: 1,
					pageSize: tableData.pageSize,
				},
			});

			if (result.error) {
				toast.error(
					<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
				);
				return;
			}

			let data = result.data;

			const newTableData = mapToListingData(data);
			setTableData({ ...newTableData, pageNumber: currentPage, pageSize });
		} catch (error) {
			console.error("Error loading data:", error);
			toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
		} finally {
			setLoading(false);
			setBooted(true);
		}
	};

	const initialize = async () => {
		await loadData(0, tableData.pageSize);
	};

	useEffect(() => {
		initialize();
	}, []);

	const handlePageChange = async (page: number) => {
		await loadData(page, tableData.pageSize);
	};

	const handleRowsPerPageChange = async (numberOfRecordsPerPage: number) => {
		await loadData(0, numberOfRecordsPerPage);
	};

	(
		headers.accounting_Bills_TotalCommission as IEnhanceTableHeaderClickable
	).callback = (payload: any) => {
		setChosenBreakdownId(payload.accounting_Bills_BillNumber);
	};

	if (!booted) {
		return <Loader />;
	}

	return (
		<>
			<EnhancedTable
				inlineTitle="Payment Terms Schedule"
				name="paymentTermsSchedule"
				data={tableData}
				headers={headers}
				handlePageChange={(page: number) => handlePageChange(page)}
				handleRowsPerPageChange={(page: number) => {
					handleRowsPerPageChange(page);
				}}
				currentPage={tableData.pageNumber}
				loader={loading}
				tableClasses={classes}
				actions={tableAction}
				showInlineFilter={false}
				hideToolbar={false}
				inline
				isTitlePascalCase
				orderByAscendingByDefault
				disableSelection
				usePagination
				showCellFullData
			/>
			{chosenBreakdownId && <div>Hello</div>}
			{showTermsPopup && (
				<PaymentTermsPopup
					policyId={policyId}
					open={showTermsPopup}
					onClose={() => setShowTermsPopup(false)}
				/>
			)}
		</>
	);
};

export default ProductionPolicyPaymentTermsSchedule;
