import _ from "lodash";
import React from "react";
import { makeStyles } from "tss-react/mui";
import WidgetPaper from "../../../components/common/WidgetPaper";
import WidgetSection from "../../../components/common/WidgetSection";
import EnhancedChipInput from "../../../components/enhanced-form/EnhancedChipInput";
import EnhancedCurrencyInput from "../../../components/enhanced-form/EnhancedCurrencyInput";
import EnhancedPercentageInput from "../../../components/enhanced-form/EnhancedPercentageInput";
import { validateCostWidgetField } from "../validation";
import { IProposalCostCharges, IProposalPageFormState } from "./form";

interface IPolicyCostChargesWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  status: string;
  editableLine: boolean;
  line: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(4, 23%)`,
    gap: '2%',
    justifyContent: "flex-start",
    alignItems: 'center',
    alignContent: 'center'
  },
}));

const PolicyCostChargesWidget: React.FC<IPolicyCostChargesWidgetProps> =
  ({ pageState, onPageStateUpdate, disabledForm, status, editableLine, line }) => {

    const { classes } = useStyles();

    const values = pageState.values.costs;
    const errors = pageState.errors.costs;
    const touched = pageState.touched.costs;

    const disabledRow = disabledForm || status?.toUpperCase() === "CLOSED_WON" || status?.toUpperCase() === "CLOSED_LOST" || !editableLine;


    const onFieldBlur = (fieldName: string) => {

      const newPageState = _.cloneDeep(pageState);
      newPageState.touched.costs[fieldName] = true;
      onPageStateUpdate(newPageState)
    }

    const onFieldUpdate = (fieldName: keyof IProposalCostCharges, value: any, touched = false) => {
      const newPageState = _.cloneDeep(pageState);

      (newPageState.values.costs as any)[fieldName] = value;
      (newPageState.errors.costs as any)[fieldName] = validateCostWidgetField(fieldName, value, newPageState.values.costs, line);

      if (fieldName === "tpaFeesType") {
        newPageState.values.costs.tpaFeesPercentage = null;
        newPageState.values.costs.tpaFeesAmount = null;

        newPageState.errors.costs.tpaFeesPercentage = "";
        newPageState.errors.costs.tpaFeesAmount = "";

        newPageState.touched.costs.tpaFeesPercentage = false;
        newPageState.touched.costs.tpaFeesAmount = false;
      }

      if (touched) {
        newPageState.touched.costs[fieldName] = true;
      }

      onPageStateUpdate(newPageState)
    }

    return (
      <WidgetPaper style={{ marginTop: "1em" }}>
        <WidgetSection
          title="Costs and Charges"
          hasTitleSpecificDesign={false}
          classes={{
            // container: classes.section,
          }}
          useSeparator={false}
        >

          <div className={classes.fieldRow}>
            <EnhancedCurrencyInput
              key="policyCost"
              name="policyCost"
              title="Policy Cost*"
              placeholder="Policy Cost"
              value={values.policyCost}
              error={touched.policyCost ? errors.policyCost : ""}
              onBlur={(v) => onFieldBlur("policyCost")}
              onChange={(v) => onFieldUpdate("policyCost", v.target.value)}
              disabled={disabledRow}
              type={""}
              material={true}
            />

            <EnhancedPercentageInput
              key="chargesPercentage"
              name="chargesPercentage"
              title="Charges Percentage*"
              placeholder="Charges Percentage"
              value={values.chargesPercentage}
              error={touched.chargesPercentage ? errors.chargesPercentage : ""}
              onBlur={(v) => onFieldBlur("chargesPercentage")}
              onChange={(v) => onFieldUpdate("chargesPercentage", v.target.value)}
              disabled={disabledRow}
              material
            />
            {
              line !== "43" &&
              <EnhancedChipInput
                key="tpaFeesType"
                name="tpaFeesType"
                title="TPA Fees Type*"
                placeholder="TPA Fees Type"
                value={values.tpaFeesType}
                error={touched.tpaFeesType ? errors.tpaFeesType : ""}
                onChange={(v) => onFieldUpdate("tpaFeesType", v, true)}
                disabled={disabledRow}
                // customStyles={input.customStyles}
                selectOptions={{
                  PERCENTAGE: "Percentage",
                  AMOUNT: "AMOUNT"
                }}
                required={true}
                // freeSolo={input.freeSolo}
                multiple={false}
                material={true}
                // loader={loadingFields[input.name] || false}
                canClearSingleValueSelection={false}
              />
            }
            {
              values.tpaFeesType === "PERCENTAGE" &&
              <EnhancedPercentageInput
                key="tpaFeesPercentage"
                name="tpaFeesPercentage"
                title="TPA Fees Percentage*"
                placeholder="TPA Fees Percentage"
                value={values.tpaFeesPercentage}
                error={touched.tpaFeesPercentage ? errors.tpaFeesPercentage : ""}
                onBlur={(v) => onFieldBlur("tpaFeesPercentage")}
                onChange={(v) => onFieldUpdate("tpaFeesPercentage", v.target.value)}
                disabled={disabledRow}
                material
              />
            }
            {
              values.tpaFeesType === "AMOUNT" &&
              <EnhancedCurrencyInput
                key="tpaFeesAmount"
                name="tpaFeesAmount"
                title="TPA Fees Amount*"
                placeholder="TPA Fees Amount"
                value={values.tpaFeesAmount}
                error={touched.tpaFeesAmount ? errors.tpaFeesAmount : ""}
                onBlur={(v) => onFieldBlur("tpaFeesAmount")}
                onChange={(v) => onFieldUpdate("tpaFeesAmount", v.target.value)}
                disabled={disabledRow}
                type={""}
                material={true}
              />
            }

            {
              line !== "43" &&
              <EnhancedChipInput
                key="tpaFeesApplicableOn"
                name="tpaFeesApplicableOn"
                title="TPA Fees ApplicableOn*"
                placeholder="TPA Fees ApplicableOn"
                value={values.tpaFeesApplicableOn}
                error={touched.tpaFeesApplicableOn ? errors.tpaFeesApplicableOn : ""}
                onChange={(v) => onFieldUpdate("tpaFeesApplicableOn", v, true)}
                disabled={disabledRow}
                // customStyles={input.customStyles}
                selectOptions={{
                  "NET_PREMIUM": "Net Premium",
                  "GROSS_PREMIUM": "Gross Premium"
                }}
                required={true}
                // freeSolo={input.freeSolo}
                multiple={false}
                material={true}
                // loader={loadingFields[input.name] || false}
                canClearSingleValueSelection={false}
              />
            }

          </div>

        </WidgetSection>
        {/* <button style={{ display: "none" }} onClick={() => validateForm()} >Submit</button> */}
      </WidgetPaper>
    )
  }

export default PolicyCostChargesWidget;