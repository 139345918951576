import {
  convertToFixedNumber,
  numberToPercentage,
} from "../../../../../utils/helper-utils";
import { isEmpty } from "../../../../../utils/validationUtils";
import {
  IBeneficiaryData,
  IInsuredData,
  IOwnerData,
  IPayerData,
  IPolicyPersons,
  IProposalBankerDetails,
  IProposalCover,
} from "./form";

export interface IProposalDetailsSummary {
  Id: string;
  ProposalName: string;
  ClauseText: string;
  InternalCode: number;
  PolicyEffectiveDate: string;
  PolicyExpiryDate: string;
  modifiedOn: string;
  lastModifiedBy: string;
  createdOn: string;
  createdBy: string;
  PolicyCurrency: {
    Code: string;
    Title: string;
    Symbol: string;
  };
  Status: string;
  StatusReason: string | null;
  LineId: {
    Id: string;
    Name: string;
    ExternalCode: string;
  };
  SubLineId: {
    Name: string;
    ExternalCode: string;
  };
  BusinessUserID: {
    Code: string;
    FullName: string;
    AllowPlanCoverEdits: boolean;
    EditableLineChargesIDs: string[];
    AllowClauseEdits: boolean;
  };
  PrimaryBPID: {
    Id: string;
    Code: string;
    FullName: string;
    RelatedCompany: string;
  };
  PlanID: {
    Id: string;
    Name: string;
    ExternalCode: string;
    ClauseEditable: boolean;
    MaxNbInsured: number;
    MaxNbBeneficiary: number;
    AcalCategory: {
      Id: string;
      Code: string;
      Title: string;
    };
    Scratching: string;
    SOSService: string;
    ReplacementCarValue: number;
    ReplacementCarDays: number;
    ReplacementCarApplied: string;
  };
  PricingOption: {
    PolicyNumber: string;
    PolicyId: string;
    PolicyCost: number;
    ChargesPercentage: number;
    TPAFeesType: string;
    TPAFeesAmount: number;
    TPAFeesPercentage: number;
    TPAFeesApplicableOn: string;
    BasicPremium: number;
    NetPremium: number;
    PlanToPolicyRate: number;
    GrossPremium: number;
    TaxOnCommissionAmount: number;
    ReinsuranceTaxAmount: number;
    MunicipalityTaxAmount: number;
    TotalPremium: number;
    ChargesAmount: number;
    TPATotalFeesAmount: number;
    FixedStampAmount: number;
    ProportionalStampAmount: number;
    Status: string;
    StatusReason?: string;
    CreatedOn: string;
    SumInsured: number;
    PricingOptionCurrencySymbol: string;
  };
  BankersDetails?: {
    BranchName: string;
    IsMain: boolean;
    IsAgency: boolean;
    NumberOfEmployees: number;
    UnderwritingYear: number;
    Infidelity: number;
    Burglary: number;
    HoldUp: number;
    InSafe: number;
    InTransit: number;
    ForgedChecks: number;
    CounterfeitCurrencies: number;
    Clause6: number;
    Falsification: number;
  }[];
  MarineDetails?: {
    Matter: {
      Id: string;
      Code: string;
      Title: string;
    };
    Description: string;
    VesselName: string;
    VesselAge: number;
    From: string;
    To: string;
    Via: string;
    Survey: string;
    CargoContract: string;
    SumPerShipment: number;
    LCNumber: string;
    LCIssueDate: string;
    Bank: string;
    ClaimsPayableBy: string;
    Loading: number;
    Remarks: string;
  };
  MarineHullDetails?: {
    VesselName: string;
    ConnectionPort: string;
    TerritorialWaters: string;
    Usage: string;
    Builder: string;
    ConstructionYear: string;
    RegisteryPort: string;
    Length: string;
    Weight: string;
    Height: string;
    EngineType: string;
    EngineNumber: string;
    EngineYearBuilt: string;
    EngineHorsePower: string;
    EngineMaxDesignedSpeed: string;
    EngineFuel: string;
    Remarks: string;
  };
  CommissionDetails: {
    Id: string;
    BusinessUserFullName: string;
    PlanCommissionType: string;
    PlanCommissionAmount: number;
    PlanCommissionPercentage: number;
    ApplicableOn: string;
    PolicyCommisisonAmount: number;
    Share: number;
    IsPrimary: boolean;
  }[];
}

export interface IProposalDetailsNew {
  id: string;
  views: {
    Production_all: {
      properties: {
        Id: string;
        ClauseText: string;
        ProposalName: string;
        InternalCode: number;
        PolicyEffectiveDate: string;
        PolicyExpiryDate: string;
        modifiedOn: string;
        createdOn: string;
        PolicyCurrency: {
          Code: string;
          Title: string;
          Symbol: string;
        };
        Status: string;
        StatusReason: string | null;
        LineID: {
          id: string;
          views: {
            PlanConfigManagement_all: {
              properties: {
                Name: string;
                ExternalCode: string;
              };
            };
          };
        };
        SublineID: {
          views: {
            PlanConfigManagement_all: {
              properties: {
                Name: string;
                ExternalCode: string;
              };
            };
          };
        };
        BusinessUserID: {
          views: {
            SalesforceManagement_all: {
              properties: {
                Code: string;
                AllowPlanCoverEdits: boolean;
                AllowClauseEdits: boolean;
                EditableLineChargesIDs: {
                  id: string;
                  views: {
                    PlanConfigManagement_all: {
                      properties: {
                        Id: string;
                      };
                    };
                  };
                }[];
                PersonID: {
                  views: {
                    SalesforceManagement_all: {
                      properties: {
                        FullName: string;
                      };
                    };
                  };
                };
              };
            };
          };
        };
        PrimaryBPID: {
          id: string;
          views: {
            SalesforceManagement_all: {
              properties: {
                Code: string;
                PersonID: {
                  views: {
                    SalesforceManagement_all: {
                      properties: {
                        FullName: string;
                      };
                    };
                  };
                };
                RelatedCompany: {
                  Code: string;
                  Title: string;
                };
              };
            };
          };
        };
        PlanID: {
          id: string;
          views: {
            PlanConfigManagement_all: {
              properties: {
                Name: string;
                ClauseEditable: boolean;
                ExternalCode: string;
                MaxNbInsured: number;
                MaxNbBeneficiary: number;
                AcalCategory: {
                  Id: string;
                  Code: string;
                  Title: string;
                };
                Scratching: string;
                SOSService: string;
                ReplacementCarValue: number;
                ReplacementCarDays: number;
                ReplacementCarApplied: string;
              };
            };
          };
        };
        MarineDetailsID: {
          id: string;
          views: {
            Production_all: {
              properties: {
                Id: string;
                Matter: {
                  Id: string;
                  Title: string;
                  Code: string;
                };
                Description: string;
                VesselName: string;
                VesselAge: number;
                From: string;
                To: string;
                Via: string;
                Survey: string;
                CargoContract: string;
                LCNumber: string;
                LCIssueDate: string;
                Bank: string;
                ClaimsPayableBy: string;
                Loading: number;
                Remarks: string;
                SumPerShipment: number;
              };
            };
          };
        };
        MarineHullDetailsID: {
          id: string;
          views: {
            Production_all: {
              properties: {
                VesselName: string;
                ConnectionPort: string;
                TerritorialWaters: string;
                Usage: string;
                Builder: string;
                ConstructionYear: string;
                RegisteryPort: string;
                Length: string;
                Weight: string;
                Height: string;
                EngineType: string;
                EngineNumber: string;
                EngineYearBuilt: string;
                EngineHorsePower: string;
                EngineMaxDesignedSpeed: string;
                EngineFuel: string;
                Remarks: string;
              };
            };
          };
        };
      };
    };
  };
  queries: {
    production: {
      relatedUsers: [
        {
          createdBy_firstName: string;
          createdBy_lastName: string;
          modifiedBy_firstName: string;
          modifiedBy_lastName: string;
        }
      ];
      pricingOption: [
        {
          policy_PolicyNumber: string;
          policy_Id: string;
          pricingOption_PolicyCost: number;
          pricingOption_ChargesPercentage: number;
          pricingOption_TPAFeesType: string;
          pricingOption_TPAFeesAmount: number;
          pricingOption_TPAFeesPercentage: number;
          pricingOption_TPAFeesApplicableOn: string;

          pricingOption_BasicPremium: number;
          pricingOption_NetPremium: number;
          pricingOption_PlanToPolicyRate: number;
          pricingOption_GrossPremium: number;
          pricingOption_TaxOnCommissionAmount: number;
          pricingOption_ReinsuranceTaxAmount: number;
          pricingOption_MunicipalityTaxAmount: number;
          pricingOption_TotalPremium: number;
          pricingOption_ChargesAmount: number;
          pricingOption_TPAFeesTotalAmount: number;
          pricingOption_FixedStampAmount: number;
          pricingOption_ProportionalStampAmount: number;
          pricingOption_Status: string;
          pricingOption_createdOn: string;
          pricingOption_SumInsured: number;
          pricingOption_PricingOptionCurrency: {
            Symbol: string;
          };
        }
      ];
    };
  };
}

export interface IProposalDetailsPageResponse {
  Production: {
    entities: {
      proposal: IProposalDetailsNew;
    };
    queries: any;
  };
}

export function convertToDetailsSummary(
  response: IProposalDetailsPageResponse
): IProposalDetailsSummary | undefined {
  const proposal = response?.Production?.entities?.proposal;
  const marineDetails =
    proposal?.views?.Production_all?.properties?.MarineDetailsID;
  const marineHullDetails =
    proposal?.views?.Production_all?.properties?.MarineHullDetailsID;
  const pricingOptionInfo = proposal?.queries?.production?.pricingOption?.[0];

  return {
    Id: proposal?.id || "",
    ClauseText: proposal?.views?.Production_all?.properties?.ClauseText || "",
    ProposalName:
      proposal?.views?.Production_all?.properties?.ProposalName || "",
    InternalCode:
      proposal?.views?.Production_all?.properties?.InternalCode || 0,
    PolicyEffectiveDate:
      proposal?.views?.Production_all?.properties?.PolicyEffectiveDate || "",
    PolicyExpiryDate:
      proposal?.views?.Production_all?.properties?.PolicyExpiryDate || "",
    modifiedOn: proposal?.views?.Production_all?.properties?.modifiedOn || "",
    lastModifiedBy:
      proposal.queries.production.relatedUsers?.[0]?.modifiedBy_firstName +
      " " +
      proposal.queries.production.relatedUsers?.[0]?.modifiedBy_lastName,
    createdBy:
      proposal?.queries?.production?.relatedUsers?.[0]?.createdBy_firstName +
      " " +
      proposal.queries.production.relatedUsers?.[0]?.createdBy_lastName,
    createdOn: proposal?.views?.Production_all?.properties?.createdOn || "",
    PolicyCurrency:
      proposal?.views?.Production_all?.properties?.PolicyCurrency || undefined,
    Status: proposal?.views?.Production_all?.properties?.Status || "",
    StatusReason:
      proposal?.views?.Production_all?.properties?.StatusReason || "",
    LineId: {
      Id: proposal?.views?.Production_all?.properties?.LineID?.id,
      Name:
        proposal?.views?.Production_all?.properties?.LineID?.views
          ?.PlanConfigManagement_all?.properties?.Name || "",
      ExternalCode:
        proposal?.views?.Production_all?.properties?.LineID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || "",
    },
    SubLineId: {
      Name:
        proposal?.views?.Production_all?.properties?.SublineID?.views
          ?.PlanConfigManagement_all?.properties?.Name || "",
      ExternalCode:
        proposal?.views?.Production_all?.properties?.SublineID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || "",
    },
    BusinessUserID: {
      Code:
        proposal?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.Code || "",
      AllowPlanCoverEdits:
        proposal?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.AllowPlanCoverEdits || false,

      FullName:
        proposal?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.PersonID?.views
          ?.SalesforceManagement_all?.properties?.FullName || "",
      EditableLineChargesIDs:
        proposal?.views?.Production_all?.properties?.BusinessUserID?.views?.SalesforceManagement_all?.properties?.EditableLineChargesIDs?.map(
          (a) => a.views.PlanConfigManagement_all.properties.Id
        ) || [],
      AllowClauseEdits:
        proposal?.views?.Production_all?.properties?.BusinessUserID?.views
          ?.SalesforceManagement_all?.properties?.AllowClauseEdits,
    },
    MarineDetails: marineDetails
      ? {
          Matter: marineDetails?.views?.Production_all?.properties?.Matter,
          Description:
            marineDetails?.views?.Production_all?.properties?.Description,
          VesselName:
            marineDetails?.views?.Production_all?.properties?.VesselName,
          VesselAge:
            marineDetails?.views?.Production_all?.properties?.VesselAge,
          From: marineDetails?.views?.Production_all?.properties?.From,
          To: marineDetails?.views?.Production_all?.properties?.To,
          Via: marineDetails?.views?.Production_all?.properties?.Via,
          Survey: marineDetails?.views?.Production_all?.properties?.Survey,
          CargoContract:
            marineDetails?.views?.Production_all?.properties?.CargoContract,
          LCNumber: marineDetails?.views?.Production_all?.properties?.LCNumber,
          LCIssueDate:
            marineDetails?.views?.Production_all?.properties?.LCIssueDate,
          Bank: marineDetails?.views?.Production_all?.properties?.Bank,
          ClaimsPayableBy:
            marineDetails?.views?.Production_all?.properties?.ClaimsPayableBy,
          Loading: marineDetails?.views?.Production_all?.properties?.Loading,
          Remarks: marineDetails?.views?.Production_all?.properties?.Remarks,
          SumPerShipment:
            marineDetails?.views?.Production_all?.properties?.SumPerShipment,
        }
      : undefined,
    MarineHullDetails: marineHullDetails
      ? {
          VesselName:
            marineHullDetails?.views?.Production_all?.properties?.VesselName,
          ConnectionPort:
            marineHullDetails?.views?.Production_all?.properties
              ?.ConnectionPort,
          TerritorialWaters:
            marineHullDetails?.views?.Production_all?.properties
              ?.TerritorialWaters,
          Usage: marineHullDetails?.views?.Production_all?.properties?.Usage,
          Builder:
            marineHullDetails?.views?.Production_all?.properties?.Builder,
          ConstructionYear:
            marineHullDetails?.views?.Production_all?.properties
              ?.ConstructionYear,
          RegisteryPort:
            marineHullDetails?.views?.Production_all?.properties?.RegisteryPort,
          Length: marineHullDetails?.views?.Production_all?.properties?.Length,
          Weight: marineHullDetails?.views?.Production_all?.properties?.Weight,
          Height: marineHullDetails?.views?.Production_all?.properties?.Height,
          EngineType:
            marineHullDetails?.views?.Production_all?.properties?.EngineType,
          EngineNumber:
            marineHullDetails?.views?.Production_all?.properties?.EngineNumber,
          EngineYearBuilt:
            marineHullDetails?.views?.Production_all?.properties
              ?.EngineYearBuilt,
          EngineHorsePower:
            marineHullDetails?.views?.Production_all?.properties
              ?.EngineHorsePower,
          EngineMaxDesignedSpeed:
            marineHullDetails?.views?.Production_all?.properties
              ?.EngineMaxDesignedSpeed,
          EngineFuel:
            marineHullDetails?.views?.Production_all?.properties?.EngineFuel,
          Remarks:
            marineHullDetails?.views?.Production_all?.properties?.Remarks,
        }
      : undefined,
    PrimaryBPID: {
      Id: proposal?.views?.Production_all?.properties?.PrimaryBPID?.id,
      Code:
        proposal?.views?.Production_all?.properties?.PrimaryBPID?.views
          ?.SalesforceManagement_all?.properties?.Code || "",
      FullName:
        proposal?.views?.Production_all?.properties?.PrimaryBPID?.views
          ?.SalesforceManagement_all?.properties?.PersonID?.views
          ?.SalesforceManagement_all?.properties?.FullName || "",
      RelatedCompany:
        proposal?.views?.Production_all?.properties?.PrimaryBPID?.views
          ?.SalesforceManagement_all?.properties?.RelatedCompany?.Title,
    },
    PlanID: {
      Id: proposal?.views?.Production_all?.properties?.PlanID?.id || "",
      Name:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          ?.PlanConfigManagement_all?.properties?.Name || "",
      ExternalCode:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          ?.PlanConfigManagement_all?.properties?.ExternalCode || "",
      ClauseEditable:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ClauseEditable,
      MaxNbInsured:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.MaxNbInsured,
      MaxNbBeneficiary:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.MaxNbBeneficiary,
      AcalCategory:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.AcalCategory,
      SOSService:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.SOSService,
      Scratching:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.Scratching,
      ReplacementCarValue:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ReplacementCarValue,
      ReplacementCarDays:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ReplacementCarDays,
      ReplacementCarApplied:
        proposal?.views?.Production_all?.properties?.PlanID?.views
          .PlanConfigManagement_all.properties.ReplacementCarApplied,
    },
    PricingOption: {
      PolicyCost: pricingOptionInfo.pricingOption_PolicyCost,
      PolicyNumber: pricingOptionInfo?.policy_PolicyNumber,
      PolicyId: pricingOptionInfo?.policy_Id,
      ChargesPercentage: pricingOptionInfo.pricingOption_ChargesPercentage,
      TPAFeesType: pricingOptionInfo.pricingOption_TPAFeesType,
      TPAFeesAmount: pricingOptionInfo.pricingOption_TPAFeesAmount,
      TPAFeesPercentage: pricingOptionInfo.pricingOption_TPAFeesPercentage,
      TPAFeesApplicableOn: pricingOptionInfo.pricingOption_TPAFeesApplicableOn,
      BasicPremium: pricingOptionInfo.pricingOption_BasicPremium,
      NetPremium: pricingOptionInfo.pricingOption_NetPremium,
      PlanToPolicyRate: pricingOptionInfo.pricingOption_PlanToPolicyRate,
      GrossPremium: pricingOptionInfo.pricingOption_GrossPremium,
      TaxOnCommissionAmount:
        pricingOptionInfo.pricingOption_TaxOnCommissionAmount,
      ReinsuranceTaxAmount:
        pricingOptionInfo.pricingOption_ReinsuranceTaxAmount,
      MunicipalityTaxAmount:
        pricingOptionInfo.pricingOption_MunicipalityTaxAmount,
      TotalPremium: pricingOptionInfo.pricingOption_TotalPremium,
      ChargesAmount: pricingOptionInfo.pricingOption_ChargesAmount,
      TPATotalFeesAmount: pricingOptionInfo.pricingOption_TPAFeesTotalAmount,
      FixedStampAmount: pricingOptionInfo.pricingOption_FixedStampAmount,
      Status: pricingOptionInfo.pricingOption_Status,
      ProportionalStampAmount:
        pricingOptionInfo.pricingOption_ProportionalStampAmount,
      CreatedOn: pricingOptionInfo.pricingOption_createdOn,
      SumInsured: pricingOptionInfo.pricingOption_SumInsured,
      PricingOptionCurrencySymbol:
        pricingOptionInfo.pricingOption_PricingOptionCurrency.Symbol,
    },
    // PolicyExpat: {
    //   Continuity: expatDetails?.Continuity,
    //   ContinuityNumber: expatDetails?.ContinuityNumber,
    //   Exclusion: expatDetails?.Exclusion,
    //   MedicalClass: expatDetails?.MedicalClass,
    //   Remarks: expatDetails?.Remarks,
    // },
    BankersDetails: undefined,
    CommissionDetails: (
      response as any
    ).Production.queries.getPolicyBusienssPartnerDetails.map((detail: any) => ({
      BusinessUserFullName:
        detail.production_PolicyBusinessPartner_BusinessPartnerID.views
          .SalesforceManagement_all.properties.PersonID.views
          .SalesforceManagement_all.properties.FullName,
      PlanCommissionType:
        detail.production_PolicyBusinessPartner_PlanCommissionType,
      PlanCommissionAmount:
        detail.production_PolicyBusinessPartner_PlanCommissionAmount,
      PlanCommissionPercentage:
        detail.production_PolicyBusinessPartner_PlanCommissionRate,
      ApplicableOn:
        detail?.production_PolicyBusinessPartner_ApplicableOn?.Status,
      PolicyCommisisonAmount:
        detail.production_PolicyBusinessPartner_PolicyCommissionAmount,
      Id: detail.production_PolicyBusinessPartner_Id,
      Share: detail.production_PolicyBusinessPartner_CommissionPercentage,
      IsPrimary: detail.production_PolicyBusinessPartner_IsPrimary,
    })),
  };
}

export function getPlanCoversLov(data: any): Record<string, string> {
  const result: Record<string, string> = {};

  data.PlanConfigManagement.queries.getNonSelectedPlanCover.forEach(
    (planCover: any) => {
      const id = planCover.planConfigManagement_PlanCover_Id;
      const policyCover =
        planCover.planConfigManagement_PlanCover_PolicyCoverID.views
          .PlanConfigManagement_all.properties;
      result[id] = `${policyCover.ExternalCode} - ${policyCover.Name}`;
    }
  );

  return result;
}

export function convertToProposalCover(
  data: any,
  currenyCode: string,
  planToPolicyRate: number = 1,
  coverEffectiveFrom?: string,
  coverEffectiveTo?: string
): Record<string, IProposalCover> {
  const result: Record<string, IProposalCover> = {};

  data.PlanConfigManagement.queries.getNonSelectedPlanCover.forEach(
    (item: any) => {
      // const id = item.planConfigManagement_PlanCover_PolicyCoverID.id;
      const id = item.planConfigManagement_PlanCover_Id;
      const currencyDetails =
        item?.planConfigManagement_PlanCover_PlanID?.views
          ?.PlanConfigManagement_all?.properties.PlanCurrency || {};
      result[id] = {
        isMain: item.planConfigManagement_PlanCover_IsMain,
        isMandatory: item.planConfigManagement_PlanCover_IsMandatory
          ? "YES"
          : "NO",
        isEditable: item.planConfigManagement_PlanCover_IsEditable,
        policyCover: item.planConfigManagement_PlanCover_Id,
        coverPremiumType: item.planConfigManagement_PlanCover_CoverPremiumType,
        coverPremiumPercentage:
          item.planConfigManagement_PlanCover_CoverPremiumType?.toUpperCase() ===
          "PERCENTAGE"
            ? numberToPercentage(
                item.planConfigManagement_PlanCover_CoverPremiumPercentage
              )
            : 0,
        coverPremiumValue:
          item.planConfigManagement_PlanCover_CoverPremiumType.toUpperCase() ===
          "VALUE"
            ? convertToFixedNumber(
                item.planConfigManagement_PlanCover_CoverPremiumValue *
                  planToPolicyRate
              )
            : 0,
        currency: currenyCode || currencyDetails?.Code,
        coverSumInsured: convertToFixedNumber(
          item.planConfigManagement_PlanCover_CoverSumInsured * planToPolicyRate
        ),
        sumInsuredIsAdditive:
          item.planConfigManagement_PlanCover_SumInsuredIsAdditive,
        excessOnClaimType:
          item.planConfigManagement_PlanCover_ExcessOnClaimType,
        excessOnClaimAmount: convertToFixedNumber(
          item.planConfigManagement_PlanCover_ExcessOnClaimAmount *
            planToPolicyRate
        ),
        excessOnClaimPercentage: numberToPercentage(
          item.planConfigManagement_PlanCover_ExcessOnClaimPercentage
        ),
        excessOnClaimDays:
          item.planConfigManagement_PlanCover_ExcessOnClaimDays,
        // clauseID: item.production_PolicyPlanCover_ClauseID?.id
        clauseID: item.planConfigManagement_PlanCover_ClauseID?.id,
        coverEffectiveFrom: coverEffectiveFrom,
        coverEffectiveTo: coverEffectiveTo,
      };
    }
  );

  return result;
}

export function convertToPolicyPlanProposalCovers(data: any): IProposalCover[] {
  const covers = data.Production.queries.getPolicyPlanCovers;
  const proposal = data?.Production?.entities?.proposal;

  return covers.map((cover: any) => ({
    isMain: cover.production_PolicyPlanCover_IsMain,
    isEditable: cover.production_PolicyPlanCover_IsEditable,
    isMandatory: cover.production_PolicyPlanCover_IsMandatory ? "YES" : "NO",
    policyCover: cover.planCover_Id,
    coverPremiumType: cover.production_PolicyPlanCover_CoverPremiumType,
    coverPremiumPercentage: numberToPercentage(
      cover.production_PolicyPlanCover_CoverPremiumPercentage
    ),
    coverPremiumValue: convertToFixedNumber(
      cover.production_PolicyPlanCover_CoverPremiumValue
    ),
    currency:
      cover.production_PolicyPlanCover_ProposalID?.views?.Production_all
        ?.properties?.PolicyCurrency?.Code,
    coverSumInsured: convertToFixedNumber(
      cover.production_PolicyPlanCover_CoverSumInsured
    ),
    sumInsuredIsAdditive: cover.production_PolicyPlanCover_SumInsuredIsAdditive,
    excessOnClaimType: cover.production_PolicyPlanCover_ExcessOnClaimType,
    excessOnClaimAmount: convertToFixedNumber(
      cover.production_PolicyPlanCover_ExcessOnClaimAmount
    ),
    excessOnClaimPercentage: numberToPercentage(
      cover.production_PolicyPlanCover_ExcessOnClaimPercentage
    ),
    excessOnClaimDays: cover.production_PolicyPlanCover_ExcessOnClaimDays,
    name: cover.policyCover_Name,
    clauseId: cover.production_PolicyPlanCover_ClauseID?.id,
    coverEffectiveFrom:
      cover?.production_PolicyPlanCover_CoverEffectiveFrom ||
      proposal?.views?.Production_all?.properties?.PolicyEffectiveDate,
    coverEffectiveTo:
      cover?.production_PolicyPlanCover_CoverEffectiveTo ||
      proposal?.views?.Production_all?.properties?.PolicyExpiryDate,
  }));
}

export function convertToPolicyBankersBlanketBondDetails(
  data: any
): IProposalBankerDetails[] {
  const bankersDetails =
    data.Production.queries.getPolicyBankersBlanketBondDetails;

  if (bankersDetails.length > 0) {
    return bankersDetails.map((detail: any) => ({
      branchName: detail.production_PolicyBBBond_BranchName || "",
      isMain: isEmpty(detail.production_PolicyBBBond_IsMain)
        ? ""
        : detail.production_PolicyBBBond_IsMain
        ? "YES"
        : "NO",
      isAgency: isEmpty(detail.production_PolicyBBBond_IsAgency)
        ? ""
        : detail.production_PolicyBBBond_IsAgency
        ? "YES"
        : "NO",
      numberOfEmployees: Number(
        detail.production_PolicyBBBond_NumberOfEmployees || 0
      ),
      underwritingYear: Number(
        detail.production_PolicyBBBond_UnderwritingYear || 0
      ),
      infidelity: convertToFixedNumber(
        detail.production_PolicyBBBond_Infidelity || 0
      ),
      burglary: convertToFixedNumber(
        detail.production_PolicyBBBond_Burglary || 0
      ),
      holdUp: convertToFixedNumber(detail.production_PolicyBBBond_HoldUp || 0),
      inSafe: convertToFixedNumber(detail.production_PolicyBBBond_InSafe || 0),
      inTransit: convertToFixedNumber(
        detail.production_PolicyBBBond_InTransit || 0
      ),
      forgedChecks: convertToFixedNumber(
        detail.production_PolicyBBBond_ForgedCheckes || 0
      ),
      counterfeitCurrencies: convertToFixedNumber(
        detail.production_PolicyBBBond_CounterFeitCurrencies || 0
      ),
      clause6: convertToFixedNumber(
        detail.production_PolicyBBBond_Clause6 || 0
      ),
      falsification: convertToFixedNumber(
        detail.production_PolicyBBBond_Falsification || 0
      ),
    }));
  } else {
    return [
      {
        branchName: "",
        isMain: "",
        isAgency: "",
        numberOfEmployees: null,
        underwritingYear: null,
        infidelity: null,
        burglary: null,
        holdUp: null,
        inSafe: null,
        inTransit: null,
        forgedChecks: null,
        counterfeitCurrencies: null,
        clause6: null,
        falsification: null,
      },
    ];
  }
}

export function convertToPolicyPersons(data: any): IPolicyPersons {
  const persons = data.Production.queries.GetPolicyPerson;

  let payer: IPayerData = {
    payerId: "",
    payerAddress: "",
    ownerIsDifferent: false,
  };
  let owner: IOwnerData = { ownerId: "", ownerAddress: "" };
  const insured: IInsuredData[] = [];
  const beneficiaries: IBeneficiaryData[] = [];

  persons.forEach((person: any) => {
    const building = person.production_PolicyPerson_Address.Building;
    const street = person.production_PolicyPerson_Address.Street;
    const city = person.production_PolicyPerson_Address.City;
    const country = person.production_PolicyPerson_Address.Country.Title;
    const address = `${building ? building + ", " : ""} ${
      street ? street + ", " : ""
    } ${city ? city + ", " : ""} ${country ? country : ""}`;

    switch (person.production_PolicyPerson_Type) {
      case "PAYER":
        payer = {
          payerId: person.production_PolicyPerson_PersonID?.id,
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
          payerAddress: person?.production_PolicyPerson_PersonAddressID?.id,
          ownerIsDifferent: false,
          addressDetails: address,
        };
        break;
      case "INSURED":
        insured.push({
          id: person.production_PolicyPerson_PersonID?.id,
          order: person.production_PolicyPerson_OrderNumber,
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
          address: person?.production_PolicyPerson_PersonAddressID?.id,
          addressDetails: address,
        });
        break;
      case "BENEFICIARY":
        beneficiaries.push({
          id: person.production_PolicyPerson_PersonID?.id,
          address: person?.production_PolicyPerson_PersonAddressID?.id,
          percentage: numberToPercentage(
            person.production_PolicyPerson_Percentage
          ),
          fullName: `${person.production_PolicyPerson_PersonCode} - ${person.production_PolicyPerson_FullName}`,
          addressDetails: address,
        });
        break;
      case "OWNER":
        owner = {
          ownerId: person.production_PolicyPerson_PersonID?.id || "",
          ownerAddress:
            person?.production_PolicyPerson_PersonAddressID?.id || "",
          addressDetails: address,
        };
        break;
    }
  });

  if (insured.length === 0) {
    insured.push({
      id: "",
      order: "1",
      fullName: "",
      address: "",
      addressDetails: "",
    });
  }

  if (beneficiaries.length === 0) {
    beneficiaries.push({
      id: "",
      fullName: "",
      percentage: 0,
      address: "",
      addressDetails: "",
    });
  }

  return {
    payer: payer,
    owner: owner,
    insured,
    beneficiaries,
  };
}
