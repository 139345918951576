import _, { isArray, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { IOwnerData, IProposalPageFormState } from "./form";
import CollapsibleSection from "../../../components/common/CollapsibleSection";
import ProposalPolicyPersonDrawer from "../../proposal-policy-person-drawer/ProposalPolicyPersonDrawer";
import { useLazyQuery } from "@apollo/client";
import { fetchedPersonAddressList, fetchedPersons } from "../queries";
import { extractPersons, extractPersonsAddresses } from "../utils";
import { useParams } from "react-router-dom";
import { isGuid } from "../../../utils/validationUtils";
import NewChipsInput from "../../../components/enhanced-form/NewChipsInput";
import { validatePolicyOwnerField } from "../../../modules/production/proposal/page/expat/validation";
import { IProposalDetailsSummary } from "./index2";
import PersonAddressDrawer from "../../person-address-drawer/PersonAddressDrawer";

interface IPolicyDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(4, 23%)`,
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "ce",
  },
  field: {
    width: "100%",
    marginRight: "25px !important",
    minWidth: "195px",
  },
  orderField: {
    width: "100%",
    marginRight: "25px !important",
    minWidth: "20px",
  },
  sumInsuredRow: {
    width: "100%",
  },
  section: {
    display: "grid",
    "grid-template-columns": "23.5% 23.5% 23.5% 23.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& label": {
      "& span": {
        margin: "0 0 0",
      },
    },
  },
  addBtn: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0",
    margin: "20px auto 0",
    display: "block",
  },
  removeBtn: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0",
    margin: "0 auto",
    display: "block",
  },
}));

const PolicyOwnerWidget: React.FC<IPolicyDetailsWidgetProps> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  lovs,
  data,
}) => {
  const { classes } = useStyles();
  const params = useParams();
  const entityId = params.id;

  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList());

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] =
    useState<boolean>(false);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] =
    useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<
    Record<string, Record<string, Record<string, string>>>
  >({
    ownerId: {},
    ownerAddresses: {},
  });

  const values = pageState.values.policyPersons.owner;
  const errors = pageState.errors.policyPersons.owner;
  const touched = pageState.touched.policyPersons.owner;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());

  const handleOpenDrawer = (section: string) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(true);
  };

  const handleOpenAddressDrawer = () => {
    setNewAddressDrawerOpen(true);
  };

  const handleOwnerSearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [data?.PrimaryBPID?.Id],
        },
      });
      return extractPersons(newData.data);
    }
    return {} as Record<string, string>;
  };

  const initialize = async () => {
    const newOwnerList: Record<string, string> = {};
    newOwnerList[values.ownerId] = values.fullName;
    const newList = {
      ownerId: { payerPersons: newOwnerList },
    };
    setLists(newList);
  };
  useEffect(() => {
    initialize();
  }, []);

  const handleOwnerAddresses = async (personId: string) => {
    const result: any = await getPersonAddressListLazy({
      variables: {
        selectedPersonID: personId,
      },
      errorPolicy: "all",
    });

    return extractPersonsAddresses(result.data);
  };

  const onFieldUpdate = async (
    fieldName: string,
    value: any,
    rowIndex?: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newLists = { ...updatedList };
    if (fieldName === "ownerId") {
      const mainValue: string = isArray(value) ? value[0] : value;
      if (isEmpty(mainValue) || isGuid(mainValue)) {
        (newPageState.values.policyPersons.owner as any)[fieldName] = mainValue;
        newPageState.errors.policyPersons.owner[fieldName] =
          validatePolicyOwnerField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.owner[fieldName] = true;
        }

        if (isGuid(mainValue)) {
          const result2 = (await handleOwnerAddresses(mainValue)) as Record<
            string,
            Record<string, { address: string; isPrimary: boolean }>
          >;
          const ownerAddresses = result2.ownerPersonAddress;
          let extractedAddresses: any = {};
          Object.keys(ownerAddresses).forEach((key) => {
            extractedAddresses[key] = ownerAddresses[key].address;
            if (ownerAddresses[key].isPrimary) {
              newPageState.values.policyPersons.owner.ownerAddress = key;
            }
          });
          newPageState.errors.policyPersons.owner.ownerAddress = "";
          newLists["ownerAddresses"] = extractedAddresses;
        }
      } else {
        const result: Record<
          string,
          Record<string, string>
        > = (await handleOwnerSearch(value)) as any;

        newLists["ownerId"] = result;
      }
    } else {
      (newPageState.values.policyPersons.owner as any)[fieldName] = value;
      newPageState.errors.policyPersons.owner[fieldName] =
        validatePolicyOwnerField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.owner[fieldName] = true;
      }
    }
    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: any,
    touched = false,
    updatedList: any = lists
  ) => {
    const newLists = { ...updatedList };

    if (fieldName === "ownerId") {
      const result: Record<
        string,
        Record<string, string>
      > = (await handleOwnerSearch(value)) as any;
      newLists["ownerId"] = result;
    }

    setLists(newLists);
  };

  const handleDrawerSubmitSuccess = (
    person: any,
    newPersonFullName: string
  ) => {
    const newPersonList: Record<string, string> = {};
    newPersonList[person] = newPersonFullName;
    onFieldUpdate("ownerId", [person], 0, false, {
      ownerId: { ownerPersons: newPersonList },
    });
    setNewPersonDrawerOpen(false);
  };

  const handleAddressSubmitSuccess = (
    address: string,
    addressFullName: string
  ) => {
    onFieldUpdate("ownerAddress", address, 0, false, {
      ownerAddresses: { [address]: addressFullName },
    });
    setNewAddressDrawerOpen(false);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    owner: IOwnerData
  ) => {
    if (Object.keys(list || {}).length > 0 || !owner.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [owner.ownerId]: owner.fullName,
      [owner.ownerAddress]: owner.addressDetails,
    };

    return newList;
  };

  return (
    <CollapsibleSection title={"Owner"} open>
      <div className={classes.fieldRow}>
        {newPersonDrawerOpen && currentSection === "owner" && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen}
            onClose={() => setNewPersonDrawerOpen(false)}
            section={currentSection}
            planId={""}
            lineExternalCode={data?.LineId?.ExternalCode}
            onSuccess={() => {}}
            proposalId={entityId}
            onSubmitSuccess={handleDrawerSubmitSuccess}
          />
        )}

        {newAddressDrawerOpen && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen}
            onClose={() => setNewAddressDrawerOpen(false)}
            personId={values.ownerId}
            onSubmitSuccess={handleAddressSubmitSuccess}
            onSuccess={() => {}}
          />
        )}

        <NewChipsInput
          key="ownerId"
          name="ownerId"
          title="Owner"
          placeholder="Owner"
          value={values.ownerId}
          error={touched.ownerId ? errors.ownerId : ""}
          onChange={(v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldUpdate("ownerId", value);
          }}
          onSearch={async (v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldSearch("ownerId", value);
          }}
          clearValueOnSearch={false}
          disabled={disabledForm}
          items={getDefaultPersonListIfEmpty(
            lists?.ownerId?.ownerPersons,
            values
          )}
          required={true}
          multiple={false}
          material={true}
          supportLink={disabledForm ? "" : "Add Person"}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenDrawer("owner");
          }}
        />

        <NewChipsInput
          key="ownerAddress"
          name="ownerAddress"
          title="Owner Address"
          placeholder="Owner Address"
          value={values.ownerAddress}
          error={touched.ownerAddress ? errors.ownerAddress : ""}
          onChange={(v) => onFieldUpdate("ownerAddress", v)}
          disabled={disabledForm}
          items={getDefaultPersonListIfEmpty(
            lists.ownerAddresses as any,
            values
          )}
          required={true}
          multiple={false}
          material={true}
          supportLink={disabledForm ? "" : "Add Address"}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenAddressDrawer();
          }}
        />
      </div>
    </CollapsibleSection>
  );
};

export default PolicyOwnerWidget;
