import React, { useEffect, useState } from "react";
import {
  IDynamicDiplayView,
  IDynamicSection,
} from "../../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../../components/widgets/dynamic-display/DynamicWidget";
import { IPolicyBankersDetails } from ".";

interface IProductionPolicyBankersDetailsInfoProps {
  data: IPolicyBankersDetails[];
}

const ProductionPolicyBankersDetailsInfo: React.FC<
  IProductionPolicyBankersDetailsInfoProps
> = ({ data }) => {
  const [view, setView] = useState<IDynamicDiplayView>();

  useEffect(() => {
    setViewData();
  }, [data]);

  const setViewData = () => {
    const dynamicView: IDynamicDiplayView = {
      name: "PolicyBankersDetails",
      sections: data.map(
        (item, index) =>
          ({
            name: "PolicyBankersDetails" + index,
            title: index === 0 ? "Bankers Blanket Bond Details" : "",
            properties: {
              ["branchName" + index]: {
                multiline: false,
                value: item.branchName,
                name: "branchName" + index,
                title: "Branch Name",
                tooltip: false,
              },
              ["isMain" + index]: {
                multiline: false,
                value: item.isMain ? "Yes" : "No",
                name: "isMain" + index,
                title: "Main",
                tooltip: false,
              },
              ["isAgency" + index]: {
                multiline: false,
                value: item.isAgency ? "Yes" : "No",
                name: "isAgency" + index,
                title: "Agency",
                tooltip: false,
              },
              ["numberOfEmployees" + index]: {
                multiline: false,
                value: item.numberOfEmployees.toString(),
                name: "numberOfEmployees" + index,
                title: "Number of Employees",
                tooltip: false,
              },
              ["underwritingYear" + index]: {
                multiline: false,
                value: item.underwritingYear.toString(),
                name: "underwritingYear" + index,
                title: "Underwriting Year",
                tooltip: false,
              },
              ["infidelity" + index]: {
                multiline: false,
                value: item.infidelity.toString(),
                name: "infidelity" + index,
                title: "Infidelity",
                tooltip: false,
              },
              ["burglary" + index]: {
                multiline: false,
                value: item.burglary.toString(),
                name: "burglary" + index,
                title: "Burglary",
                tooltip: false,
              },
              ["holdUp" + index]: {
                multiline: false,
                value: item.holdUp.toString(),
                name: "holdUp" + index,
                title: "Hold Up",
                tooltip: false,
              },
              ["inSafe" + index]: {
                multiline: false,
                value: item.inSafe.toString(),
                name: "inSafe" + index,
                title: "In Safe",
                tooltip: false,
              },
              ["inTransit" + index]: {
                multiline: false,
                value: item.inTransit.toString(),
                name: "inTransit" + index,
                title: "In Transit",
                tooltip: false,
              },
              ["forgedChecks" + index]: {
                multiline: false,
                value: item.forgedChecks.toString(),
                name: "forgedChecks" + index,
                title: "Forged Checks",
                tooltip: false,
              },
              ["counterfeitCurrencies" + index]: {
                multiline: false,
                value: item.counterfeitCurrencies.toString(),
                name: "counterfeitCurrencies" + index,
                title: "Counterfeit Currencies",
                tooltip: false,
              },
              ["clause6" + index]: {
                multiline: false,
                value: item.clause6.toString(),
                name: "clause6" + index,
                title: "Clause 6",
                tooltip: false,
              },
              ["falsification" + index]: {
                multiline: false,
                value: item.falsification.toString(),
                name: "falsification" + index,
                title: "Falsification",
                tooltip: false,
              },
            },
          } as IDynamicSection)
      ),
    };

    setView(dynamicView);
  };

  if (!view) {
    return <></>;
  }

  return <DynamicWidget hasFourFields={true} view={view} />;
};

export default ProductionPolicyBankersDetailsInfo;
