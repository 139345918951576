import { IPaymentTermsPopupFormState } from ".";

export const getPaymentTermsPopupFormState =
	(): IPaymentTermsPopupFormState => ({
		values: {
			policyNumber: null,
			policyIssuanceDate: null,
			policyExpiryDate: null,
			paymentType: null,
			maxEffectiveFrom: null,
			effectiveFrom: null,
			frequency: null,
			numOfPayments: null,
			bills: [],
		},
		errors: {
			policyIssuanceDate: null,
			policyExpiryDate: null,
			paymentType: null,
			maxEffectiveFrom: null,
			effectiveFrom: null,
			frequency: null,
			numOfPayments: null,
		},
		touched: {
			policyIssuanceDate: false,
			policyExpiryDate: false,
			paymentType: false,
			maxEffectiveFrom: false,
			effectiveFrom: false,
			frequency: false,
			numOfPayments: false,
		},
	});

export const arrayToList = (arr: string[]) => {
	let list: Record<string, any> = {};

	arr.forEach((element: string) => {
		list[element] = element;
	});

	return list;
};

export const numToList = (num: number) => {
	let list: Record<string, any> = {};

	for (let i = 1; i <= num; i++) {
		list[i] = i;
	}

	return list;
};
