import React from "react";
import { getBenefieryView, getInsuredView, getOwnerView, getPayerView } from ".";
import { IProductionDetailsSummary } from "..";
import WidgetPaper from "../../../../../components/common/WidgetPaper";
import WidgetSection from "../../../../../components/common/WidgetSection";
import DynamicWidget from "../../../../../components/widgets/dynamic-display/DynamicWidget";




const PersonsWidget: React.FC<{data: IProductionDetailsSummary}> = ({data}) => {

  return <WidgetPaper>
    <WidgetSection title="Policy Persons">
      {
        data?.Persons?.payer.name &&
        <DynamicWidget hasFourFields collapsibleSectionTitle="Payer" isCollapsible view={getPayerView(data?.Persons?.payer)} />
      }
      {
        data?.Persons?.owner.name &&
        <DynamicWidget hasFourFields collapsibleSectionTitle="Owner" isCollapsible view={getOwnerView(data?.Persons?.owner)} />
      }
      {
        data?.Persons?.insured?.length > 0 &&
        <DynamicWidget hasFourFields collapsibleSectionTitle="Insured" isCollapsible view={getInsuredView(data?.Persons?.insured)} />
      }
      {
        data?.Persons?.beneficiaries?.length > 0 &&
        <DynamicWidget hasFourFields collapsibleSectionTitle="Beneficiary" isCollapsible view={getBenefieryView(data?.Persons?.beneficiaries)} />
      }
    </WidgetSection>


  </WidgetPaper>

}


export default PersonsWidget;
